import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { Home } from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Portfolio from './pages/Portfolio'
import Contact from './pages/Contact'
import Auth from './pages/auth/Auth'
import Layout from './pages/admin/Containers/Layout'
import AdminContact from './pages/admin/Contact'
import Testimonials from './pages/admin/Testimonials/Testimonials'
import Loading from './components/Loading'
import ProductDetail from './pages/products/ProductDetail'
import Chat from './pages/chat/Chat'
import 'aos/dist/aos.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useAuthContext } from './context/AuthContext'
import Products from './pages/admin/products/Products'
import CreateOrEditProduct from './pages/admin/products/CreateOrEditProduct'
import CustomProduct from './components/Products/CustomProduct'
import Checkout from './pages/checkout/Checkout'
import Wishlist from './pages/Wishlist'
import NotFound from './pages/NotFound'
import Invoice from './pages/checkout/Invoice'
import Orders from './pages/admin/orders/Orders'
import Newsletters from './pages/admin/Newsletter'
import Articles from './pages/admin/Articles/Articles'
import { useDispatch } from 'react-redux'
import { deleteCart } from './store/actions/cart.action'
import Register from './pages/auth/Register'
import AddCart from './components/AddCart'
import Collections from './pages/Collections'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ProtectedRoute from './middleware/ProtectedRoute'
import CustomerArea from './pages/user/CustomerArea'
import Logistics from './pages/admin/Logistics/Logistics'
import PersonalInfo from './pages/user/PersonalInfo'
import NewOrderLogistics from './pages/admin/Logistics/NewOrderLogistics'
import Interceptor from './interceptor/AxiosInterceptor'
import store from './store/store'
import DetailEmployee from './pages/admin/HRManagement/Employee/DetailEmployee'
import ListEmployee from './pages/admin/HRManagement/Employee/ListEmployee'
import Leaves from './pages/admin/HRManagement/Leaves/Leaves'
import Basket from './pages/Basket'
import Inventory from './pages/admin/Logistics/Inventory'
import Absence from './pages/admin/HRManagement/Absence/Absence'
import Salaries from './pages/admin/HRManagement/Salary/Salaries'
import Request from './pages/admin/Request/Request'
import SendNewsletter from './pages/admin/Newsletter/SendNewsletter'

Interceptor.interceptor(store)

function App() {
  const [loading, setLoading] = useState(true)
  const { isLoggedIn, isLoading } = useAuthContext()
  const dispatch = useDispatch()
  const location = useLocation()
  const currentKey = location.pathname.split('/')[1] || '/'
  const timeout = { enter: 600, exit: 600 }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    //listen when user change data into the local storage
    window.addEventListener('storage', async (e) => {
      if (e.storageArea.cart) {
        dispatch(deleteCart(e.oldValue))
        localStorage.removeItem(e.key)
      }
      localStorage.removeItem('store')
      window.location.reload()
    })
    // curDot({
    //   zIndex: 999,
    //   diameter: 30,
    //   background: '#f5f5f5',
    //   borderColor: '#f5f5f5',
    // })
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="App">
          <CustomProduct />
          <Register />
          <AddCart />
          <TransitionGroup component="main" classNames="page-main">
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames="fade"
              appear
            >
              <Routes>
                <Route path={'/'} element={<Home />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/our-services/:type" element={<Services />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/auth/confirm/:code" element={<Auth />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/products/:slug" element={<ProductDetail />} />
                <Route path="/admin" element={<Layout />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/collections" element={<Collections />} />
                <Route path="/cart" element={<Basket />} />
                <Route path="/admin/contacts" element={<AdminContact />} />
                <Route path="/admin/newsletters" element={<Newsletters />} />
                <Route
                  path="/admin/newsletters/send"
                  element={<SendNewsletter />}
                />
                <Route path="/admin/articles" element={<Articles />} />
                <Route path="/admin/products" element={<Products />} />
                <Route path="/admin/orders" element={<Orders />} />
                <Route
                  path="/admin/products/createOrEdit"
                  element={<CreateOrEditProduct />}
                />
                <Route path="/admin/testimonials" element={<Testimonials />} />
                <Route path="/admin/logistics" element={<Logistics />} />
                <Route path="/admin/request" element={<Request />} />
                <Route
                  path="/admin/logistics/new"
                  element={<NewOrderLogistics />}
                />
                <Route
                  path="/admin/logistics/inventory"
                  element={<Inventory />}
                />
                <Route path="/invoice/:ref" element={<Invoice />} />
                <Route path="/customer" element={<CustomerArea />} />
                <Route
                  path="/customer-area/personal-info"
                  element={
                    <ProtectedRoute
                      isLoggedIn={isLoggedIn}
                      isLoading={isLoading}
                    >
                      <PersonalInfo />
                    </ProtectedRoute>
                  }
                />
                <Route path="/admin/hr/employee" element={<ListEmployee />} />
                <Route
                  path="/admin/hr/employee/:id"
                  element={<DetailEmployee />}
                />
                <Route path="/admin/hr/leaves" element={<Leaves />} />
                <Route path="/admin/hr/absence" element={<Absence />} />
                <Route path="/admin/hr/salaries" element={<Salaries />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}
    </>
  )
}

export default App
