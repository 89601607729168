import React, { useEffect, useState } from 'react'
import { Grid, Group, Alert, Text, TextInput } from '@mantine/core'
import Button from '../../components/common/Button'
import { useForm } from '@mantine/form'
import axios from 'axios'
import { IconAlertCircle } from '@tabler/icons-react'

const UpdatePassword = ({ user }) => {
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const form = useForm({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      newPassword: (value) =>
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(value)
          ? null
          : 'The password is too easy to guess. Include a range of types of characters to increase the score.',
      confirmPassword: (value, values) =>
        value !== values.newPassword ? 'Passwords did not match' : null,
    },
  })

  const handleSubmit = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}users/update/password/${user._id}`,
        { ...data },
      )
      setSuccess('User updated')
      setError('')
      form.reset()
    } catch (err) {
      setError(err.response.data.message)
    }
  }

  return (
    <div style={{ maxWidth: 500, margin: '0 auto' }}>
      <Text fw={600} style={{ fontSize: 25, marginBottom: 20 }}>
        Login & Password
      </Text>
      {success && (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="cyan"
          radius="xs"
          withCloseButton
          mb={10}
        >
          {success}
        </Alert>
      )}
      {error && (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="red"
          radius="xs"
          withCloseButton
          mb={10}
        >
          {error}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(handleSubmit)} method="post">
        <TextInput
          label="Current password"
          size="md"
          radius={'xs'}
          type="password"
          {...form.getInputProps('currentPassword')}
        />
        <TextInput
          mt={10}
          label="New password"
          radius={'xs'}
          size="md"
          type="password"
          {...form.getInputProps('newPassword')}
        />
        <TextInput
          mt={10}
          label="Confirm password"
          radius={'xs'}
          size="md"
          type="password"
          {...form.getInputProps('confirmPassword')}
        />
        <Button style={{ marginTop: 15 }}>Save</Button>
      </form>
    </div>
  )
}

export default UpdatePassword
