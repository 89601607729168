import React, { useEffect, useRef } from 'react'
import { Layout } from '../components/Layout'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import Slider from 'react-slick'
import { useParams, Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { up } from 'styled-breakpoints'

const data = {
  wedding: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679996517/wedding/186528714_2129005723907256_5265126556515965084_n_az7vto.jpg',
    title: 'Pack mariage',
    description:
      'Nos packs mariage, sur mesure comme ils sont, peuvent contenir les éléments ci-après',
    items: [
      'Service traiteur ( boissons inclus)',
      'Décoration',
      'Couverture photos et vidéos',
      'Carteries',
      'Tête de cortège décorée',
      'Gâteau des mariés et des invités',
      'Accessoires de mariage : Bouquet de la mariée, Boutonnière du marié, …',
      'Autres ( sur mesure, exemple : cadeaux pour les invités,…)',
    ],
    extra: {
      title: 'Vous êtes libre de choisir en externe : ',
      items: [
        'Les animateurs (DJ, Orchestre, etc.)',
        'Vos costumes : Robes, Costard, etc.',
        'Vos bagues',
      ],
      footer:
        'Toutefois, nous avons une longue liste de prestataires qui pourront assurer toutes ces prestations. ',
    },
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679996519/wedding/186546281_2129006193907209_5912401870683639202_n_ew5k0g.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679996517/wedding/186538871_2129005743907254_2288620214851792878_n_j1p4rw.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679996516/wedding/148088043_2049998608474635_1576117057423056863_n_ly6wug.jpg',
    ],
  },
  engagement: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942046/fiancaille/6R3A8213_krmf30.jpg',
    title: 'Pack fiançailles',
    description:
      'Pour nos packs fiançailles, nous proposons des fêtes en petit comité. Nous proposons nos locaux, notamment celle d’Antaninandro Ampandrana, en plein centre ville.  Nous collaborons aussi avec de nombreux lieux de charme. ',
    items: [
      'Salle de réception',
      'Service traiteur ( boissons inclus)',
      'Décoration',
      'Couverture photos',
      'Gâteau des mariés et des invités',
      'Sonorisation',
      'Autres ( sur mesure, exemple : cadeaux pour les invités,…)',
      'Autres ( sur mesure, exemple : cadeaux pour les invités,…)',
    ],
    extra: {
      title: 'Vous êtes libre de choisir en externe : ',
      items: [
        'Les animateurs (DJ, Orchestre, etc.)',
        'Vos costumes : Robes, Costard, etc.',
        'La bague de fiançailles',
      ],
      footer:
        'Toutefois, nous avons une longue liste de prestataires qui pourront assurer toutes ces prestations. ',
    },
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942058/fiancaille/IMG_6622_wrpuwt.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942052/fiancaille/IMG_6513_vrsnf3.jpg',
    ],
  },
  birthday: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942415/birthday/IMG_6736_-_Copie_ienbrj.jpg',
    title: 'Pack anniversaire',
    description:
      'Ce pack est idéal pour les simples anniversaires, les anniversaires de mariage, les anniversaires de rencontre, etc. Il peut être personnalisé selon le contexte. La production d’effet de surprise pour la (les) personne(s) concernée(s) est presque toujours au rendez-vous. Il est souvent composé de : ',
    items: [
      'Salle de réception',
      'Service traiteur ( boissons inclus)',
      'Décoration notamment un coin photobooth',
      'Couverture photos',
      'Gâteau d’anniversaire',
      'Sonorisation',
      'Autres ( sur mesure,…)',
    ],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942415/birthday/IMG_6736_-_Copie_ienbrj.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942418/birthday/IMG_6747_amyrid.jpg',
    ],
  },
  baptism: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679974808/baptism/4I7A8176_sfoqpd.jpg',
    title: 'Pack baptême',
    description: 'Ce pack, simple comme il est, comprend :  ',
    items: [
      'Salle de réception',
      'Service traiteur ( boissons inclus)',
      'Décoration notamment un coin photobooth',
      'Couverture photos',
      'Gâteau',
      'Sonorisation',
      'Autres ( sur mesure, exemple : cadeaux pour les invités,…)',
    ],
    extra: {
      footer:
        'En moyenne, ce pack exige souvent une conception épurée et minimaliste. Toutefois, il dépend des exigences de chaque client. ',
    },
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679974883/baptism/IMG_1652_znhtn5.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679974880/baptism/IMG_1640_feu34i.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679974876/baptism/IMG_1645_r1dpvd.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679974874/baptism/IMG_1633_doabjn.jpg',
    ],
  },
  familial: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975026/familial/119884947_984915472023277_5223399302204033084_n_q7ivbf.jpg',
    title: 'Pack fête familiale',
    description:
      'Ce pack, exige la convivialité et l’intimité.  Il nécessite de connaître au préalable les attentes de la famille en question.  Il inclut :',
    items: [
      'Salle de réception',
      'Service traiteur ( boissons inclus)',
      'Décoration conviviale',
      'Couverture photos',
      'Gâteau',
      'Sonorisation',
      'Autres (sur mesure,…)',
    ],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975029/familial/186558407_2129006203907208_1773174033549887559_n_kph5bk.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975022/familial/241053338_1600396373624952_4304037236700226281_n_yd0fyl.jpg',
    ],
  },
  event: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975299/event/IMG_1758_l3i3bj.jpg',
    title: 'Evénements d’entreprises',
    description:
      '<p>Il s’agit ici par exemple des teambuildings, des journées récréatives, des cérémonies officielles, des salons & foires, des décorations de stands, divers habillages vitrine, etc. <br/>Les entreprises sont nos partenaires. Nous nous soucions du principe WIN WIN dans nos relations. </p><br/><p>Nos offres sont adaptées selon les attentes de ces entités. Nous avons des formules et des modalités de paiement cohérentes avec chaque taille d’entreprise.</p><br/> <p>Nous pouvons organiser des séminaires, des conférences, des ateliers, des colloques.</p>',
    items: [],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975355/event/IMG_1785_jbp7rs.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975343/event/IMG_1792_wo7hod.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975342/event/IMG_1795_xilh3a.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975326/event/IMG_1824_gizpee.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975317/event/IMG_1694_nl4has.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975311/event/IMG_1678_bztxki.jpg',
    ],
  },
  promotion: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975602/promotion/IMG_7840_m3zesb.jpg',
    title: 'Sorties de promotion',
    description:
      'En vue d’élargir notre zone d’intervention, nos offres concernent aussi tous les événements en lien avec les sorties de promotion. Nous pouvons nous charger de l’organisation de la cérémonie, de la fête de sortie de promotion. ',
    items: [
      'Salle de réception',
      'Service traiteur ( boissons inclus)',
      'Décoration',
      'Couverture photos & vidéos',
      'Photos instantanées pour chaque sortant',
      'Gâteau',
      'Sonorisation',
      'Autres (sur mesure,…)',
    ],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975595/promotion/IMG_7925_xbs0di.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975592/promotion/IMG_7746_mr1thx.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975592/promotion/IMG_7680_c7iuyl.jpg',
    ],
  },
  others: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975763/others/40652230_1358320547632665_3156102472028651520_n_gxmfua.jpg',
    title: 'Autre service sur mesure',
    description:
      'Ce service requiert une réunion physique ou virtuelle car il dépend fortement des attentes du client. Souvent, ce sont les offres de type VIP qui ont besoin d’être personnalisées.',
    items: [],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975768/others/72673233_1595002673974233_5138401063644168192_n_xobprv.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975763/others/40597822_1358321434299243_2292224578451144704_n_ugvybi.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975763/others/40626777_1358320574299329_3249079241152659456_n_zqjlt2.jpg',
    ],
  },
  formation: {
    photo:
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975802/formation/241100391_1600394810291775_3583336108117441963_n_rmrsb1.jpg',
    title: 'Autre service sur mesure',
    description:
      'Santikà donne ses conseils pour toutes personnes ou entreprises ayant besoin de son expertise. Ce service est gratuit pour certains cas. Pour les conseils en conception de plan pour une planification de mariage, ce service est payant. Santikà offre aussi diverses formations. ',
    items: [],
    sliders: [
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975802/formation/241074802_1600394853625104_4970832655608176590_n_jymdek.jpg',
      'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975836/formation/IMG_9267j_amidfv.jpg',
    ],
  },
}

const Services = ({ photo, services, sliders }) => {
  const { type } = useParams()

  const serviceRef = useRef(null)

  const isActive = (pageType) => {
    return {
      fontWeight: pageType === type ? 600 : '',
      borderLeft: pageType === type ? '2px solid rgb(192, 158, 111)' : '',
      color: pageType === type ? 'rgb(192, 158, 111)' : '',
      paddingLeft: pageType === type ? 15 : '',
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout withBg>
      <Container
        style={{
          marginTop: 50,
          textAlign: 'left',
          padding: '0px 25px 100px 25px',
        }}
      >
        <Row ref={serviceRef}>
          <Col sm={4}>
            <Title>Services</Title>
            <ServicesItems>
              <Link to={'/our-services/wedding'}>
                <ServiceItem style={isActive('wedding')}>
                  Pack mariage
                </ServiceItem>
              </Link>
              <Link to={'/our-services/engagement'}>
                <ServiceItem style={isActive('engagement')}>
                  Pack fiançailles
                </ServiceItem>
              </Link>
              <Link to={'/our-services/birthday'}>
                <ServiceItem style={isActive('birthday')}>
                  Pack anniversaire
                </ServiceItem>
              </Link>
              <Link to={'/our-services/baptism'}>
                <ServiceItem style={isActive('baptism')}>
                  Pack baptême
                </ServiceItem>
              </Link>
              <Link to={'/our-services/familial'}>
                <ServiceItem style={isActive('familial')}>
                  Pack fête familiale
                </ServiceItem>
              </Link>
              <Link to={'/our-services/event'}>
                <ServiceItem style={isActive('event')}>
                  Evénements d’entreprises
                </ServiceItem>
              </Link>
              <Link to={'/our-services/promotion'}>
                <ServiceItem style={isActive('promotion')}>
                  Sorties de promotion
                </ServiceItem>
              </Link>
              <Link to={'/our-services/others'}>
                <ServiceItem style={isActive('others')}>
                  Autre service sur mesure
                </ServiceItem>
              </Link>
              <Link to={'/our-services/formation'}>
                <ServiceItem style={isActive('formation')}>
                  Formations, Conseils & astuces
                </ServiceItem>
              </Link>
            </ServicesItems>
          </Col>
          <Col sm={6}>
            <ServiceTitle>{data[type].title}</ServiceTitle>
            {data[type].description && (
              <ServiceDescription>
                {parse(data[type].description)}
              </ServiceDescription>
            )}
            {data[type].items && data[type].items.length > 0 && (
              <ul
                className="service-item"
                style={{ fontFamily: 'JosefinSlab', marginTop: 15 }}
              >
                {data[type].items.map((item, index) => (
                  <li
                    data-aos="fade-in-down"
                    data-aos-delay={index * 100}
                    data-aos-duration="1000"
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
            {data[type].extra && data[type].extra.title && (
              <p style={{ marginTop: 15, fontFamily: 'JosefinSlab' }}>
                {data[type].extra.title}
              </p>
            )}
            {data[type].extra && data[type].extra.items && (
              <ul
                className="service-item"
                style={{ fontFamily: 'JosefinSlab', marginTop: 15 }}
              >
                {data[type].extra.items.map((item, index) => (
                  <li
                    data-aos="fade-in-down"
                    data-aos-delay={index * 200}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
            {data[type].extra && data[type].extra.footer && (
              <p style={{ marginTop: 15, fontFamily: 'JosefinSlab' }}>
                {data[type].extra.footer}
              </p>
            )}
          </Col>
          <Col sm={2}>
            <Photo>
              <LazyLoadImage
                height="100%"
                src={data[type].photo}
                style={{ objectFit: 'cover' }}
                width="100%"
              />
            </Photo>
          </Col>
        </Row>
        <Row style={{ marginTop: 25 }}>
          <Col sm={4}></Col>
          <Col sm={8}>
            <Slider {...settings} className="slider">
              {data[type].sliders &&
                data[type].sliders.length > 0 &&
                data[type].sliders.map((slider, index) => (
                  <div key={index}>
                    <LazyLoadImage
                      height={up('md') ? 500 : 1000}
                      src={slider}
                      style={{ objectFit: 'cover', borderRadius: 5 }}
                      width="100%"
                    />
                  </div>
                ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0px 100px 0px;
`
const Photo = styled.div`
  height: 250px;
  width: 200px;
`
const Title = styled.h3`
  font-size: 25px;
  font-family: 'JosefinSlab';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`
const ServicesItems = styled.ul`
  list-style: 'none';
  font-family: 'JosefinSlab';
`
const ServiceItem = styled.a`
  margin: 10px 0px;
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  &:hover {
  }
`
const ServiceTitle = styled.span`
  color: #c09e6f;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 1.3em;
  font-family: 'JosefinSlab';
`
const ServiceDescription = styled.p`
  font-family: 'JosefinSlab';
  margin-top: 15px;
  line-height: 25px;
`

export default Services
