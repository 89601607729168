import React, { useState } from 'react'
import Layout from '../../Containers/Layout'
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Grid,
  SegmentedControl,
  Tabs,
  Text,
} from '@mantine/core'
import {
  IconArrowLeft,
  IconCalendar,
  IconInfoCircle,
  IconMail,
  IconMap,
  IconMoneybag,
  IconPhone,
  IconUser,
} from '@tabler/icons-react'
import styled from 'styled-components'
import Management from '../../Containers/Management'
import Payroll from '../../../../components/HRManagement/Payroll/Payroll'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Information from '../../../../components/HRManagement/Employee/Information'
import TabsContainer from '../../../../components/HRManagement/Employee/TabsContainer'

const DetailEmployee = () => {
  const [tabValue, setTabValue] = useState(1)
  const { id } = useParams()
  const employee = useSelector(
    (state) =>
      state.employees.employees &&
      state.employees.employees.filter((employee) => employee._id === id)[0],
  )

  return (
    <div>
      <Management>
        <Grid mt={15}>
          <Grid.Col md={2}>
            <Card>
              <Back>
                <Icon>
                  <IconArrowLeft size={20} />
                </Icon>{' '}
                Retourner vers la liste
              </Back>
              <CardContent>
                <Avatar src={employee.avatar} />
                <Username>John Doe</Username>
                <Service>Service traiteur</Service>
                <Items>
                  <Item>
                    <IconMail size={20} />
                  </Item>
                  <Item>
                    <IconPhone size={20} />
                  </Item>
                  <Item>
                    <IconMap size={20} />
                  </Item>
                </Items>
                <Button color="dark" fullWidth mt={15}>
                  Contactez
                </Button>
                <Tabs color="dark" defaultValue="first" mt={20}>
                  <Tabs.List grow>
                    <Tabs.Tab value="first">Information</Tabs.Tab>
                    <Tabs.Tab value="second">Paiement</Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="first" pt="xs">
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Sexe
                      </Text>
                      <Text fz={13}>{employee.sexe}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Email
                      </Text>
                      <Text fz={13}>{employee.email}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        CIN
                      </Text>
                      <Text fz={13}>{employee.cin}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Téléphone
                      </Text>
                      <Text fz={13}>{employee.phone}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Numéro
                      </Text>
                      <Text fz={13}>{employee.numero}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Date de naissance
                      </Text>
                      <Text fz={13}>
                        {moment(employee.birthday).format('DD/MM/YYYY')}
                      </Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Service
                      </Text>
                      <Text fz={13}>{employee.department}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Banque
                      </Text>
                      <Text fz={13}>{employee.bankId}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Status
                      </Text>
                      <Badge>
                        <Text>{employee.status}</Text>
                      </Badge>
                    </div>
                  </Tabs.Panel>
                  <Tabs.Panel value="second" pt="xs">
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        Banque
                      </Text>
                      <Text fz={13}>{employee.bankId}</Text>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Text c="dimmed" fz={14}>
                        IBAN
                      </Text>
                      <Text fz={13}>{employee.iban}</Text>
                    </div>
                  </Tabs.Panel>
                </Tabs>
              </CardContent>
            </Card>
          </Grid.Col>
          <Grid.Col md={10}>
            <TabsContainer tabValue={(value) => setTabValue(value)} />
            {tabValue == 1 && <Information employee={employee} />}
            {tabValue == 4 && (
              <Card>
                <Payroll />
              </Card>
            )}
          </Grid.Col>
        </Grid>
      </Management>
    </div>
  )
}

const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0px;
  font-family: 'Rubik';
`
const Avatar = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 15px;
`
const Username = styled.div`
  font-weight: 600;
  font-size: 20px;
`
const Service = styled.div`
  font-size: 14px;
`
const Items = styled.ul`
  display: inline-flex;
  margin-top: 10px;
  list-style: none;
`
const Item = styled.li`
  border: 1px solid #ddd;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`
const Back = styled.div`
  font-family: 'Rubik';
  font-size: 15px;
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  height: 35px;
  width: 35px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export default DetailEmployee
