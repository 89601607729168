import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  rem,
  Menu,
  Button,
} from '@mantine/core'
import {
  IconCreditCard,
  IconBuildingBank,
  IconRepeat,
  IconReceiptRefund,
  IconReceipt,
  IconReceiptTax,
  IconReport,
  IconCashBanknote,
  IconCoin,
  IconSettings,
  IconDashboard,
  IconUsers,
  IconMoneybag,
  IconPresentation,
  IconUserCheck,
  IconCalendarBolt,
  IconCalendar,
  IconCalendarOff,
  IconChartBar,
  IconChartArcs,
  IconArrowRight,
} from '@tabler/icons-react'
import { Link } from 'react-router-dom'

const mockdata = [
  { title: 'Tableau de bord', icon: IconDashboard, color: 'violet' },
  {
    title: 'Management',
    icon: IconBuildingBank,
    color: 'indigo',
    items: [],
  },
  {
    title: 'Employés',
    icon: IconUsers,
    color: 'blue',
    items: [
      { label: 'Liste des employés', href: '/hr/employee' },
      { label: 'Positions', href: '/' },
    ],
  },
  {
    title: 'Gestion de paie',
    icon: IconMoneybag,
    color: 'green',
    items: [
      { label: 'Avances', href: '/hr/advance' },
      { label: 'Salaire', href: '/hr/salaries' },
    ],
  },
  { title: 'Présence', icon: IconUserCheck, color: 'teal' },
  {
    title: 'Gestion des congés',
    icon: IconCalendar,
    color: 'cyan',
    items: [
      { label: 'Type', href: '' },
      { label: 'Liste des congés', href: '/hr/leaves' },
    ],
  },
  { title: 'Jours fériés', icon: IconCalendarOff, color: 'pink' },
  {
    title: 'Statistiques',
    icon: IconChartArcs,
    color: 'red',
    items: ['Sauvegarder la base'],
  },
]

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: rem(90),
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.05)',
    },
  },
}))

const Service = () => {
  const { classes, theme } = useStyles()

  const items = mockdata.map((item) => (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <UnstyledButton key={item.title} className={classes.item}>
          <item.icon color={theme.colors[item.color][6]} size="2rem" />
          <Text size="xs" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      </Menu.Target>
      {item.items && item.items.length > 0 && (
        <Menu.Dropdown>
          <Menu.Label>Application</Menu.Label>
          {item.items &&
            item.items.length > 0 &&
            item.items.map((item, key) => (
              <Menu.Item key={key}>
                <Link to={`/admin${item.href}`}>{item.label}</Link>
              </Menu.Item>
            ))}
        </Menu.Dropdown>
      )}
    </Menu>
  ))

  return (
    <SimpleGrid cols={8} mt="md">
      {items}
    </SimpleGrid>
  )
}

export default Service
