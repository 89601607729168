import axios from 'axios'
import { CLOSE_MODAL, CREATE_NEW_ATTENDANCE, FETCH_ATTENDANCE } from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchAttendance = (allRecord = false) => {
  return async (dispatch, getState) => {
    let url = `${API}attendance?limit=10`
    const { data } = await axios.get(url)
    dispatch({
      type: FETCH_ATTENDANCE,
      payload: data,
    })
  }
}

export const createAttendance = (values) => {
  return async (dispatch, getState) => {
    const formValues = Object.keys(values).reduce((formData, key) => {
      formData.append(key, values[key])
      return formData
    }, new FormData())

    try {
      const { data } = await axios.post(`${API}attendances`, formValues)
      dispatch({
        type: CREATE_NEW_ATTENDANCE,
        payload: data.data,
      })
      dispatch({
        type: CLOSE_MODAL,
        payload: { type: 'attendance' },
      })
    } catch (err) {}
  }
}
