import { CREATE_NEW_ATTENDANCE, FETCH_ATTENDANCE } from '../types'

const initialState = {
  attendances: [],
}

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE: {
      return {
        ...state,
        attendances: action.payload?.attendance.data,
      }
    }
    case CREATE_NEW_ATTENDANCE: {
      const { attendance } = action.payload
      return {
        ...state,
        attendances: [...state.attendances, attendance],
      }
    }
    default: {
      return state
    }
  }
}

export default attendanceReducer
