import React, { useEffect, useRef, useState } from 'react'
import Layout from '../Containers/Layout'
import {
  Text,
  Card,
  TextInput,
  FileInput,
  Button,
  Input,
  Select,
  Group,
  SimpleGrid,
  Divider,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { Col, Row } from 'react-grid-system'
import { TextEditor } from '../../../components/TextEditor'
import { usePostQuery } from '../../../hooks/usePostQuery'
import { useGetQuery } from '../../../hooks/useGetQuery'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'

const CreateOrEditProduct = ({ ...props }) => {
  const [files, setFiles] = useState([])
  const [specifications, setSpecifications] = useState([{ content: '' }])
  const [description, setDescription] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const spec = useRef(null)

  const { data: categories } = useGetQuery('categories')
  const { sendRequest, loading } = usePostQuery('products', 'POST', true)
  const productId = location.search.split('=').pop()

  const form = useForm({
    initialValues: {
      title: '',
      newPrice: '',
      availableStock: '',
      caption: '',
      categoryId: '',
    },
  })

  useEffect(() => {
    spec.current.scrollIntoView({ behavior: 'smooth' })
  }, [specifications])

  useEffect(() => {
    const getProductByID = async () => {
      const { data: product } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}products/${productId}`,
      )
      //populate form
      form.setValues(product)
      setDescription(product.description)
    }
    getProductByID()
  }, [productId])

  const onDeleteSpecification = (index) => {
    var array = [...specifications]
    if (index !== -1) {
      array.splice(index, 1)
      setSpecifications(array)
    }
  }

  const handleChangeSpecificiation = (e, index) => {
    setSpecifications((specifications) =>
      specifications.map((val, i) => (i !== index ? val : e.target.value)),
    )
  }

  const handleSubmit = async (values) => {
    const {
      availableStock,
      caption,
      categoryId,
      newPrice,
      title,
      description,
    } = values

    const formData = new FormData()
    formData.append('newPrice', newPrice)
    formData.append('description', description)
    formData.append('availableStock', availableStock)
    formData.append('categoryId', categoryId)
    formData.append('caption', caption)
    formData.append('title', title)
    formData.append('specifications', specifications)
    formData.append('file', files[0])
    try {
      if (productId) {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}products/${productId}`,
          formData,
        )
      } else {
        await sendRequest(formData)
      }
      navigate('/santika/admin/products')
    } catch (err) {}
  }

  return (
    <div>
      <Layout>
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          className="products"
        >
          <div style={{ maxWidth: 800, margin: '0 auto' }}>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Row>
                <Col md={6} lg={6} style={{ marginBottom: 15 }}>
                  <TextInput
                    size={'md'}
                    label="Nom"
                    {...form.getInputProps('title')}
                    required
                  />
                </Col>
                <Col md={6} lg={6}>
                  <TextInput
                    size={'md'}
                    label="Prix"
                    {...form.getInputProps('newPrice')}
                    required
                  />
                </Col>
                <Col md={6} lg={6}>
                  <TextInput
                    size={'md'}
                    label="Stock disponible"
                    type="number"
                    {...form.getInputProps('availableStock')}
                    required
                  />
                </Col>
                <Col md={6} lg={6}>
                  <TextInput
                    size={'md'}
                    label="Caption"
                    {...form.getInputProps('caption')}
                  />
                </Col>
                <Col md={6} lg={6} style={{ marginTop: 15 }}>
                  {categories && categories.length > 0 && (
                    <Select
                      label="Categorie"
                      placeholder="Selectionner"
                      transitionProps={{
                        transition: 'scale',
                        timingFunction: 'ease',
                      }}
                      style={{ fontFamily: 'JosefinSlab' }}
                      size="md"
                      data={categories.map((category) => ({
                        value: category._id,
                        label: category.name,
                      }))}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input.Wrapper
                    label="Description"
                    maw={'100%'}
                    mx="auto"
                    withAsterisk
                    mt={10}
                  >
                    <TextEditor
                      value={description}
                      content={(value) => setDescription(value)}
                    />
                  </Input.Wrapper>
                </Col>
              </Row>
              <FileInput
                mt={10}
                label="Pièce jointe"
                multiple
                onChange={(files) => setFiles(files)}
              />
              <div style={{ marginTop: 15 }} ref={spec}>
                {specifications.map((specification, index) => (
                  <SimpleGrid cols={2}>
                    <div>
                      <TextInput
                        size={'md'}
                        label={`Spécification n°${index + 1}`}
                        required
                        onChange={($event) =>
                          handleChangeSpecificiation($event, index)
                        }
                        value={specification.content}
                        mt={10}
                      />
                    </div>
                    <div>
                      <Button
                        color="red"
                        mt={35}
                        onClick={() => onDeleteSpecification(index)}
                      >
                        <IconTrash />
                      </Button>
                    </div>
                  </SimpleGrid>
                ))}
                <Button
                  color="primary"
                  mt={10}
                  onClick={() => {
                    setSpecifications([...specifications, { content: '' }])
                  }}
                >
                  Ajouter
                </Button>
              </div>
              <Divider mt={15} />
              <Button
                loading={loading}
                loaderPosition="left"
                type="submit"
                mt={10}
              >
                <IconPlus /> &nbsp; Envoyer
              </Button>
            </form>
          </div>
        </Card>
      </Layout>
    </div>
  )
}

export default CreateOrEditProduct
