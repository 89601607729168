import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Divider,
  Checkbox as MantineCheckbox,
  RangeSlider,
  Group,
} from '@mantine/core'
import { IconHeart } from '@tabler/icons-react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../store/actions/filter.action'
import { debounce } from 'lodash'
import { fetchProducts } from '../../store/actions/products.action'

const Filter = ({ price, onChange }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useSelector((state) => state.filter.filters)

  const dispatch = useDispatch()

  const allFilterClickListener = (event, value) => {
    const name = event.target.name
    const isChecked = event.target.checked
    dispatch(setFilter({ name, value, isChecked }))
  }

  useEffect(() => {
    for (let key in filters) {
      if ('prices' !== key) {
        searchParams.set(key, filters[key])
      }
      if (filters[key].length === 0) {
        searchParams.delete(key)
      }
    }
    setSearchParams(searchParams)
    dispatch(fetchProducts(searchParams.toString()))
  }, [filters])

  useEffect(() => {
    const [gte, lte] = price
    searchParams.set('filter.v.price.gte', gte)
    searchParams.set('filter.v.price.lte', lte)
    setSearchParams(searchParams)
    dispatch(fetchProducts(searchParams.toString()))
  }, [price])

  return (
    <FilterList>
      <Availability>
        <Label>availability</Label>
        <Checkbox
          radius={'xs'}
          mt={5}
          name="availability"
          onChange={(e) => allFilterClickListener(e, 'In stock')}
          color="gray"
          label="in stock"
        />
        <Checkbox
          mt={10}
          radius={'xs'}
          name="availability"
          onChange={(e) => allFilterClickListener(e, 'Out of stock')}
          color="gray"
          label="out of stock"
        />
      </Availability>
      <Divider />
      <Price>
        <Label>price</Label>
        <RangeSlider
          color="gray"
          thumbSize={14}
          mt="xl"
          size={5}
          defaultValue={price}
          onChange={debounce(onChange, 500)}
          style={{ marginTop: 0 }}
          min={0}
          max={125000}
          thumbChildren={<IconHeart size="2rem" />}
        />
        <Group grow>
          <RangePrice>
            <div>Ar</div>
            <div>{price[0]}</div>
          </RangePrice>
          <RangePrice>
            <div>Ar</div>
            <div>{price[1]}</div>
          </RangePrice>
        </Group>
      </Price>
      <Divider />
      <ProductType>
        <Label>product type</Label>
        <Checkbox
          radius={'xs'}
          onChange={(e) => allFilterClickListener(e, 'Custom')}
          color="gray"
          name="product_type"
          mt={5}
          label="custom"
        />
        <Checkbox
          mt={10}
          color="gray"
          name="product_type"
          onChange={(e) => allFilterClickListener(e, 'Normal')}
          radius={'xs'}
          label="normal"
        />
      </ProductType>
    </FilterList>
  )
}

const FilterList = styled.div``
const Availability = styled.div`
  margin-bottom: 20px;
`
const Price = styled.div`
  margin: 20px 0px;
`
const Label = styled.p`
  font-family: 'Futura';
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
`
const Checkbox = styled(MantineCheckbox)`
  text-transform: uppercase;
  font-family: 'Futura';
  letter-spacing: 2px;
  font-weight: 100;
`
const RangePrice = styled.div`
  height: 40px;
  width: 100%;
  border: 1px solid #f5f5f5;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  font-size: 12px;
  color: #333;
`
const ProductType = styled.div`
  margin-top: 20px;
`
export default Filter
