import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Navigation, Pagination } from 'swiper'
import Button from './common/Button'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import styled from 'styled-components'
import { up, down } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'

const sliders = [
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734831/sliders/9_gwm6bx.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734855/sliders/4_exhzvk.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734843/sliders/1_fiskd6.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734842/sliders/6_vf3jie.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734836/sliders/7_dc57ou.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734834/sliders/5_ouc0tc.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679734821/sliders/14_l9fnep.jpg',
]

export const ImageSlider = () => {
  const { t } = useTranslation()

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      className="swiper"
      effect={'fade'}
      navigation={true}
      speed={500}
      autoplay={true}
      parallax={true}
      pagination={{
        clickable: true,
      }}
      modules={[EffectFade, Navigation, Pagination]}
    >
      {sliders.map((slider, index) => (
        <SwiperSlide key={index} style={{ position: 'relative' }}>
          <LazyLoadImage
            src={slider}
            alt="test"
            placeholderSrc="/images/slider_placeholder.jpg"
            width={'100%'}
            height={'100%'}
          />
          <SwiperOverlay />
        </SwiperSlide>
      ))}
      <SwiperCaptionContainer>
        <SwiperCaption>
          {t('slider.part_one')} <br />
          {t('slider.part_two')}
        </SwiperCaption>
        <div class="scroll-arrow"></div>
        <div class="scroll-arrow"></div>
        <div class="scroll-arrow"></div>
      </SwiperCaptionContainer>
    </Swiper>
  )
}

const SwiperOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  top: 0;
`
const SwiperCaptionContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${up('md')} {
    background: rgba(184, 127, 132, 0.65);
    border-radius: 50%;
    width: 450px;
    height: 450px;
    padding: 25px 50px;
  }
`
const SwiperCaption = styled.div`
  display: flex;
  justify-content: center;
  ${up('md')} {
    font-size: 25px;
    line-height: 40px;
  }
  ${down('md')} {
    line-height: 20px;
  }
  padding-bottom: 25px;
  color: #fff;
  font-weight: normal;
  text-align: center;
  font-family: 'JosefinSlab';
  letter-spacing: 2px;
`
