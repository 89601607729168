import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../store/actions/modal.action'
import { ImageSlider } from './ImageSlider'
import Bag from '../icons/Bag'
import { down } from 'styled-breakpoints'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { IconLogout, IconUserCircle } from '@tabler/icons-react'
import { useAuthContext } from '../context/AuthContext'
import IconHeart from './icons/IconHeart'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const Slider = ({ withSlider }) => {
  const [scrolled, setScrolled] = useState(false)
  const dispatch = useDispatch()
  const menuRef = useRef(null)
  const { t } = useTranslation()
  const { isLoggedIn } = useAuthContext()

  const cartProduct = useSelector((state) => state.cart.cart)
  const wishlist = useSelector((state) => state.wishlist.wishlist)

  useLayoutEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY > 0) {
        menuRef.current.style.position = 'fixed'
        menuRef.current.style.top = 0
        menuRef.current.style.backgroundColor = '#fff'
        menuRef.current.style.transition = '0.5s'
        menuRef.current.style.zIndex = 200
        setScrolled(true)
      } else {
        menuRef.current.style.position = 'position'
        menuRef.current.style.top = '50px'
        menuRef.current.style.backgroundColor = 'transparent'
        setScrolled(false)
      }
    }

    if (withSlider) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!withSlider) {
      menuRef.current.style.position = 'fixed'
      menuRef.current.style.top = 0
      menuRef.current.style.backgroundColor = '#fff'
      menuRef.current.style.transition = '0.5s'
      menuRef.current.style.zIndex = 200
      setScrolled(true)
    }
  }, [withSlider])

  const onLogout = () => {
    sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = `/auth`
  }

  return (
    <>
      {withSlider && <ImageSlider />}
      <div className="menu" ref={menuRef}>
        <MenuItems style={{ marginRight: 15 }}>
          <Item>
            <RouterLink to={'/'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.home')}
              </Link>
            </RouterLink>
          </Item>
          <Item>
            <RouterLink to={'/about-us'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.about')}
              </Link>
            </RouterLink>
          </Item>
          <Item>
            <RouterLink to={'/our-services/wedding'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.services')}
              </Link>
              <Dropdown>
                <ul style={{ listStyle: 'none', margin: 0 }}>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/wedding'}>
                      {t('menu.services_items.wedding')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/engagement'}>
                      {t('menu.services_items.engagement')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/birthday'}>
                      {t('menu.services_items.birthday')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/baptism'}>
                      {t('menu.services_items.baptism')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/familial'}>
                      {t('menu.services_items.familial')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/event'}>
                      {t('menu.services_items.corporate')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/promotion'}>
                      {t('menu.services_items.promotion')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/others'}>
                      {t('menu.services_items.other')}
                    </RouterLink>
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    <RouterLink to={'/our-services/formation'}>
                      {t('menu.services_items.formation')}
                    </RouterLink>
                  </li>
                </ul>
              </Dropdown>
            </RouterLink>
          </Item>
        </MenuItems>
        <div>
          <img
            src={
              !scrolled && withSlider
                ? '/images/logo/logo-santika.png'
                : '/images/santika.png'
            }
            width="130"
            style={{ padding: scrolled ? 15 : !withSlider ? 10 : 0 }}
            alt=""
          />
        </div>
        <MenuItems style={{ marginLeft: 15 }}>
          <Item>
            <RouterLink to={'/portfolio'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.portfolio')}
              </Link>
            </RouterLink>
          </Item>
          <Item>
            <RouterLink to={'/collections'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.shop')}
              </Link>
            </RouterLink>
          </Item>
          <Item>
            <RouterLink to={'/contact'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {t('menu.contact')}
              </Link>
            </RouterLink>
          </Item>
          <Item
            style={{
              borderLeft: '1px solid rgba(255,255,255,0.5)',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <RouterLink to={'/wishlist'}>
              <Link style={{ marginRight: 10, position: 'relative' }}>
                <WishlistCount scrolled={scrolled}>
                  {wishlist.length}
                </WishlistCount>
                <IconHeart color={scrolled ? '#333' : '#fff'} />
              </Link>
            </RouterLink>
            <Link onClick={() => dispatch(openModal({ type: 'cart' }))}>
              <BagWrapper>
                <Bag strokeColor={scrolled ? '#333' : '#fff'} />
                <CartCount>{cartProduct?.totalQuantity || 0}</CartCount>
              </BagWrapper>
            </Link>
          </Item>
          <Item style={{ padding: 0 }}>
            <RouterLink to={isLoggedIn ? '/' : '/auth'}>
              <Link isScrolled={scrolled} withSlider={withSlider}>
                {isLoggedIn ? (
                  <IconLogout onClick={onLogout} />
                ) : (
                  <IconUserCircle />
                )}
              </Link>
            </RouterLink>
          </Item>
        </MenuItems>
      </div>
    </>
  )
}

const MenuItems = styled.ul`
  list-style: none;
  ${down('md')} {
    display: none;
  }
`
const Dropdown = styled.div`
  position: absolute;
  text-align: left;
  background: #fff;
  padding: 10px 25px;
  font-family: JosefinSlab;
  font-size: 15px;
  text-transform: capitalize;
  display: none;
  transition: opacity 0.5s;
`
const Item = styled.li`
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
  padding: 0 25px;
  @media (max-width: 1127px) {
    padding: 0 10px;
  }
  &:hover {
    ${Dropdown} {
      display: block;
    }
  }
`
const Link = styled.a`
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  line-height: 1.3em;
  color: ${(props) =>
    props.isScrolled || !props.withSlider ? '#333' : 'rgba(255, 255, 255, 1)'};
  font-family: JosefinSlab;
  letter-spacing: 2.4px;
  position: relative;
  text-decoration: none;
  padding-bottom: 5px;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    margin-top: 15px;
    transition: transform 0.2s ease;
  }
  &:hover::before {
    transform: scaleX(1);
  }
  @media (max-width: 1211px) {
    font-size: 12px;
  }
`
const BagWrapper = styled.div`
  position: relative;
`
const CartCount = styled.p`
  position: absolute;
  top: -10px;
  right: -5px;
  background: #f3ddce;
  height: 20px;
  width: 20px;
  font-size: 10px;
  border-radius: 50%;
`
const WishlistCount = styled.div`
  position: absolute;
  right: -10px;
  top: -20px;
  background: #f3ddce;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #333;
`

export default Slider
