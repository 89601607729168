import axios from 'axios'
import { CREATE_NEW_LEAVE, DELETE_LEAVE, FETCH_LEAVES } from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchLeaves = () => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`${API}leaves?limit=10`)
    dispatch({
      type: FETCH_LEAVES,
      payload: data,
    })
  }
}

export const createLeave = (values) => {
  return async (dispatch, getState) => {
    delete values['EmployeeId']
    const formValues = Object.keys(values).reduce((formData, key) => {
      formData.append(key, values[key])
      return formData
    }, new FormData())

    try {
      const { data } = await axios.post(`${API}leaves`, formValues)
      dispatch({
        type: CREATE_NEW_LEAVE,
        payload: { leaves: data.data.leave, isUpdated: data.data.isUpdated },
      })
    } catch (err) {}
  }
}

export const deleteLeave = (id) => {
  return async (dispatch, getState) => {
    try {
      await axios.delete(`${API}leaves/${id}`)
      dispatch({
        type: DELETE_LEAVE,
        payload: { id },
      })
    } catch (err) {}
  }
}
