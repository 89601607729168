import * as React from 'react'
const IconReview = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M86.5 41c-12 1.4-20.7 5.5-29.8 14-6.9 6.4-11.4 13.8-14.4 23.5-1.7 5.7-1.8 13.9-1.8 177.5s.1 171.8 1.8 177.5c3 9.7 6.6 15.6 13.6 22.6 7 7 12.9 10.6 22.6 13.6 5.7 1.7 13.9 1.8 177.5 1.8s171.8-.1 177.5-1.8c9.7-3 15.6-6.6 22.6-13.6 7-7 10.6-12.9 13.6-22.6 1.7-5.7 1.8-13.9 1.8-177.5s-.1-171.8-1.8-177.5C464.1 60.7 452 48.4 434 42.4c-5.2-1.8-13.4-1.9-173.5-2-92.4-.1-170.7.2-174 .6zm345.9 23.9c6.3 3.1 11.6 8.4 14.7 14.7l2.4 4.9v343l-2.4 4.9c-3.1 6.3-8.4 11.6-14.7 14.7l-4.9 2.4h-343l-4.9-2.4c-2.7-1.4-6.6-4.1-8.6-6.1s-4.7-5.9-6.1-8.6l-2.4-4.9-.3-169C62 138 62.3 88.2 63 85c2.4-9.7 10.8-18.6 20.4-21.6 3.3-1 37.6-1.2 174.1-1.1l170 .2 4.9 2.4z" />
    <path d="M235 153.5c-19.5 50.9-20.6 53.5-23 53.5-7.9 0-110.4 6.1-110.9 6.5-.3.4-.1.9.5 1.3 5.1 3.4 86.4 70.7 86.4 71.6 0 .9-26.1 102.7-27.7 108-.4 1.4-.1 1.7.9 1.4.8-.3 22.3-14.2 47.8-30.7 25.5-16.6 46.8-30.1 47.5-29.9.6.2 22.1 14 47.8 30.7 25.8 16.7 47 30.2 47.2 29.9.2-.2-5.9-24.7-13.5-54.3-7.7-29.7-14-54.5-14-55.1 0-.9 81.1-68 86.4-71.6.6-.4.8-1 .4-1.3-.5-.5-100-6.4-109.8-6.5h-3.5L277 153.5c-11.3-29.4-20.7-53.5-21-53.5-.3 0-9.7 24.1-21 53.5zm34 39.7c6.7 17.8 12.8 32.8 13.5 33.4.8.6 16.2 1.8 36.7 2.8 19.4 1 35.4 2 35.6 2.1.2.2-47.1 39.3-54.5 45-1 .8.6 8.1 7.8 35.9 4.9 19.1 8.9 35 8.7 35.1-.2.2-13.8-8.4-30.2-19.1-16.4-10.7-30.2-19.4-30.6-19.4-.4 0-14.2 8.7-30.6 19.4-16.4 10.7-30 19.3-30.2 19.1-.2-.1 3.8-16 8.7-35.1 7.3-27.9 8.8-35.1 7.8-35.9-6.1-4.7-54.7-44.8-54.5-45 .2-.1 16.3-1 35.8-2 22.4-1.1 35.9-2.2 36.6-2.9.6-.6 6.7-15.6 13.4-33.4 6.8-17.7 12.6-32.2 13-32.2.4 0 6.2 14.5 13 32.2z" />
  </svg>
)
export default IconReview
