import React, { useState } from 'react'
import { notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons-react'
import { SESSION_TOKEN } from '../constant'
import { useSessionStorage } from './useSessionStorage'

export const usePostQuery = (
  url,
  method = 'POST',
  isFormData = false,
  headers = {},
) => {
  const [responseData, setResponseData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { setItem } = useSessionStorage()

  const sendRequest = async (data) => {
    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}${url}${
          ['DELETE', 'PUT'].includes(method) ? '/' + data.id : ''
        }`,
        {
          method,
          body: !isFormData ? JSON.stringify(data) : data,
          headers: {
            ...headers,
            sessionToken: sessionStorage.getItem('sessionToken'),
          },
        },
      )
      const json = await response.json()

      setResponseData(json)
      //save token
      if (json.hasOwnProperty('sessionToken')) {
        setItem(SESSION_TOKEN, json.sessionToken)
      }
      notifications.show({
        title: 'Notification',
        message: json.message,
        color: json.error ? 'red' : 'teal',
        icon: <IconCheck />,
      })
      setLoading(false)
    } catch (error) {
      setError(error.message)
      notifications.show({
        title: 'Notification',
        message: error.message,
        color: 'red',
      })
      setLoading(false)
    }
  }

  return { responseData, loading, error, sendRequest }
}
