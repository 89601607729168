import React from 'react'
import { deleteProductInCart } from '../store/actions/cart.action'
import { getEncryptKey, getStorage } from '../utils/encrypt'
import { IconTrash, IconBox } from '@tabler/icons-react'
import { QuantityInput } from './common/NumberInput'
import { Loader } from '@mantine/core'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

const CartItem = ({ key, cartProduct, ref, onChange }) => {
  const dispatch = useDispatch()

  return (
    <CardItem
      key={key}
      data-aos="slide-left"
      data-aos-delay="20"
      data-aos-duration="500"
      data-aos-easing="ease-in-out-cubic"
      ref={ref}
    >
      <CardItemImage>
        <img
          src={cartProduct.products.productImage}
          width={100}
          height={100}
          style={{
            borderRadius: 8,
            objectFit: 'cover',
            position: 'relative',
          }}
          alt=""
        />
      </CardItemImage>
      <CardItemContent>
        <ContentInner>
          <ItemInfo>
            <Title>
              <a href={cartProduct.products.url}>
                {cartProduct.products.title}
              </a>
            </Title>
            <Pricing>
              {cartProduct.products.newPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
              Ar
            </Pricing>
          </ItemInfo>
          {cartProduct.products.productType === 'custom' && (
            <>
              <Size style={{ display: 'flex', alignItems: 'center' }}>
                <IconBox size={15} style={{ marginRight: 5 }} />
                Produit sur commande
              </Size>
            </>
          )}
          <div style={{ display: 'flex' }}>
            <QuantityInput
              availableStock={cartProduct.products.availableStock}
              defaultValue={cartProduct.totalProductQuantity}
              value={onChange}
            />
            <DeleteItem>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    deleteProductInCart(cartProduct._id, getStorage('cart')),
                  )
                }}
              >
                <IconTrash size={20} />
              </a>
            </DeleteItem>
          </div>
        </ContentInner>
      </CardItemContent>
    </CardItem>
  )
}
const CardItem = styled.div`
  margin-top: 20px;
  font-family: 'JosefinSlab';
  margin-bottom: 25px;
`
const CardItemImage = styled.div`
  overflow: hidden;
  float: left;
  margin-right: 15px;
`
const CardItemContent = styled.div`
  width: 100%;
  text-align: 'left';
`
const ContentInner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  text-align: left;
`
const ItemInfo = styled.div``
const Title = styled.p`
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
`
const Pricing = styled.small`
  font-size: 12px;
  font-weight: 300;
`
const Size = styled.span`
  font-size: 12px;
  font-weight: 300;
`
const DeleteItem = styled.span`
  margin-top: 11px !important;
  text-decoration: underline;
  color: gray;
  position: absolute;
  right: 0;
`
const Overlay = styled.div`
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
`
export default CartItem
