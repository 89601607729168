import React from 'react'
import Service from '../../../components/HRManagement/Service'
import Layout from './Layout'

const Management = ({ children }) => {
  return (
    <Layout>
      <Service />
      <div className="main-content">{children}</div>
    </Layout>
  )
}

export default Management
