import { CREATE_NEW_SALARY, DELETE_SALARY, FETCH_SALARIES } from '../types'

const initialState = {
  salaries: [],
  totalPages: 0,
}

const salaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALARIES: {
      return {
        ...state,
        salaries: action.payload?.salaries.data,
        totalPages: action.payload?.salaries.totalPages,
      }
    }
    case CREATE_NEW_SALARY: {
      const { salary } = action.payload
      return {
        ...state,
        salaries: [...state.salaries, salary],
      }
    }
    case DELETE_SALARY: {
      const { id } = action.payload
      return {
        ...state,
        salaries: [...state.salaries.filter((salary) => salary._id !== id)],
      }
    }
    default: {
      return state
    }
  }
}

export default salaryReducer
