import { modals } from '@mantine/modals'
import { Text } from '@mantine/core'

export const openConfirmModal = ({ content, onConfirm }) => {
  modals.openConfirmModal({
    title: 'Veuillez confirmer votre action',
    centered: true,
    children: <Text size="sm">{content}</Text>,
    labels: { confirm: 'Supprimez', cancel: 'Annuler' },
    transitionProps: { transition: 'pop', duration: 200 },
    overlayProps: {
      opacity: 0.55,
      blur: 6,
    },
    onCancel: () => console.log('Cancel'),
    onConfirm,
  })
}
