import React, { useRef, useEffect } from 'react'
import { Container } from 'react-grid'
import { Row, Col } from 'react-grid-system'
import { Layout } from '../components/Layout'
import styled from 'styled-components'
import { IconMail, IconMapPin, IconPhoneOutgoing } from '@tabler/icons-react'
import { TextInput, NativeSelect, Textarea } from '@mantine/core'
import Button from '../components/common/Button'
import { useForm } from '@mantine/form'
import { DatePickerInput } from '@mantine/dates'
import { usePostQuery } from '../hooks/usePostQuery'

const Contact = () => {
  const contactRef = useRef(null)
  const { sendRequest } = usePostQuery('contacts', 'POST', false, {
    'Content-Type': 'application/json',
  })

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      waiting: '',
      type: '',
      budget: '',
      message: '',
      date: '',
    },
  })

  const handleSubmit = async (values) => {
    await sendRequest(values)
    form.reset()
  }

  return (
    <Layout withBg>
      <Wrapper style={{ fontFamily: 'JosefinSlab' }}>
        <Row ref={contactRef}>
          <Col lg={4} md={12} sm={12} data-aos="zoom-in-up">
            <Address>
              <Icon>
                <IconMapPin width={50} height={50} />
              </Icon>
              <Label>Adresse</Label>
              <Description>Ampandrana Madagascar, Antananarivo</Description>
            </Address>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            data-aos="zoom-in-up"
            data-aos-delay="300"
          >
            <Address>
              <Icon>
                <IconPhoneOutgoing width={50} height={50} />
              </Icon>
              <Label>Téléphone</Label>
              <Description>
                <ul style={{ listStyle: 'none', marginTop: 2 }}>
                  <li>+261 34 15 092 27 </li>
                  <li>+261 34 04 248 42</li>
                </ul>
              </Description>
            </Address>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            data-aos="zoom-in-up"
            data-aos-delay="400"
          >
            <Address>
              <Icon>
                <IconMail width={50} height={50} />
              </Icon>
              <Label>E-Mail</Label>
              <Description>
                <span>
                  E-mail:{' '}
                  <span style={{ color: '#c09e6f' }}>contact@santika.mg</span>
                </span>
              </Description>
            </Address>
          </Col>
        </Row>
        <form
          onSubmit={form.onSubmit(handleSubmit)}
          style={{ marginTop: 50 }}
          data-aos="fade-down"
          data-aos-delay="700"
        >
          <Row>
            <Col lg={6} md={12} sm={12}>
              <TextInput
                radius="xs"
                withAsterisk
                label="Nom"
                size={'md'}
                required
                style={{ fontFamily: 'JosefinSlab' }}
                {...form.getInputProps('name')}
              />
            </Col>
            <Col lg={6} md={12} sm={12}>
              <TextInput
                type="number"
                radius="xs"
                withAsterisk
                label="Téléphone"
                size={'md'}
                required
                style={{ fontFamily: 'JosefinSlab' }}
                {...form.getInputProps('phone')}
              />
            </Col>
            <Col lg={6} style={{ marginTop: 15 }} md={12} sm={12}>
              <TextInput
                radius="xs"
                size={'md'}
                withAsterisk
                label="Email"
                type={'email'}
                required
                style={{ fontFamily: 'JosefinSlab' }}
                {...form.getInputProps('email')}
              />
            </Col>
            <Col lg={6} md={12} sm={12} style={{ marginTop: 15 }}>
              <TextInput
                radius="xs"
                size={'md'}
                label="Type de service souhaité?"
                style={{ fontFamily: 'JosefinSlab' }}
                withAsterisk
                required
                {...form.getInputProps('type')}
              />
            </Col>
            <Col lg={6} md={12} sm={12} style={{ marginTop: 15 }}>
              <TextInput
                radius="xs"
                size={'md'}
                label="Vos attentes?"
                style={{ fontFamily: 'JosefinSlab' }}
                withAsterisk
                required
                {...form.getInputProps('waiting')}
              />
            </Col>
            <Col lg={6} md={12} sm={12} style={{ marginTop: 15 }}>
              <TextInput
                radius="xs"
                size={'md'}
                label="Votre budget estimatif?"
                style={{ fontFamily: 'JosefinSlab' }}
                withAsterisk
                required
                {...form.getInputProps('budget')}
              />
            </Col>
            <Col lg={6} md={12} sm={12} style={{ marginTop: 15 }}>
              <DatePickerInput
                clearable
                valueFormat="DD/MM/YYYY"
                label="Date prévue pour votre événement?"
                mx="auto"
                radius="xs"
                size={'md'}
                {...form.getInputProps('date')}
                style={{ fontFamily: 'JosefinSlab' }}
              />
            </Col>
            <Col lg={12} md={12} sm={12} style={{ marginTop: 15 }}>
              <Textarea
                label="Votre message"
                radius="xs"
                withAsterisk
                className="textarea"
                {...form.getInputProps('message')}
                size="lg"
                required
              />
            </Col>
            <Col
              lg={12}
              md={12}
              sm={12}
              style={{
                marginTop: 15,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button noHover={true}>Envoyer</Button>
            </Col>
          </Row>
        </form>
      </Wrapper>
    </Layout>
  )
}

const Address = styled.div``
const Label = styled.div`
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 1.3em;
`
const Description = styled.div`
  font-size: 14px;
`
const Icon = styled.div`
  float: left;
  padding-right: 15px;
  color: #c09e6f !important;
`
const Wrapper = styled(Container)`
  padding: 100px;
  @media (max-width: 716px) {
    padding: 50px;
  }
`
export default Contact
