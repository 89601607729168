import React from 'react'
import { useDisclosure } from '@mantine/hooks'
import {
  Box,
  Image,
  Modal,
  SimpleGrid,
  Text,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { closeModal, openModal } from '../store/actions/modal.action'
import Button from './common/Button'
import { IconX } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'

const AddCart = () => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const cartProduct = useSelector((state) => state.cart.cart)
  const { t } = useTranslation()
  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'addCart'
      ? state.modal.openModal.value
      : false,
  )
  const [opened, { close }] = useDisclosure(modalStatus)
  const smallScreen = useMediaQuery('(max-width: 800px)')

  return (
    <Modal
      opened={modalStatus}
      onClose={close}
      centered
      withCloseButton={false}
      closeOnClickOutside={true}
      size="xl"
      className="modal"
      radius={'xs'}
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 8,
      }}
    >
      <ModalHeader onClick={() => dispatch(closeModal({ type: 'addCart' }))}>
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <Inner>
          <Text
            fz="lg"
            transform="uppercase"
            fw={600}
            style={{ letterSpacing: 2 }}
            mb={10}
          >
            le produit est ajouté à votre panier:
          </Text>
          <SimpleGrid
            cols={4}
            breakpoints={[
              { maxWidth: '800px', cols: 2, spacing: 'sm' },
              { maxWidth: '36rem', cols: 2, spacing: 'sm' },
            ]}
          >
            {cartProduct && cartProduct.length === 0 && (
              <LoadingOverlay
                visible={true}
                overlayBlur={1}
                loaderProps={{ size: 'md', color: '#333', variant: 'bars' }}
              />
            )}
            {cartProduct &&
              cartProduct.items?.map((item, index) => (
                <div>
                  <Box maw={240} mx="auto">
                    <Image
                      radius="xs"
                      src={item.products.productImage}
                      alt="Random unsplash image"
                      caption={item.products.title}
                      height={120}
                    />
                  </Box>
                </div>
              ))}
          </SimpleGrid>
          <Action>
            <SimpleGrid
              cols={2}
              breakpoints={[
                { maxWidth: '62rem', cols: 3, spacing: 'md' },
                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                { maxWidth: '36rem', cols: 1, spacing: 'sm' },
              ]}
            >
              <div>
                <Button
                  noHover={true}
                  style={{
                    marginRight: 5,
                    background: '#d3d3d3',
                    borderColor: '#d3d3d3',
                    color: '#242629',
                    width: '100%',
                    fontSize: 12,
                  }}
                  onClick={() => dispatch(closeModal({ type: 'addCart' }))}
                >
                  continuer {!smallScreen ? ' mon shopping' : ''}
                </Button>
              </div>
              <div>
                <Button
                  style={{ width: '100%', fontSize: 12 }}
                  noHover={true}
                  onClick={() => {
                    dispatch(closeModal({ type: 'addCart' }))
                    dispatch(openModal({ type: 'cart' }))
                  }}
                >
                  voir mon panier
                </Button>
              </div>
            </SimpleGrid>
          </Action>
        </Inner>
      </ModalBody>
    </Modal>
  )
}
const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9999;
`
const ModalBody = styled.div`
  position: relative;
  padding: 10px;
  font-family: 'JosefinSlab';
`
const Inner = styled.div`
  border: 1px solid #eee;
  padding: 15px 35px;
`
const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
export default AddCart
