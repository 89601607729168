import { HIDE_LOADER, SHOW_LOADER } from '../types'

const initialState = {
  isLoading: [],
}

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
    case HIDE_LOADER: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default loaderReducer
