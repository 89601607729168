import React, { useEffect, useState } from 'react'
import {
  Card,
  Badge,
  Text,
  TextInput,
  ActionIcon,
  LoadingOverlay,
} from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable } from 'mantine-datatable'
import Filter from '../../../../components/HRManagement/Filter'
import Management from '../../Containers/Management'
import { fetchLeaves } from '../../../../store/actions/leave.action'
import { useDebouncedValue } from '@mantine/hooks'
import {
  IconDownload,
  IconEdit,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react'
import moment from 'moment'
import NewLeave from '../../../../components/HRManagement/Leave/NewLeave'
import sortBy from 'lodash/sortBy'
import { deleteLeave } from '../../../../store/actions/leave.action'
import { openConfirmModal } from '../../../../utils/openConfirmModal'
import { openModal } from '../../../../store/actions/modal.action'
import axios from 'axios'

const PAGE_SIZES = [10, 15, 20]
const Leaves = () => {
  const dispatch = useDispatch()
  const leaves = useSelector((state) => state.leaves.leaves)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [debouncedQuery] = useDebouncedValue(query, 200)
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
  const [records, setRecords] = useState(leaves?.slice(0, pageSize))
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: 'numero',
    direction: 'asc',
  })

  useEffect(() => {
    setPage(1)
    dispatch(fetchLeaves())
  }, [pageSize])

  useEffect(() => {
    const from = (page - 1) * pageSize
    const to = from + pageSize
    setRecords(leaves?.slice(from, to))
  }, [page, pageSize])

  useEffect(() => {
    setRecords(
      leaves.filter(({ numero, type, validator }) => {
        if (
          debouncedQuery !== '' &&
          !`${numero} ${type} ${validator}`
            .toLowerCase()
            .includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false
        }
        return true
      }),
    )
  }, [debouncedQuery])

  useEffect(() => {
    const data = sortBy(leaves, sortStatus.columnAccessor)
    setRecords(sortStatus.direction === 'desc' ? data.reverse() : data)
  }, [sortStatus])

  const onDelete = (_id) => {
    openConfirmModal({
      content:
        "Cette action est si importante que vous devez la confirmer avec un modal. Veuillez cliquer sur l'un de ces boutons pour continuer.",
      onConfirm: () => dispatch(deleteLeave(_id)),
    })
  }

  const onUpdate = (_id) => {
    const leave = leaves.filter((leave) => leave._id === _id)[0]
    dispatch(openModal({ type: 'leave', extra: { leave } }))
  }

  const onDownload = async (_id) => {
    //use redux
    setLoading(true)
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}leaves/export/pdf/${_id}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    )
    var blob = new Blob([data], { type: 'application/pdf' })
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    var fileName = 'demande-en-congée.pdf'
    link.download = fileName
    link.click()
    setLoading(false)
  }

  return (
    <div>
      <Management>
        <Filter
          type="leave"
          onSearch={(e) => setQuery(e.currentTarget.value)}
          query={query}
        />
        <NewLeave />
        <Card padding="lg" radius="md" mt={10} className="hr-management">
          <LoadingOverlay
            visible={isLoading}
            overlayBlur={1.5}
            transitionDuration={500}
            loaderProps={{ variant: 'bars', color: 'dark' }}
          />
          <Card.Section
            style={{
              background: 'rgb(34, 139, 230)',
              color: '#fff',
              padding: 8,
              fontWeight: 600,
            }}
          >
            <Text align="center">Liste des congés</Text>
          </Card.Section>
          <Card.Section>
            <DataTable
              withBorder
              withColumnBorders
              records={leaves}
              columns={[
                {
                  accessor: 'numero',
                  title: 'Numero',
                  sortable: true,
                  render: ({ numero }) => numero,
                },
                {
                  accessor: 'employee',
                  title: 'Employé',
                  sortable: true,
                  render: ({ employee }) => <span>{employee?.name}</span>,
                },
                {
                  accessor: 'employee',
                  title: 'Téléphone',
                  render: ({ employee: { phone } }) => <span>{phone}</span>,
                  sortable: true,
                },
                { accessor: 'type', title: 'Type', sortable: true },
                {
                  accessor: 'isPaid',
                  title: 'Paiement',
                  sortable: true,
                  render: ({ isPaid }) => (
                    <Badge
                      variant="filled"
                      color={isPaid ? 'blue' : 'teal'}
                      radius={0}
                    >
                      {isPaid ? 'Payé' : 'Non payé'}
                    </Badge>
                  ),
                },
                {
                  accessor: 'startDate',
                  title: 'Date de début',
                  sortable: true,
                  render: ({ startDate }) =>
                    moment(startDate).format('DD/MM/YYYY'),
                },
                {
                  accessor: 'endDate',
                  title: 'Date de retour',
                  sortable: true,
                  render: ({ endDate }) => moment(endDate).format('DD/MM/YYYY'),
                },
                { accessor: 'nbDay', title: 'Journées', sortable: true },
                {
                  accessor: 'status',
                  title: 'Statut',
                  render: ({ status }) => (
                    <Badge variant="filled" color="teal" radius={0}>
                      {status}
                    </Badge>
                  ),
                },
                {
                  accessor: '',
                  title: 'Action',
                  render: ({ _id }) => (
                    <div style={{ display: 'flex' }}>
                      <ActionIcon
                        color="blue"
                        variant="filled"
                        onClick={() => onUpdate(_id)}
                        mr={3}
                      >
                        <IconEdit size="1.125rem" />
                      </ActionIcon>
                      <ActionIcon
                        color="red"
                        variant="filled"
                        mr={3}
                        onClick={() => onDelete(_id)}
                      >
                        <IconTrash size="1.125rem" />
                      </ActionIcon>
                      <ActionIcon
                        color="dark"
                        variant="filled"
                        onClick={() => onDownload(_id)}
                        mr={3}
                      >
                        <IconDownload size="1.125rem" />
                      </ActionIcon>
                    </div>
                  ),
                },
              ]}
              totalRecords={leaves.length}
              paginationColor="dark"
              recordsPerPage={pageSize}
              page={page}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
            />
          </Card.Section>
        </Card>
      </Management>
    </div>
  )
}

export default Leaves
