import { combineReducers } from 'redux'
import cartReducer from './cartReducer'
import modalReducer from './modalReducer'
import productsReducer from './productsReducer'
import productModalReducer from './productModalReducer'
import wishlistReducer from './wishlistReducer'
import filterReducer from './filterReducer'
import articleReducer from './articleReducer'
import employeeReducer from './employeeReducer'
import loaderReducer from './loader.reducer'
import leaveReducer from './leaveReducer'
import attendanceReducer from './attendanceReducer'
import salaryReducer from './salaryReducer'

export default combineReducers({
  modal: modalReducer,
  cart: cartReducer,
  loader: loaderReducer,
  products: productsReducer,
  articles: articleReducer,
  productModal: productModalReducer,
  wishlist: wishlistReducer,
  filter: filterReducer,
  employees: employeeReducer,
  leaves: leaveReducer,
  attendances: attendanceReducer,
  salaries: salaryReducer,
})
