import { CREATE_NEW_EMPLOYEE, DELETE_EMPLOYEE, FETCH_EMPLOYEE } from '../types'

const initialState = {
  employees: [],
}

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE: {
      return {
        ...state,
        employees: action.payload?.employees.data,
      }
    }
    case CREATE_NEW_EMPLOYEE: {
      const { employee, isUpdated } = action.payload
      return {
        ...state,
        employees: !isUpdated && [...state.employees, employee],
      }
    }
    case DELETE_EMPLOYEE: {
      const { id } = action.payload
      return {
        ...state,
        employees: [
          ...state.employees.filter((employee) => employee._id !== id),
        ],
      }
    }
    default: {
      return state
    }
  }
}

export default employeeReducer
