import { OPEN_MODAL_PRODUCT, CLOSE_MODAL_PRODUCT } from '../types'

const initialState = {
  openModal: false,
  product: {},
}

const productModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_PRODUCT:
      const { modalStatus, product } = action.payload
      return {
        ...state,
        openModal: modalStatus,
        product,
      }
    case CLOSE_MODAL_PRODUCT:
      return {
        ...state,
        openModal: action.payload.modalStatus,
      }
    default:
      return state
  }
}

export default productModalReducer
