import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../store/actions/modal.action'
import { ActionIcon, Text } from '@mantine/core'
import { IconEdit, IconTrash, IconView360 } from '@tabler/icons-react'
import { deleteEmployee } from '../../../store/actions/employee.action'
import { Link } from 'react-router-dom'
import { openConfirmModal } from '../../../utils/openConfirmModal'

const EmployeeItem = ({ employee }) => {
  const dispatch = useDispatch()

  const openDeleteModal = (id) => {
    openConfirmModal({
      content:
        "Cette action est si importante que vous devez la confirmer avec un modal. Veuillez cliquer sur l'un de ces boutons pour continuer.",
      onConfirm: () => dispatch(deleteEmployee(id)),
    })
  }

  return (
    <tr>
      <td>{employee.name}</td>
      <td>{employee.numero}</td>
      <td>{employee.department}</td>
      <td>{employee.phone}</td>
      <td>{employee.email}</td>
      <td>{employee.city}</td>
      <td>{employee.cin}</td>
      <td>{employee.status}</td>
      <td>{employee.familyStatus}</td>
      <td style={{ display: 'flex' }}>
        <ActionIcon
          color="red"
          variant="filled"
          mr={3}
          onClick={() => openDeleteModal(employee._id)}
        >
          <IconTrash size="1.125rem" />
        </ActionIcon>
        <ActionIcon color="dark" variant="filled">
          <Link to={`/admin/hr/employee/${employee._id}`}>
            <IconView360 size="1.125rem" />
          </Link>
        </ActionIcon>
      </td>
    </tr>
  )
}

export default EmployeeItem
