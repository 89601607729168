import * as React from 'react'
const IconBox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="m271.3 31.5-22.7 19.6-9.1-9.6c-8.7-9.2-9.1-9.5-13.4-9.5-4.4 0-4.7.2-19.1 14.5L192.4 61h-38l-51.5 39.6c-28.2 21.8-53.3 40.9-55.7 42.6-4 2.8-5.6 5.7-25.7 45.9-24.1 48-24.5 49.2-18.4 54.3 5 4.2 8.9 3.6 23.9-3.9l13-6.5.2 105.3c.3 99.5.4 105.4 2.1 108 1 1.6 2.9 3.2 4.3 3.7 1.6.6 61.9 1 158.7 1 146.9 0 156.4-.1 158.9-1.8 1.4-.9 21.6-25.4 44.7-54.5L451 342V240.5l30.5-30.5 30.5-30.6V175c0-4.1-2.6-8.9-30.4-55.2-16.7-27.9-30.7-51.6-31.1-52.8-.4-1.2-2.1-3-3.8-4.1-3-1.8-5.6-1.9-50.4-1.9h-47.2l-22.6-23.9c-12.5-13.2-23.6-24.2-24.8-24.5-6.2-1.6-7-1.1-30.4 18.9zm68.6 51 35.9 38-11.1 11.2-11.2 11.3h-12l-12-.1-32-36c-17.6-19.8-32.6-36.8-33.3-37.8-1.1-1.6.6-3.3 15.9-16.5l17.2-14.7 3.3 3.3c1.9 1.8 19.5 20.4 39.3 41.3zM263 100.6c20.5 22.8 37 41.7 36.7 42-.2.2-35.4.3-78.3.2l-77.9-.3 40.9-41.8c22.5-22.9 41-41.7 41.2-41.7.2 0 17 18.7 37.4 41.6zm-120.6 11.9L112.6 143H82.8l39.8-30.5C162.2 82.3 162.5 82 167.3 82h4.9l-29.8 30.5zm260.4-18.8-11.7 11.7-2.2-2c-1.2-1-6.2-6.3-11.1-11.7l-9-9.7h45.7l-11.7 11.7zm60.7 37.4 24.8 41.1-33.1 33.1c-18.2 18.2-33.3 32.9-33.5 32.7-.2-.3-11.5-18.9-25.1-41.5l-24.6-41 32.7-32.7c18-18.1 33.1-32.8 33.4-32.8.3 0 11.7 18.5 25.4 41.1zM348 297v133H62V164h286v133zm43.5-67.7c18.5 31.2 21.1 35 24.2 36.3 4.1 1.8 8.1 1.1 11.8-1.7l2.5-2v71.6l-29.6 37c-16.2 20.3-30 37.4-30.5 37.9-.5.6-.9-41.1-.9-107.4 0-62.2.4-108 .9-107.5.5.6 10.2 16.7 21.6 35.8z" />
    <path d="M147.2 205.5c-7.2 3.2-7.8 14.4-.9 18.5 3.1 1.9 5.4 2 57.8 2h54.6l3.4-2.3c6-4.1 6.3-12.8.6-17.1-2.8-2.1-3.7-2.1-57.5-2.3-45.8-.2-55.2 0-58 1.2z" />
  </svg>
)
export default IconBox
