import React, { useEffect } from 'react'
import { Row, Col } from 'react-grid-system'
import { Layout } from '../../components/Layout'
import { useSelector } from 'react-redux'
import { IconShoppingBag } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Accordion, Divider } from '@mantine/core'
import { openModal } from '../../store/actions/modal.action'
import styled from 'styled-components'
import ProductItem from '../../components/ProductItem'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import {
  fetchProducts,
  getSingleProduct,
} from '../../store/actions/products.action'
import { addProductToCart } from '../../store/actions/cart.action'
import WishlistButton from '../../components/WishlistButton'
import { useTranslation } from 'react-i18next'
import { Features } from '../../components/Features'

const ProductDetail = () => {
  let { slug } = useParams()
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products.products)
  const product = useSelector((state) => state.products.product)
  const uniqueKey = useSelector((state) => state.cart.uniqueKey)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchProducts())
    dispatch(getSingleProduct(slug))
    window.scrollTo(0, 0)
  }, [])

  const addToCart = (product) => {
    // add item into cart
    if (product.productType === 'custom') {
      dispatch(
        openModal({ type: 'customProduct', extra: { product: product._id } }),
      )
    } else {
      dispatch(
        addProductToCart({
          productId: product._id,
          key: uniqueKey,
          quantity: 1,
        }),
      )
      //open modal cart
      dispatch(openModal({ type: 'addCart' }))
    }
  }

  return (
    <Layout withSlider={false}>
      <Features />
      <Container>
        <Row>
          <Col sm={6}>
            <InnerImageZoom
              src={product.productImage}
              height={500}
              zoomType="hover"
              style={{ height: '800px !important' }}
            />
            <div
              style={{
                position: 'absolute',
                top: 5,
                right: 15,
                cursor: 'pointer',
              }}
            >
              <WishlistButton slug={product.slug} />
            </div>
          </Col>
          <Col sm={6}>
            <ProductInfo>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductPrice>
                {product.newPrice?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}{' '}
                Ar
              </ProductPrice>
            </ProductInfo>
            <Button
              onClick={() => addToCart(product)}
              style={{ borderRadius: 0, width: '100%' }}
            >
              <IconShoppingBag style={{ marginRight: 5 }} />{' '}
              {product.productType === 'custom'
                ? t('shop.order')
                : t('shop.add')}
            </Button>
            <Accordion defaultValue="customization" mt={15}>
              <Accordion.Item value="customization">
                <Accordion.Control
                  style={{
                    textTransform: 'uppercase',
                    fontFamily: 'JosefinSlab',
                    letterSpacing: 1.3,
                    fontWeight: 'bold',
                    fontSize: 14,
                    background: '#F6F6F6',
                  }}
                >
                  {t('productDetail.specification')}
                </Accordion.Control>
                <Accordion.Panel>
                  {product.specifications &&
                    product.specifications.map((specification, index) => (
                      <div style={{ marginBottom: 14 }}>
                        <SpecItem>
                          <SpecTitle>
                            {
                              (
                                specification.content.charAt(0).toUpperCase() +
                                specification.content.slice(1)
                              ).split(':')[0]
                            }
                          </SpecTitle>
                          <SpecValue>
                            {specification.content.split(':').pop() !==
                            specification.content.split(':')[0]
                              ? specification.content.split(':').pop()
                              : '-'}
                          </SpecValue>
                        </SpecItem>
                        <Divider />
                      </div>
                    ))}
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="shipping">
                <Accordion.Control
                  style={{
                    textTransform: 'uppercase',
                    fontFamily: 'JosefinSlab',
                    letterSpacing: 1.3,
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                >
                  {t('productDetail.shippingInfo')}
                </Accordion.Control>
                <Accordion.Panel>
                  <img src="/images/dhl-1.jpeg" width={80} alt="" />
                  &nbsp; DHL Service
                  <ul
                    style={{
                      marginLeft: 5,
                      marginTop: 15,
                      listStyle: 'none',
                      fontFamily: 'JosefinSlab',
                    }}
                  >
                    <li>1 - 2 jours ouvrable</li>
                    <li>Livraison à domicile</li>
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="payment">
                <Accordion.Control
                  style={{
                    textTransform: 'uppercase',
                    fontFamily: 'JosefinSlab',
                    letterSpacing: 1.3,
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                >
                  {t('productDetail.paymentMethod')}
                </Accordion.Control>
                <Accordion.Panel>
                  <img src="/images/payment.png" height={30} alt="" />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <RelatedProduct>
              <Title>{t('shop.similar')}</Title>
              <Row style={{ maxWidth: 1300, margin: '0 auto' }}>
                {products?.data.slice(4).map((product, index) => (
                  <ProductItem {...product} />
                ))}
              </Row>
            </RelatedProduct>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const SpecItem = styled.div`
  font-family: 'JosefinSlab';
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
`
const SpecTitle = styled.span`
  font-size: 15px;
  font-weight: 300;
  color: #000000;
`
const SpecValue = styled.div`
  font-weight: bold;
`
const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    padding: 0 15px;
  }
`
const Container = styled.div`
  @media only screen and (max-width: 770px) {
    margin: 10px;
  }
  margin: 40px;
  font-family: 'JosefinSlab';
`
const ProductTitle = styled.h1`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  width: 400px;
`
const ProductPrice = styled.span`
  display: block;
  margin: 20px 6px 8px 0;
  font-size: 20px;
`
const Button = styled.button`
  border: none;
  background: #000;
  padding: 15px 22px;
  color: #fff;
  border-radius: 8px;
  width: 50%;
  font-family: 'JosefinSlab';
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProductDescription = styled.p`
  margin-top: 15px;
  line-height: 25px;
`
const ProductDetails = styled.h4`
  font-size: 26.25px;
  margin: 26.25px 0px;
  line-height: 1.25;
`
const ProductDetailsItem = styled.p``
const ProductImage = styled(InnerImageZoom)`
  width: 100%;
  height: 500px;
  object-fit: cover;
`
const RelatedProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`
const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
`

export default ProductDetail
