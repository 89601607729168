import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import { Modal, TextInput, useMantineTheme } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import { useForm } from '@mantine/form'
import Button from '../../../components/common/Button'
import { Alert } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import axios from 'axios'

const IncomingStock = ({ designation, onCallback }) => {
  const [error, setError] = useState(null)
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      designation: '',
      invoice: '',
      value: '',
    },
  })

  useEffect(() => {
    form.setFieldValue('designation', designation)
  }, [designation])

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'incomingStock'
      ? state.modal.openModal.value
      : false,
  )

  const handleSubmit = async (value) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}logistics/inventory/incoming`,
        value,
      )
      form.reset()
      dispatch(closeModal({ type: 'incomingStock' }))
      onCallback()
    } catch (err) {
      setError(err.response.data)
    }
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="md"
      radius={'xs'}
      className="auth-register"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          setError(null)
          dispatch(closeModal({ type: 'incomingStock' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>rendre un article</ModalTitle>
        {error && (
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {error}
          </Alert>
        )}
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="username"
            variant="default"
            placeholder="name"
            required
            disabled
            {...form.getInputProps('designation')}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="username"
            variant="default"
            placeholder="Numero de la facture"
            required
            {...form.getInputProps('invoice')}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="username"
            variant="default"
            placeholder="Quantité"
            type="number"
            required
            {...form.getInputProps('value')}
          />
          <Button noHover style={{ marginTop: 15 }}>
            Envoyer
          </Button>
        </form>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`
export default IncomingStock
