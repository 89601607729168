import React from 'react'
import ContentLoader from 'react-content-loader'

const ContentPlaceholder = () => (
  <ContentLoader
    viewBox="0 0 400 400"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
    style={{ width: '600px'}}
  >
    <rect x="25" y="25" rx="5" ry="5" width="350" height="100" />
    <rect x="50" y="150" rx="3" ry="3" width="100" height="10" />
    <rect x="50" y="170" rx="3" ry="3" width="200" height="10" />
  </ContentLoader>
)

export default ContentPlaceholder
