import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addProductToWishlist,
  removeProductToWishlist,
} from '../store/actions/wishlist.action'
import { IconHeart } from '@tabler/icons-react'
import styled from 'styled-components'

const WishlistButton = ({ slug }) => {
  const dispatch = useDispatch()
  const wishlist = useSelector((state) => state.wishlist.wishlist)

  return (
    <div>
      {wishlist.indexOf(slug) >= 0 ? (
        <Favourite
          onClick={() => {
            dispatch(removeProductToWishlist(slug))
          }}
        >
          <IconHeart fill={'#000'} />
        </Favourite>
      ) : (
        <Favourite
          onClick={() => {
            dispatch(addProductToWishlist(slug))
          }}
        >
          <IconHeart fill={'#fff'} />
        </Favourite>
      )}
    </div>
  )
}

const Favourite = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`
export default WishlistButton
