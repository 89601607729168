import { OPEN_MODAL, CLOSE_MODAL } from '../types'

const initialState = {
  openModal: { type: '', value: false },
  extra: {},
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModal: { type: action.payload.type, value: true },
        extra: action.payload.extra,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: { type: action.payload.type, value: false },
        extra: {},
      }
    default:
      return state
  }
}

export default modalReducer
