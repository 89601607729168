import React, { useEffect } from 'react'
import { Card, Pagination, Table, Text } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import EmployeeItem from '../../../../components/HRManagement/Employee/EmployeeItem'
import NewEmployee from '../../../../components/HRManagement/Employee/NewEmployee'
import { fetchEmployee } from '../../../../store/actions/employee.action'
import Filter from '../../../../components/HRManagement/Filter'
import Management from '../../Containers/Management'

const ListEmployee = () => {
  const dispatch = useDispatch()
  const employees = useSelector((state) => state.employees.employees)

  useEffect(() => {
    dispatch(fetchEmployee())
  }, [])

  return (
    <div>
      <Management>
        <NewEmployee />
        <Filter />
        <Card padding="lg" radius="md" mt={10} className="hr-management">
          <Card.Section
            style={{
              background: 'rgb(34, 139, 230)',
              color: '#fff',
              padding: 8,
              fontWeight: 600,
            }}
          >
            <Text align="center">Liste des employés</Text>
          </Card.Section>
          <Card.Section>
            <Table withBorder withColumnBorders>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Numero</th>
                  <th>Service</th>
                  <th>Téléphone</th>
                  <th>Email</th>
                  <th>Adresse</th>
                  <th>CIN</th>
                  <th>Status</th>
                  <th>Situation familiale</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {employees &&
                  employees.length > 0 &&
                  employees.map((employee, index) => (
                    <EmployeeItem key={index} employee={employee} />
                  ))}
              </tbody>
            </Table>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination
                total={10}
                color="dark"
                size="sm"
                mt={15}
                mb={15}
                mr={15}
              />
            </div>
          </Card.Section>
        </Card>
      </Management>
    </div>
  )
}

export default ListEmployee
