import {
  ADD_PRODUCT_TO_CART,
  FETCH_PRODUCTS_CART,
  DELETE_PRODUCT_CART,
  UPDATE_PRODUCT_CART,
  CLEAR_CART,
  REQUEST_PRODUCT_CART,
  DELETE_CART,
  FETCH_PURCHASED_PRODUCTS,
} from '../types'

const initialState = {
  cart: [],
  isLoading: false,
  purchasedProducts: [],
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
    case FETCH_PRODUCTS_CART:
    case DELETE_PRODUCT_CART:
    case UPDATE_PRODUCT_CART:
      if (!localStorage.getItem('cart') && action.payload.carts) {
        localStorage.setItem('cart', action.payload.carts[0]._id)
      }
      if (action.payload.carts?.length === 0) {
        localStorage.removeItem('cart')
      }
      return {
        ...state,
        cart:
          action.payload.carts?.length > 0
            ? action.payload.carts[0]
            : action.payload.carts,
        isLoading: action.payload.isLoading,
      }
    case FETCH_PURCHASED_PRODUCTS:
      return {
        ...state,
        purchasedProducts: action.payload,
      }
    case REQUEST_PRODUCT_CART:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    case CLEAR_CART: {
      return {
        ...state,
        uniqueKey: null,
      }
    }
    case DELETE_CART:
      return {
        ...state,
        cart: [],
      }
    default: {
      return state
    }
  }
}

export default cartReducer
