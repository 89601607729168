import React, { useEffect, useState } from 'react'
import Layout from './Containers/Layout'
import { Table, Text, Card, Button } from '@mantine/core'
import axios from 'axios'
import { IconSend } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

const Newsletters = () => {
  const navigate = useNavigate()
  const [newsletters, setNewsletters] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}newsletters`,
      )
      setNewsletters(data)
    }
    fetch()
  }, [])

  return (
    <div>
      <Layout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text fw={500} fz={25} mb={10} style={{ fontFamily: 'JosefinSlab' }}>
            Liste des abonnés
          </Text>
          <Button onClick={() => navigate('/admin/newsletters/send')}>
            <IconSend size={20} style={{ marginRight: 5 }} /> Envoyer un message
          </Button>
        </div>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Table style={{ fontFamily: 'JosefinSlab' }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Adresse email</th>
              </tr>
            </thead>
            <tbody>
              {newsletters &&
                newsletters?.length > 0 &&
                newsletters.map((newsletter, index) => (
                  <tr>
                    <td>{newsletter._id}</td>
                    <td>{newsletter.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
      </Layout>
    </div>
  )
}

export default Newsletters
