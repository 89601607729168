import * as React from 'react'
const IconMessage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M181.5 31C135.9 35.7 94.9 54.7 65 85c-21.8 22.1-37.4 51.3-42.7 80.3-2.3 12.6-2.4 39.9-.1 52.3 5.2 27.4 19.2 54.8 39.6 77.3 4.1 4.5 7.2 8.8 7.2 10 0 5.1-6.5 15.6-14.5 23.6-7.5 7.5-10.4 9.4-22.7 15-10.9 4.9-14.6 16.5-8.4 25.8 1.2 1.8 4.7 4.5 8.1 6.2l5.9 3h18c15.2 0 19.7-.4 28.4-2.3 19.7-4.4 46.8-14.7 67.7-25.6 8.7-4.6 10.4-5.1 13.5-4.5 1.9.5 10.4 1.3 18.9 1.9 46.2 3.2 89.9-8.1 127.1-32.8 15.2-10.1 33.9-28.5 43.3-42.7 11-16.7 18.2-33.5 22.3-52.6 3-13.2 2.7-44.8-.4-58.8-4.3-19.2-11.2-35.6-21.7-51.6-7-10.7-10-14.4-20.8-25.3-27.5-27.9-64.4-46-106.4-52.2-10.5-1.5-35.4-2-45.8-1zm43.2 22.5c60.3 8.6 109.9 47.3 126.8 98.9 4.4 13.7 5.9 23.9 5.9 39.6 0 25.5-6.3 45.9-21.1 68.4-6.8 10.2-26.8 29.8-38.3 37.5-22.8 15.1-50 24.8-78.5 28.1-18 2-43.8.9-56.9-2.5-5.1-1.3-5.1-1.3-10.8 2.1-16.3 9.9-47 22.5-68.3 27.9-12 3.1-31.5 5.7-31.5 4.2 0-.4 2.6-2.3 5.8-4.3 18.2-11.6 28.8-26.8 33.3-47.8l2.2-9.9-8.1-7.6c-13.6-12.9-22.6-24.9-30.6-40.9-14.2-28.2-16.7-62.4-6.9-93.6C60.1 113.8 95.1 79 139 62.7c11.2-4.1 23.1-7.2 35.5-9.1 12-1.9 37.5-1.9 50.2-.1z" />
    <path d="M112 133v11h175v-22H112v11zM112 184.5V195h175v-21H112v10.5zM112 235.5V246h124v-21H112v10.5zM396.7 217.6c-.4.4-.7 1.7-.7 2.9s-.9 5.5-1.9 9.5c-1.1 4.1-1.9 7.5-1.8 7.6.1 0 5 2.4 10.8 5.1 15.1 7.2 27 15.4 38 26.3 19.5 19.4 28.8 40.1 28.9 63.8 0 23.4-8.9 44.1-27.4 63-4.4 4.6-10.9 10.5-14.4 13-6.1 4.5-6.3 4.8-5.7 8.1 2.8 15.1 12.7 27.6 30.5 38.6l7.4 4.5h-11c-30.8 0-61.6-11.7-76.7-29.2l-4.5-5.2-6.7 1.3c-27.5 5-65.7 2.5-95.1-6.5-29.3-8.8-53.6-25-67.6-44.9l-4.9-7-12-1c-9.9-.8-11.9-.8-11.9.4 0 2.9 9.7 19.5 15.8 27.2 29.6 36.9 87 58 151.7 55.5 9.4-.3 18.2-.9 19.7-1.2 2.1-.5 3.5.2 7 3.4 21.4 19.6 64 32 97.1 28.3 16.4-1.9 26.3-6.6 29.4-13.9 3.2-7.8-.3-15.3-9.8-20.8-3-1.7-9.2-5.3-13.8-8-4.7-2.7-11.1-7.4-14.3-10.5l-5.8-5.7 8.1-7.8c41.8-40.6 48.4-96.9 16.7-143.1-12.4-18.2-35.7-37.3-58.9-48.2-11.4-5.4-15.1-6.6-16.2-5.5z" />
    <path d="M362 294.1c-3.6 4.5-7.6 9.4-8.9 11l-2.4 2.9H400v-22h-31.5l-6.5 8.1zM299.5 344.4c-7.7 3.9-17.8 8.4-22.5 10-4.7 1.6-8.9 3.3-9.4 3.7-.6.5 28.7.9 65.7.9H400v-22l-43.2.1-43.3.1-14 7.2z" />
  </svg>
)
export default IconMessage
