import * as React from 'react'
const IconProduct = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="m248.8 22.9-3.3 2.9-.5 71.1-.5 71.1-22.5-22.5c-24.4-24.4-25.6-25.2-32.3-22-3.9 1.8-5.7 5-5.7 10.3 0 3.5 1.6 5.3 33.8 37.4l33.7 33.8h9l33.7-33.8c32.2-32.1 33.8-33.9 33.8-37.4 0-5.3-1.8-8.5-5.7-10.3-6.7-3.2-7.9-2.4-32.3 22L267.5 168l-.5-71.1-.5-71.1-3.3-2.9c-2.4-2.2-4.2-2.9-7.2-2.9s-4.8.7-7.2 2.9z" />
    <path d="M97.5 159.1c-33.8 12.7-42.5 16.4-44.4 18.6-1.3 1.5-13.8 21.2-27.7 43.7C2.6 258.1 0 262.7 0 266.5c0 2.6.7 5.1 1.8 6.5 1.1 1.4 9.7 5.1 25.2 10.9l23.5 8.8.5 61.1c.5 59.5.6 61.2 2.5 63.2 1.2 1.1 42.6 16.9 101.4 38.6 75.8 27.9 100.2 36.5 102.5 36.1 6.2-1 199-72.7 201.1-74.7 2-2 2-3.3 2.5-63.2l.5-61.1 18.5-6.9c10.2-3.7 20.5-7.5 23-8.5 6.4-2.4 9-5.3 9-10.3 0-2.3-.7-5.4-1.5-6.9-2.8-5.5-49.3-79.6-51.6-82.4-2-2.2-10.5-5.8-44.9-18.7-23.4-8.8-43.8-16-45.3-16-6.4 0-10.7 4.6-10.7 11.5 0 6.3 2.3 7.6 32 18.6 15.1 5.6 27.8 10.5 28.2 10.8 1 .8-160.8 60.3-163.1 59.9-1-.2-37.8-13.6-81.9-29.8-48.9-18-79.5-29.8-78.7-30.3.8-.4 13.4-5.2 28.2-10.7 30-11.2 31.3-12 31.3-19.6 0-3.7-.6-5.1-2.9-7.5-5-4.9-7.1-4.4-53.6 13.2zm54 69.9c45.1 16.6 83.4 30.7 85.1 31.4l3.1 1.1-19.4 31c-10.6 17-19.9 31.1-20.6 31.3-1.1.2-171.3-62-172.3-63.1-.3-.3 8.2-14.5 18.8-31.6 16.1-25.9 19.6-31.1 21.3-30.8 1.1.2 38.9 14 84 30.7zm314.3.1c10.6 17.1 19.1 31.3 18.8 31.6-1.1 1.1-171.1 63.3-172.3 63.1-.7-.2-10-14.2-20.6-31.3l-19.4-31 85.6-31.6c47.1-17.4 86.3-31.7 87.1-31.7.9-.1 9 11.9 20.8 30.9zM244.5 464.8c-.4.4-150.8-54.5-168.8-61.5l-3.7-1.5v-50.5c0-47.6.1-50.5 1.8-50.1.9.3 29.8 10.9 64.2 23.6 34.4 12.7 63.7 23.3 65.2 23.7 6.2 1.6 8.3-.8 25.4-28l15.9-25.5.3 84.7c.1 46.5 0 84.8-.3 85.1zm39.4-143.5c16.5 26.3 18.7 28.7 24.9 27.2 1.5-.3 31.3-11.2 66.3-24.1 35-12.9 63.9-23.4 64.3-23.4.3 0 .6 22.7.6 50.4 0 47.1-.1 50.4-1.7 51.1-8.2 3.2-169.7 62.5-170.4 62.5-.5 0-.9-33.4-.9-85.3 0-46.8.4-84.7.8-84.1.4.6 7.6 12.2 16.1 25.7z" />
  </svg>
)
export default IconProduct
