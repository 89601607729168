export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_QTY = 'UPDATE_QTY'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT'
export const OPEN_MODAL_PRODUCT = 'OPEN_MODAL_PRODUCT'
export const CLOSE_MODAL_PRODUCT = 'CLOSE_MODAL_PRODUCT'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const FETCH_PRODUCTS_CART = 'FETCH_PRODUCTS_CART'
export const DELETE_PRODUCT_CART = 'DELETE_PRODUCT_CART'
export const UPDATE_PRODUCT_CART = 'UPDATE_PRODUCT_CART'
export const REQUEST_PRODUCT_CART = 'REQUEST_PRODUCT_CART'
export const ADD_PRODUCT_TO_WISHLIST = 'ADD_PRODUCT_TO_WISHLIST'
export const REMOVE_PRODUCT_TO_WISHLIST = 'REMOVE_PRODUCT_TO_WISHLIST'
export const FETCH_PRODUCT_TO_WISHLIST = 'FETCH_PRODUCT_TO_WISHLIST'
export const CLEAR_CART = 'CLEAR_CART'
export const DELETE_CART = 'DELETE_CART'
export const FETCH_PURCHASED_PRODUCTS = 'FETCH_PURCHASED_PRODUCTS'
export const FETCH_ACTIVE_FILTERS = 'FETCH_ACTIVE_FILTERS'
export const SET_FILTER = 'SET_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const REMOVE_ALL_FILTER = 'REMOVE_ALL_FILTER'
export const SORT_BY = 'SORT_BY'

//types for article
export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const CREATE_NEW_ARTICLE = 'CREATE_NEW_ARTICLE'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'

//types for employee
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE'
export const CREATE_NEW_EMPLOYEE = 'CREATE_NEW_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'

//types for leave
export const FETCH_LEAVES = 'FETCH_LEAVES'
export const CREATE_NEW_LEAVE = 'CREATE_NEW_LEAVE'
export const DELETE_LEAVE = 'DELETE_LEAVE'

//types for employee
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

//types for attendance
export const FETCH_ATTENDANCE = 'FETCH_ATTENDANCE'
export const CREATE_NEW_ATTENDANCE = 'CREATE_NEW_ATTENDANCE'

//types for salaries
export const FETCH_SALARIES = 'FETCH_SALARIES'
export const CREATE_NEW_SALARY = 'CREATE_NEW_SALARY'
export const DELETE_SALARY = 'DELETE_SALARY'
