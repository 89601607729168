import React from 'react'
import { Layout } from '../components/Layout'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Service from './partials/_service'
import Testimonials from './partials/_testimonials'
import Partner from './partials/_partner'
import Shop from './partials/_shop'
import Gallery from './partials/_gallery'
import Chat from './chat/Chat'

export const Home = () => {
  return (
    <Layout>
      <Service />
      <Testimonials />
      <Partner />
      <Shop />
      <Gallery />
    </Layout>
  )
}
