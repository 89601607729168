import axios from 'axios'
import {
  ADD_PRODUCT_TO_CART,
  DELETE_CART,
  DELETE_PRODUCT_CART,
  FETCH_PRODUCTS_CART,
  FETCH_PURCHASED_PRODUCTS,
  REQUEST_PRODUCT_CART,
  UPDATE_PRODUCT_CART,
} from '../types'

const API = process.env.REACT_APP_BASE_URL

export const addProductToCart = ({
  productId,
  cart,
  quantity,
  productType,
  productCustomDescription,
}) => {
  return async (dispatch, getState) => {
    const { data } = await axios.post(
      `${API}cart/add`,
      {
        productId,
        quantity,
        productType,
        productCustomDescription,
      },
      {
        headers: {
          cart,
        },
      },
    )
    dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: data,
    })
  }
}

export const fetchProductsInCart = (cart) => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`${API}cart/${cart}`, {
      headers: {
        cart,
      },
    })
    dispatch({
      type: FETCH_PRODUCTS_CART,
      payload: { ...data, isLoading: false },
    })
  }
}

export const deleteProductInCart = (id, cart) => {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_PRODUCT_CART,
      payload: { isLoading: true },
    })
    const { data } = await axios.delete(`${API}cart/remove/${id}`, {
      headers: {
        cart,
      },
    })
    dispatch({
      type: DELETE_PRODUCT_CART,
      payload: { ...data, isLoading: false },
    })
  }
}

export const deleteCart = (cart) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CART,
      payload: cart,
    })
    await axios.post(`${API}cart/delete`, null, {
      headers: {
        cart,
      },
    })
  }
}

export const updateProductInCart = ({ productId, cart, quantity }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_PRODUCT_CART,
      payload: { isLoading: true },
    })
    const { data } = await axios.put(
      `${API}cart/update/${productId}`,
      {
        productId,
        quantity,
      },
      {
        headers: {
          cart,
        },
      },
    )
    dispatch({
      type: UPDATE_PRODUCT_CART,
      payload: { ...data, isLoading: false },
    })
  }
}

export const fetchPurchasedProducts = () => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`${API}orders/purchased?paid=1`)
    dispatch({
      type: FETCH_PURCHASED_PRODUCTS,
      payload: Object.values(data).split(-1).pop(),
    })
  }
}
