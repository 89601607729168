import { CREATE_NEW_LEAVE, DELETE_LEAVE, FETCH_LEAVES } from '../types'

const initialState = {
  leaves: [],
}

const leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEAVES: {
      return {
        ...state,
        leaves: action.payload?.leaves,
      }
    }
    case CREATE_NEW_LEAVE: {
      const { leaves, isUpdated } = action.payload
      return {
        ...state,
        leaves: !isUpdated
          ? [...state.leaves, leaves]
          : [
              ...state.leaves.filter((leave) => leave._id !== leaves._id),
              leaves,
            ],
      }
    }
    case DELETE_LEAVE: {
      const { id } = action.payload
      return {
        ...state,
        leaves: [...state.leaves.filter((leave) => leave._id !== id)],
      }
    }
    default: {
      return state
    }
  }
}

export default leaveReducer
