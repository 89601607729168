import React, { useEffect, useRef, useState } from "react";
import { Table, Text, Card, Button, TextInput, Textarea } from "@mantine/core";
import styled from "styled-components";
import { useSocketContext } from "../../context/SocketContext";
import { useAuthContext } from "../../context/AuthContext";
import Layout from "../admin/Containers/Layout";
import { useGetQuery } from "../../hooks/useGetQuery";
import Message from "./Message";
import { IconSend } from "@tabler/icons-react";
import Conversation from "./Conversation";
import { io } from "socket.io-client";
import { SOCKET_DOMAIN } from "../../constant";
import ChatBar from "./ChatBar";
import ChatFooter from "./ChatFooter";
import ChatBody from "./ChatBody";

const Chat = ({ socket }) => {
  const { user } = useAuthContext();
  const [currentChat, setCurrentChat] = useState(null);
  const [message, setMessage] = useState([]);

  const getMessage = (message) => {
    setMessage(message);
  };

  return (
    <Layout>
      <ChatWrapper>
        <div id="frame">
          <ChatBar
            socket={socket}
            userId={user?._id}
            currentConversation={(conversation) => setCurrentChat(conversation)}
          />
          <div class="content">
            <div class="contact-profile">
              <img
                src="http://emilcarlsson.se/assets/harveyspecter.png"
                alt=""
              />
              <p>Harvey Specter</p>
              <div class="social-media">
                <i class="fa fa-facebook" aria-hidden="true"></i>
                <i class="fa fa-twitter" aria-hidden="true"></i>
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </div>
            </div>
            <div class="messages">
              <ChatBody
                socket={socket}
                conversation={currentChat}
                newMessage={message}
                userId={user?._id}
              />
            </div>
            <ChatFooter
              socket={socket}
              userId={user?._id}
              conversation={currentChat}
              newMessage={getMessage}
            />
          </div>
        </div>
      </ChatWrapper>
    </Layout>
  );
};

const ChatWrapper = styled.div`
  z-index: 9999;
`;
export default Chat;
