import React from "react";

const Message = ({ message, own }) => {
  return (
    <li class={own ? "replies" : "sent"}>
      <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
      <p>{message.text}</p>
    </li>
  );
};

export default Message;
