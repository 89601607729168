import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { down } from 'styled-breakpoints'
import { Row, Col } from 'react-grid-system'
import ProductItem from '../../components/ProductItem'
import { fetchProducts } from '../../store/actions/products.action'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useMediaQuery } from '@mantine/hooks'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CarouselMobile = ({ children, responsive, ...rest }) => {
  const smallScreen = useMediaQuery('(max-width: 800px)')
  return !smallScreen ? (
    <Row {...rest}>{children}</Row>
  ) : (
    <Carousel responsive={responsive}>{children}</Carousel>
  )
}
const Shop = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchProducts())
  }, [])

  const products = useSelector((state) => state.products.products)

  return (
    <Container>
      <Wrapper>
        <Title>{t('shop.title')}</Title>
        <Caption>{t('shop.description')}</Caption>
      </Wrapper>
      <CarouselMobile
        responsive={responsive}
        style={{ maxWidth: 1300, margin: '0 auto' }}
      >
        {products.data?.map((product, index) => (
          <ProductItem key={index} {...product} />
        ))}
      </CarouselMobile>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 70px !important;
  padding-bottom: 70px !important;
`
const Title = styled.h2`
  font-size: 36px;
  font-family: 'JosefinSlab';
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
  ${down('md')} {
    width: 300px;
  }
`
const Caption = styled.p`
  font-family: 'JosefinSlab';
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 25px;
  ${down('md')} {
    padding: 0px 10px;
  }
`
const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default Shop
