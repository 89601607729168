import * as React from 'react'
const IconBasket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 268 336"
    style={{ fill: '#676767' }}
    {...props}
  >
    <path d="M116.6 1.6C86.7 8.8 64 38.8 64 70.9V79h9.8l.7-6.8c1.6-16.7 7.5-29.8 19-42 11.9-12.6 25.7-19.3 40.2-19.4 14.5 0 26.7 5.5 39.4 18.2 6.9 6.8 9.4 10.2 12.7 17 4.7 9.6 7.2 18.3 7.2 25.1 0 7.2.5 7.9 6.1 7.9h4.9v-8.3c0-20.7-10.9-43.7-26.8-56.2-7.1-5.7-17.8-10.9-26.2-12.9-8.2-2-26.4-1.9-34.4 0zM10.1 99.9c-1.1 1.1-2.2 3.9-2.5 6.3C7 111.4-.1 312 0 323.7 0 337.6-17 336 133.8 336h129.9l2.1-2.3c2.9-3.1 2.9-2.2-1.7-127.5L260.3 103l-2.3-2.5-2.3-2.5H133.8C14.9 98 12 98 10.1 99.9zm243.1 107.6c2 54.5 3.7 103.4 3.7 108.7l.1 9.8H10.5l3.7-99.3c2-54.5 3.7-103.6 3.7-109l.1-9.7 115.8.2 115.7.3 3.7 99z" />
    <path d="M80.8 181.7c-6.5 7.5-6.5 21.1 0 28.5 2 2.4 8.4 2.4 10.4 0 6.5-7.4 6.5-21 0-28.5-.9-1-2.9-1.7-5.2-1.7-2.3 0-4.3.7-5.2 1.7zM177.7 181.1c-5.2 3-7.6 17.3-4.1 24.2 2.7 5.3 4.4 6.7 8.4 6.7 6.1 0 10-6.3 10-16 0-11.9-7-19.2-14.3-14.9zM91.7 243.8c-2.2 2.4-2.1 6.5.1 8.5 1.7 1.5 6.1 1.7 43.4 1.7 45.6 0 42.8.4 42.8-6s2.9-6-43-6c-39.1 0-41.8.1-43.3 1.8z" />
  </svg>
)
export default IconBasket
