import React, { useState, useEffect } from 'react'
import Layout from '../Containers/Layout'
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Table,
  ActionIcon,
  Menu,
  LoadingOverlay,
} from '@mantine/core'
import axios from 'axios'
import { useDebouncedValue } from '@mantine/hooks'
import { DataTable } from 'mantine-datatable'
import _ from 'lodash'
import {
  IconDots,
  IconDownload,
  IconEdit,
  IconFile,
  IconFileExport,
  IconFileInvoice,
  IconPdf,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { priceFormatter } from '../../../utils/priceFormatter'

const PAGE_SIZES = [10, 15, 20]

const Logistics = () => {
  const [currentPage, setPage] = useState(1)
  const [logistics, setLogistics] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRecords, setSelectedRecords] = useState([])
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebouncedValue(query, 200)
  const navigate = useNavigate()
  const from = (currentPage - 1) * pageSize
  const to = from + pageSize

  useEffect(() => {
    setPage(1)
  }, [pageSize])

  useEffect(() => {
    fetchLogistics()
  }, [currentPage, pageSize])

  const [records, setRecords] = useState(logistics.slice(0, pageSize))

  useEffect(() => {
    setRecords(
      logistics.slice(from, to).filter(({ name }) => {
        if (
          debouncedQuery !== '' &&
          !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false
        }
        return true
      }),
    )
  }, [debouncedQuery])

  const fetchLogistics = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}logistics?limit=2000`,
    )
    setLogistics(data.data)
    setTotalPages(data.totalPages)
    setRecords(data.data.slice(from, to))
  }

  const onDownloadAsPDF = async (record, orderLogistics, isInvoice = false) => {
    const payload = { ...record, orderLogistics }
    if (isInvoice) {
      payload['type'] = 'invoice'
      const advance = prompt('Avance')
      const remise = prompt('Remise')
      payload['advance'] = advance || 0
      payload['remise'] = remise || 0
      payload['logo'] = 'https://santika.mg/images/santika.png'
    }
    setLoading(true)
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}logistics/export/pdf`,
      payload,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    )
    var blob = new Blob([data], { type: 'application/pdf' })
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    var fileName = 'order-logistics.pdf'
    link.download = fileName
    link.click()
    setLoading(false)
  }

  const onDownloadAsExcel = async (orderLogistics) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}logistics/export/excel`,
      orderLogistics,
    )
    var location = 'data:application/vnd.ms-excel;base64,'
    window.location.href = location + window.btoa(data)
  }

  const onDelete = async (id) => {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}logistics/${id}`)
    fetchLogistics()
  }

  return (
    <Layout>
      <Card padding="lg" radius="md" withBorder className="logistics">
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={1.5}
          transitionDuration={500}
          loaderProps={{ variant: 'bars', color: 'dark' }}
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Button
            color="dark"
            mb={15}
            onClick={() => navigate('/admin/logistics/new')}
          >
            <IconPlus size={20} style={{ marginRight: 5 }} />
            Créer une commande logistic
          </Button>
        </div>
        <DataTable
          records={records}
          columns={[
            { accessor: 'numero' },
            { accessor: 'name' },
            { accessor: 'email' },
            { accessor: 'phone' },
            { accessor: 'fb' },
            { accessor: 'person' },
            { accessor: 'location' },
            { accessor: 'date' },
            { accessor: 'menu' },
            { accessor: 'observation' },
            {
              accessor: '',
              title: 'Action',
              render: (logistic) => (
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button
                      variant="unstyled"
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <IconDots />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>Application</Menu.Label>
                    <Menu.Item icon={<IconEdit size={14} />} onClick={() => navigate(`/admin/logistics/new?ref=${logistic.numero}`)}>
                      Modifier
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      color="red"
                      icon={<IconTrash size={14} />}
                      onClick={() => onDelete(logistic._id)}
                    >
                      Supprimer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
          totalRecords={logistics.length}
          paginationColor="dark"
          recordsPerPage={pageSize}
          page={currentPage}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
          rowExpansion={{
            collapseProps: {
              transitionDuration: 300,
              animateOpacity: true,
              transitionTimingFunction: 'ease-out',
              allowMultiple: false,
            },
            content: ({ record }) => {
              const orderLogistics = _.groupBy(record.orderLogistics, 'service')
              return (
                <Card
                  padding="lg"
                  radius="md"
                  withBorder
                  className="logistics"
                  mt={10}
                  mb={15}
                >
                  {Object.values(orderLogistics).map((orderLogistic, key) => (
                    <Grid>
                      <Grid.Col md={1}>
                        <div
                          style={{
                            background: '#25262b',
                            color: '#fff',
                            padding: '25px 15px',
                            width: 50,
                            textOrientation: 'mixed',
                            writingMode: 'vertical-lr',
                            borderRadius: 2,
                          }}
                        >
                          <strong
                            style={{
                              textTransform: 'uppercase',
                              letterSpacing: 1,
                            }}
                          >
                            <div>{Object.keys(orderLogistics)[key]}</div>
                          </strong>
                        </div>
                      </Grid.Col>
                      <Grid.Col md={11}>
                        <Table style={{ marginBottom: 25, marginTop: 20 }}>
                          <thead>
                            <tr>
                              <th style={{ color: '#000', width: 250 }}>
                                Designation
                              </th>
                              <th style={{ color: '#000', width: 150 }}>
                                Prix
                              </th>
                              <th style={{ color: '#000', width: 200 }}>
                                Date de prise
                              </th>
                              <th style={{ color: '#000', width: 200 }}>
                                Date de retour
                              </th>
                              <th style={{ color: '#000', width: 200 }}>
                                Nombre de location
                              </th>
                              <th style={{ color: '#000', width: 150 }}>
                                Quantité
                              </th>
                              <th style={{ color: '#000', width: 150 }}>
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderLogistic.map((logistic, index) => (
                              <tr key={index}>
                                <td>{logistic.designation}</td>
                                <td>{priceFormatter(logistic.rentalPrice)}</td>
                                <td>
                                  {logistic.startDate ? moment(logistic.startDate).format(
                                    'DD/MM/YYYY',
                                  ) : '-'}
                                </td>
                                <td>
                                  {' '}
                                  {logistic.endDate ? moment(logistic.endDate).format(
                                    'DD/MM/YYYY',
                                  ) : '-'}
                                </td>
                                <td>{logistic.nbRental}</td>
                                <td>{logistic.quantity}</td>
                                <td>{priceFormatter(logistic.amount)}Ar</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Grid.Col>
                    </Grid>
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          leftIcon={<IconDownload />}
                        >
                          Télécharger
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>Application</Menu.Label>
                        <Menu.Item
                          icon={<IconPdf size={14} />}
                          onClick={() =>
                            onDownloadAsPDF(record, orderLogistics)
                          }
                        >
                          Format PDF
                        </Menu.Item>
                        <Menu.Item
                          icon={<IconFile size={14} />}
                          onClick={() => onDownloadAsExcel(orderLogistics)}
                        >
                          Format Excel
                        </Menu.Item>
                        <Menu.Item
                          icon={<IconFileInvoice size={14} />}
                          onClick={() =>
                            onDownloadAsPDF(record, orderLogistics, true)
                          }
                        >
                          Facture
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </Card>
              )
            },
          }}
        />
      </Card>
    </Layout>
  )
}

export default Logistics
