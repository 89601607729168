import React, { useEffect, useState } from "react";
import { useGetQuery } from "../../hooks/useGetQuery";
import axios from "axios";

const ChatBar = ({ socket, userId, ...props }) => {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const API = process.env.REACT_APP_BASE_URL;
    const fetchConversations = async () => {
      try {
        const { data: listConversations } = await axios.get(
          `${API}messenger/user/conversation/${userId}`
        );
        const c = [];
        if (listConversations) {
          listConversations.map(async (conversation) => {
            const { sender, receiver, _id } = conversation;
            const friendId = [sender, receiver].find((m) => m !== userId);
            const { data } = await axios.get(
              `${API}messenger/user/${friendId}`
            );
            c.push({
              _id,
              username: data.username,
              ...conversation,
            });
            setConversations(c);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (userId) {
      fetchConversations();
    }
  }, [userId]);

  return (
    <div id="sidepanel">
      <div id="profile">
        <div class="wrap">
          <img
            id="profile-img"
            src="http://emilcarlsson.se/assets/mikeross.png"
            class="online"
            alt=""
          />
          <p>Mike Ross</p>
          <i class="fa fa-chevron-down expand-button" aria-hidden="true"></i>
        </div>
      </div>
      <div id="search">
        <label for="">
          <i class="fa fa-search" aria-hidden="true"></i>
        </label>
        <input type="text" placeholder="Search contacts..." />
      </div>
      <div id="contacts">
        <ul>
          {conversations &&
            conversations.map((conversation, index) => (
              <li
                class="contact"
                onClick={() => props.currentConversation(conversation)}
              >
                <div class="wrap">
                  <span class="contact-status online"></span>
                  <img
                    src="http://emilcarlsson.se/assets/louislitt.png"
                    alt=""
                  />
                  <div class="meta">
                    <p class="name">{conversation?.username}</p>
                    <p class="preview">{conversation?.sender}</p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ChatBar;
