import React, { useEffect, useState } from 'react'
import {
  useMantineTheme,
  Modal,
  TextInput,
  SimpleGrid,
  FileInput,
} from '@mantine/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IconUpload, IconX } from '@tabler/icons-react'
import Button from '../../../components/common/Button'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { closeModal } from '../../../store/actions/modal.action'
import { fetchArticles } from '../../../store/actions/article.action'

const CreateOrEdit = () => {
  const [open, setOpen] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'article'
      ? state.modal.openModal.value
      : false,
  )
  const article = useSelector((state) => state.modal.extra)

  useEffect(() => {
    form.setValues(article)
  }, [article])

  const form = useForm({
    initialValues: {
      name: '',
      price: '',
      priceCasse: '',
      priceRepair: '',
      qtyDispo: '',
      qtyLost: '',
      qtyBroken: '',
      file: '',
    },
  })

  const handleSubmit = async (data) => {
    setIsLoading(true)
    const formValues = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key])
      return formData
    }, new FormData())
    formValues.append('id', article?._id)
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}articles/createOrEdit`,
      formValues,
    )
    setIsLoading(false)
    dispatch(closeModal({ type: 'article' }))
    window.location.reload()
    form.reset()
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="md"
      radius={'xs'}
      className="auth-register"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          dispatch(closeModal({ type: 'article' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>{!article ? 'Ajouter' : 'Modifier'}</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        ></div>
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <SimpleGrid cols={2}>
            <div>
              <TextInput
                withAsterisk
                mt={10}
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="name"
                required
                {...form.getInputProps('name')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                mt={10}
                placeholder="prix"
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                required
                type="number"
                {...form.getInputProps('price')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="prix de remplacement"
                required
                type="number"
                {...form.getInputProps('priceCasse')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="prix de réparation"
                required
                type="number"
                {...form.getInputProps('priceRepair')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="Qty disponible"
                required
                type="number"
                {...form.getInputProps('qtyDispo')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="Qty perdu"
                required
                type="number"
                {...form.getInputProps('qtyLost')}
              />
            </div>
            <div>
              <TextInput
                withAsterisk
                size={'lg'}
                radius={'xs'}
                name="username"
                variant="default"
                placeholder="Qty cassée"
                required
                type="number"
                {...form.getInputProps('qtyBroken')}
              />
            </div>
          </SimpleGrid>
          <div style={{ marginTop: 10 }}>
            <FileInput
              placeholder="Image"
              style={{ border: 'none' }}
              icon={<IconUpload size={15} />}
              {...form.getInputProps('file')}
            />
          </div>
          <Button
            type="submit"
            fullWidth
            noHover
            loading={isLoading}
            loadingPosition={'left'}
            style={{ marginTop: 15, fontSize: 12 }}
          >
            {!article ? 'Ajouter' : 'Modifier'}
          </Button>
        </form>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default CreateOrEdit
