import React, { useState } from 'react'
import {
  useMantineTheme,
  Modal,
  TextInput,
  Text,
  Group,
  Select,
  Checkbox,
  Alert,
} from '@mantine/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  IconDeviceMobile,
  IconLock,
  IconMail,
  IconRefresh,
  IconUser,
  IconUserCircle,
  IconAlertCircle,
  IconX,
} from '@tabler/icons-react'
import Button from '../../components/common/Button'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../store/actions/modal.action'
import { usePostQuery } from '../../hooks/usePostQuery'
import axios from 'axios'

const months = [
  { value: 'janvier', label: 'janvier' },
  { value: 'fevrier', label: 'fevrier' },
  { value: 'mars', label: 'mars' },
  { value: 'avril', label: 'avril' },
  { value: 'mai', label: 'mai' },
  { value: 'juin', label: 'juin' },
  { value: 'juillet', label: 'juillet' },
  { value: 'aout', label: 'aout' },
  { value: 'septembre', label: 'septembre' },
  { value: 'octobre', label: 'octobre' },
  { value: 'novembre', label: 'novembre' },
  { value: 'decembre', label: 'decembre' },
]
const Register = ({ openRegister }) => {
  const [open, setOpen] = useState(openRegister)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const theme = useMantineTheme()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'auth'
      ? state.modal.openModal.value
      : false,
  )

  const form = useForm({
    initialValues: {
      sexe: '',
      username: '',
      lastname: '',
      email: '',
      password: '',
      confirmPwd: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      phone: '',
    },
    validate: {
      password: (value) =>
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(value)
          ? null
          : 'The password is too easy to guess. Include a range of types of characters to increase the score.',
      confirmPwd: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
      sexe: (value) => (value === '' ? 'Sexe is required' : null),
      birthDay: (value) => (value === '' ? 'This field is required' : null),
      birthMonth: (value) => (value === '' ? 'This field is required' : null),
      birthYear: (value) => (value === '' ? 'This field is required' : null),
    },
  })

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true)
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/register`,
        {
          ...data,
          role: 'basic',
        },
      )
      setSuccess(response.message)
      setError('')
    } catch (err) {
      setError(err.response.data.message)
      setSuccess('')
    }
    setIsLoading(false)
    form.reset()
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="sm"
      radius={'xs'}
      className="auth-register"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          dispatch(closeModal({ type: 'auth' }))
          form.reset()
          setError('')
          setSuccess('')
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>Inscription {openRegister}</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        ></div>
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <Select
            placeholder="Sexe"
            data={[
              {
                value: 'femme',
                label: 'Je suis une femme',
              },
              {
                value: 'homme',
                label: 'Je suis un homme',
              },
            ]}
            required
            {...form.getInputProps('sexe')}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="username"
            variant="default"
            placeholder="Nom de famille"
            required
            {...form.getInputProps('username')}
            icon={<IconUser size={18} />}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="lastname"
            variant="default"
            placeholder="Prénom"
            required
            {...form.getInputProps('lastname')}
            icon={<IconUserCircle size={18} />}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            type="email"
            name="name"
            variant="default"
            placeholder="Votre email"
            required
            {...form.getInputProps('email')}
            icon={<IconMail size={18} />}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            type="password"
            {...form.getInputProps('password')}
            placeholder="mon mot de passe"
            variant="default"
            icon={<IconLock size={18} />}
          />
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="name"
            type="password"
            {...form.getInputProps('confirmPwd')}
            placeholder="répétez votre mot de passe"
            variant="default"
            required
            icon={<IconRefresh size={18} />}
          />
          <Text
            fs={'sm'}
            align="center"
            mt={8}
            style={{ fontSize: 13, letterSpacing: 1, fontWeight: 600 }}
          >
            Date de naissance
          </Text>
          <Group grow mt={10}>
            <Select
              placeholder="Jour"
              data={Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
                value: day,
                label: day,
              }))}
              {...form.getInputProps('birthDay')}
            />
            <Select
              placeholder="Mois"
              data={months}
              {...form.getInputProps('birthMonth')}
            />
            <Select
              placeholder="Année"
              data={Array(new Date().getFullYear() - 18 - 1921 + 1)
                .fill()
                .map((_, idx) => ({
                  value: 1921 + idx,
                  label: 1921 + idx,
                }))
                .reverse()}
              {...form.getInputProps('birthYear')}
            />
          </Group>
          <TextInput
            withAsterisk
            mt={10}
            size={'lg'}
            radius={'xs'}
            name="name"
            type="text"
            placeholder="téléphone"
            variant="default"
            required
            {...form.getInputProps('phone')}
            icon={<IconDeviceMobile size={18} />}
          />
          {error && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title={error}
              color="red"
              mt={10}
            />
          )}
          {success && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title={success}
              color="cyan"
              mt={10}
            />
          )}
          <Button
            loading={isLoading}
            fullWidth
            noHover
            style={{ marginTop: 15, fontSize: 12 }}
          >
            je m'inscris !
          </Button>
        </form>
        <Text fz="xs" align="center" mt={10}>
          Les données collectées sont traitées par la société Santikà pour gérer
          la relation client, personnaliser votre navigation et vous envoyer des
          informations promotionnelles.
        </Text>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default Register
