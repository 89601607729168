import React, { useEffect } from 'react'

import { Select, TextInput } from '@mantine/core'
import { Col, Row } from 'react-grid-system'
import { Country } from 'country-state-city'
import { useForm } from '@mantine/form'
import Button from '../../components/common/Button'
import { useAuthContext } from '../../context/AuthContext'

const NewOrder = (props) => {
  const { user } = useAuthContext()
  const form = useForm({
    initialValues: {
      email: user?.email || '',
      country: '',
      name: '',
      lastname: '',
      cin: '',
      passeport: '',
      address: '',
      phoneNumber: '',
      zipCode: '',
      postalCode: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      zipCode: (value) =>
        /^[0-9]+$/.test(value) ? null : 'Zip code incorrect',
      postalCode: (value) =>
        /^[0-9]+$/.test(value) ? null : 'Postal code incorrect',
      phoneNumber: (value) =>
        /^[0-9]+$/.test(value) ? null : 'Phone number incorrect',
    },
  })

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      form.setValues(JSON.parse(localStorage.getItem('userInfo')))
    }
  }, [])

  const handleSubmit = (values) => {
    localStorage.removeItem('userInfo')
    localStorage.setItem('userInfo', JSON.stringify(values))
    props.nextStep(true)
  }

  return (
    <div className="checkout">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Row>
          <Col md={12} style={{ marginTop: 15 }}>
            <label
              htmlFor=""
              style={{
                fontSize: 16,
                fontFamily: 'Futura',
                textTransform: 'uppercase',
                letterSpacing: 2,
              }}
            >
              Contact information
            </label>
            <TextInput
              placeholder="Email"
              withAsterisk
              mt={10}
              size="lg"
              {...form.getInputProps('email')}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col md={12}>
            <label
              htmlFor=""
              style={{
                fontSize: 16,
                fontFamily: 'Futura',
                textTransform: 'uppercase',
                letterSpacing: 2,
              }}
            >
              Adresse de livraison
            </label>
            <Select
              mt={10}
              size="lg"
              searchable
              placeholder="Pays"
              style={{ fontSize: 13 }}
              data={Country.getAllCountries().map((country) => ({
                value: country.isoCode,
                label: country.flag + ' ' + country.name,
              }))}
              {...form.getInputProps('country')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ paddingRight: 0 }}>
            <TextInput
              placeholder="Nom"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('name')}
            />
          </Col>
          <Col md={6}>
            <TextInput
              placeholder="Prénom"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('lastname')}
            />
          </Col>
          <Col md={12}>
            <TextInput
              placeholder="N° carte d'identité nationale"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('cin')}
            />
          </Col>
          <Col md={12}>
            <TextInput
              placeholder="N° passeport"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('passeport')}
            />
          </Col>
          <Col md={12}>
            <TextInput
              placeholder="Adresse physique"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('address')}
            />
          </Col>
          <Col md={12}>
            <TextInput
              placeholder="Numéro téléphone"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('phoneNumber')}
            />
          </Col>
          <Col md={6} style={{ paddingRight: 0 }}>
            <TextInput
              placeholder="Zip code"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('zipCode')}
            />
          </Col>
          <Col md={6}>
            <TextInput
              placeholder="Code postale"
              withAsterisk
              required
              mt={10}
              size="lg"
              {...form.getInputProps('postalCode')}
            />
          </Col>
          <Col md={12} style={{ marginTop: 15 }}>
            <Button type="submit">continuez vos achats</Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default NewOrder
