import { SimpleGrid, Table } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../../store/actions/modal.action'
import NewAttendance from './NewAttendance'
import { fetchEmployee } from '../../../store/actions/employee.action'

const labels = [
  {
    title: 'Journée complète',
    bg: '#e74c3c',
  },
  {
    title: 'Absence',
    bg: 'red',
  },
  {
    title: 'Congé sans solde',
    bg: 'blue',
  },
  {
    title: 'Congé payés',
    bg: 'orange',
  },
]
const Payroll = () => {
  const employees = useSelector((state) => state.employees.employees)
  const [weeks] = useState(getDaysInMonth(9, 2023))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchEmployee())
  }, [])

  function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1)
    var weeks = []
    while (date.getMonth() === month) {
      const weekName = date
        .toLocaleString('fr', { weekday: 'long' })
        .substr(0, 3)
      weeks.push({
        date: date.getDate(),
        month,
        year,
        weekName: weekName.charAt(0).toUpperCase() + weekName.slice(1),
      })
      date.setDate(date.getDate() + 1)
    }
    return weeks
  }

  const handleDate = (employeeId, { date, month, year }) => {
    const employee = employees.filter(
      (employee, index) => employee._id === employeeId,
    )[0]
    dispatch(
      openModal({
        type: 'attendance',
        extra: { employee, date: { date, month, year } },
      }),
    )
  }

  const activateAttendance = (employee, currentDate) => {
    const attendance = employee.attendances?.find((attendance) => {
      const date = parseInt(attendance?.date.split('/')[0])
      return date === currentDate
    })

    return labels.filter((label) => label.title === attendance?.type)[0]?.bg
  }

  return (
    <div>
      <NewAttendance />
      <Table withBorder withColumnBorders mt={25} className="payroll">
        <thead>
          <tr>
            <th>Employés</th>
            <th style={{ padding: 0 }}>
              <SimpleGrid cols={weeks.length} style={{ gap: 0 }}>
                {weeks.map(({ date, weekName }, index) => (
                  <th
                    key={index}
                    style={{
                      background: ['Sam', 'Dim'].includes(weekName)
                        ? 'rgb(21, 170, 191)'
                        : '',
                      color: ['Sam', 'Dim'].includes(weekName) ? '#fff' : '',
                    }}
                  >
                    <div
                      style={{
                        width: 20,
                        height: 'auto',
                        fontSize: 10,
                        color: ['Sam', 'Dim'].includes(weekName)
                          ? '#fff'
                          : 'rgb(34, 139, 230)',
                        fontWeight: 600,
                      }}
                    >
                      {date} <br />
                      <span style={{ fontSize: 9, fontFamily: 'Josefinslab' }}>
                        {weekName}
                      </span>
                    </div>
                  </th>
                ))}
              </SimpleGrid>
            </th>
          </tr>
        </thead>
        <tbody>
          {employees &&
            employees.map((employee, index) => (
              <tr key={index}>
                <td style={{ padding: '0 13px' }}> {employee.name}</td>
                <td style={{ padding: 0 }}>
                  <SimpleGrid cols={weeks.length} style={{ gap: 0 }}>
                    {Array.from({ length: weeks.length }, (_, i) => i + 1).map(
                      (day, index) => (
                        <td>
                          <div
                            onClick={() =>
                              handleDate(employee._id, weeks[index])
                            }
                            className="payroll-days"
                            style={{
                              background: ['Sam', 'Dim'].includes(
                                weeks[index].weekName,
                              )
                                ? 'rgb(21, 170, 191)'
                                : activateAttendance(employee, day),
                            }}
                          ></div>
                        </td>
                      ),
                    )}
                  </SimpleGrid>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 20,
          fontSize: 13,
          fontFamily: 'Rubik',
        }}
      >
        {labels.map(({ title, bg }, index) => (
          <>
            <div
              style={{
                height: 25,
                width: 25,
                borderRadius: 3,
                background: bg,
              }}
            />
            <span style={{ padding: '0 15px' }}>{title}</span>
          </>
        ))}
      </div>
    </div>
  )
}

export default Payroll
