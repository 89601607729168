import React from 'react'
import { formatMoney } from '../../utils/formatMoney'
import { Text, Badge, Stack, Table } from '@mantine/core'
import { DataTable } from 'mantine-datatable'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'

const Orders = ({ orders }) => {
  const total = (orderItems) => {
    return orderItems.reduce((total, orderItem) => {
      return total + orderItem.item.totalProductPrice
    }, 0)
  }

  return (
    <div>
      <Text fw={600} style={{ fontSize: 25, marginBottom: 20 }}>
        List orders
      </Text>
      <Text fz="xs" c="dimmed" mt={3} mb="xl"></Text>
      <DataTable
        columns={[
          {
            accessor: 'ref',
            title: 'Order ID',
          },
          {
            accessor: 'user.username',
            title: 'Nom',
          },
          { accessor: 'email' },
          { accessor: 'address' },
          { accessor: 'phone' },
          { accessor: 'paymentMethod', title: 'Payment method' },
          {
            accessor: '',
            title: 'Total',
            render: ({ orderItems }) => formatMoney(total(orderItems)) + ' Ar',
          },
          {
            accessor: 'paid',
            render: ({ paid }) => (
              <Badge color={`${paid ? 'blue' : 'red'}`}>
                {paid ? 'payé' : 'non payé'}
              </Badge>
            ),
          },
          {
            accessor: '',
            render: ({ ref }) => (
              <Link
                style={{ textDecoration: 'underline' }}
                target="_blank"
                to={`/invoice/${ref}`}
              >
                Facture
              </Link>
            ),
          },
        ]}
        records={orders}
        rowExpansion={{
          allowMultiple: false,
          content: ({ record }) => (
            <Stack p="xs" spacing={'xs'}>
              <Table sx={{ minWidth: 800 }} size="sm" verticalSpacing="xs">
                <tbody>
                  {record.orderItems.map((orderItem, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={orderItem.item.products.productImage}
                          width={50}
                          height={50}
                          style={{
                            objectFit: 'cover',
                            float: 'left',
                          }}
                          alt=""
                        />
                        <div
                          style={{
                            paddingLeft: 15,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span>
                            {orderItem.item.products.title} <br />
                          </span>
                          <span>Poduct type: {orderItem.item.productType}</span>
                        </div>
                      </td>
                      <td>
                        {formatMoney(orderItem.item.products.newPrice)} x{' '}
                        {orderItem.item.totalProductQuantity}
                      </td>
                      <td>
                        {orderItem.item.productCustomDescription
                          ? parse(orderItem.item.productCustomDescription)
                          : '-'}
                      </td>
                      <td>
                        {formatMoney(orderItem.item.totalProductPrice)}
                        Ar
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Stack>
          ),
        }}
      />
    </div>
  )
}

export default Orders
