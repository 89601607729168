import React, { useEffect, useState } from 'react'
import { Button, Card, Pagination, Table, Text } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import Filter from '../../../../components/HRManagement/Filter'
import Management from '../../Containers/Management'
import { fetchSalaries } from '../../../../store/actions/salary.action'
import Salary from '../../../../components/HRManagement/Salary/Salary'
import { openModal } from '../../../../store/actions/modal.action'
import { IconPlus } from '@tabler/icons-react'
import UpdateSalary from '../../../../components/HRManagement/Salary/UpdateSalary'

const Salaries = () => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const salaries = useSelector((state) => state.salaries.salaries)
  const totalPages = useSelector((state) => state.salaries.totalPages)

  useEffect(() => {
    dispatch(fetchSalaries(page))
  }, [page])

  return (
    <div>
      <Management>
        <UpdateSalary />
        <Filter>
          <Button
            variant="default"
            style={{ display: 'flex', justifyContent: 'end' }}
            onClick={() => dispatch(openModal({ type: 'salary' }))}
          >
            <IconPlus size={20} style={{ marginRight: 5 }} />
            Ajouter un salaire
          </Button>
        </Filter>
        <Card padding="lg" radius="md" mt={10} className="hr-management">
          <Card.Section
            style={{
              background: 'rgb(34, 139, 230)',
              color: '#fff',
              padding: 8,
              fontWeight: 600,
            }}
          >
            <Text align="center">Gestion des salaires</Text>
          </Card.Section>
          <Card.Section>
            <Table withBorder withColumnBorders>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Salaire</th>
                  <th>Numero</th>
                  <th>Mois</th>
                  <th>Salaire de base</th>
                  <th>Prime d'assiduité (+)</th>
                  <th>Bonus évènements (+)</th>
                  <th>Pénalités et sanctions (-)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {salaries &&
                  salaries.length > 0 &&
                  salaries.map((salary, index) => (
                    <Salary key={index} salary={salary} />
                  ))}
              </tbody>
            </Table>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination
                total={totalPages}
                onChange={(page) => setPage(page)}
                color="dark"
                size="sm"
                mt={15}
                mb={15}
                mr={15}
              />
            </div>
          </Card.Section>
        </Card>
      </Management>
    </div>
  )
}

export default Salaries
