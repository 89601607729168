import React, { useEffect, useState } from 'react'
import {
  Card,
  TextInput,
  FileInput,
  Grid,
  Select,
  SimpleGrid,
  Image,
  Group,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { notifications } from '@mantine/notifications'
import {
  IconBuildingBank,
  IconCalendar,
  IconCashBanknote,
  IconEPassport,
  IconGenderBigender,
  IconHierarchy2,
  IconHomeLink,
  IconMail,
  IconMap,
  IconMapPinMinus,
  IconNumber,
  IconPhone,
  IconReportMoney,
  IconServicemark,
  IconStatusChange,
  IconUser,
  IconUserPin,
  IconX,
} from '@tabler/icons-react'
import { DatePickerInput } from '@mantine/dates'
import {
  createEmployee,
  fetchEmployee,
} from '../../../store/actions/employee.action'
import Button from '../../common/Button'

const Information = ({ employee }) => {
  const [previewAvatar, setPreviewAvatar] = useState(employee?.avatar)
  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.loader.isLoading)

  const form = useForm({
    initialValues: {
      name: '',
      numero: '',
      birthday: '',
      cin: '',
      email: '',
      department: '',
      status: '',
      bankId: '',
      startDate: '',
      lastname: '',
      birthLocation: '',
      phone: '',
      city: '',
      salary: '',
      iban: '',
      sexe: '',
      city: '',
      address: '',
      familyStatus: '',
      file: '',
      id: '',
    },
  })

  useEffect(() => {
    form.setValues({
      ...employee,
      birthday: employee ? new Date(employee.birthday) : null,
      startDate: employee ? new Date(employee.startDate) : null,
      id: employee ? employee._id : null,
    })
  }, [employee])

  const handleSubmit = async (data) => {
    dispatch(createEmployee(data))
    dispatch(fetchEmployee())
    notifications.show({
      title: 'Notification',
      message: 'Employés modifié',
    })
  }

  return (
    <Card style={{ paddingBottom: 35 }}>
      <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col md={9}>
            <SimpleGrid cols={2}>
              <div>
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Numero"
                  disabled
                  required
                  {...form.getInputProps('numero')}
                  icon={<IconNumber size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Nom"
                  required
                  {...form.getInputProps('name')}
                  icon={<IconUser size={18} />}
                />
                <DatePickerInput
                  mt={10}
                  radius={'xs'}
                  placeholder="Date de naissance"
                  form
                  mx="auto"
                  {...form.getInputProps('birthday')}
                  icon={<IconCalendar size={18} />}
                  valueFormat="DD/MM/YYYY"
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="CIN"
                  required
                  {...form.getInputProps('cin')}
                  icon={<IconEPassport size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Email"
                  required
                  type="email"
                  {...form.getInputProps('email')}
                  icon={<IconMail size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Adresse"
                  {...form.getInputProps('address')}
                  required
                  icon={<IconHomeLink size={18} />}
                />
                <Select
                  placeholder="Service"
                  icon={<IconServicemark />}
                  mt={10}
                  data={['Mise en bauté', 'Décoration', 'Carteries', 'Resto']}
                  required
                  {...form.getInputProps('department')}
                />

                <Select
                  placeholder="Banque"
                  icon={<IconBuildingBank size={18} />}
                  mt={10}
                  data={[
                    'BOA Madagascar',
                    'BNI Madagascar',
                    'BFV Madagascar',
                    'BMOI',
                  ]}
                  required
                  {...form.getInputProps('bankId')}
                />
              </div>
              <div>
                <DatePickerInput
                  placeholder="Date de début"
                  mt={10}
                  radius={'xs'}
                  mx="auto"
                  {...form.getInputProps('startDate')}
                  icon={<IconCalendar size={18} />}
                  valueFormat="DD/MM/YYYY"
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Prénom"
                  required
                  {...form.getInputProps('lastname')}
                  icon={<IconUserPin size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Lieu de naissance"
                  required
                  {...form.getInputProps('birthLocation')}
                  icon={<IconMapPinMinus size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Téléphone"
                  required
                  {...form.getInputProps('phone')}
                  icon={<IconPhone size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Ville"
                  required
                  {...form.getInputProps('city')}
                  icon={<IconMap size={18} />}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="username"
                  variant="default"
                  placeholder="Salaire"
                  required
                  {...form.getInputProps('salary')}
                  icon={<IconReportMoney size={18} />}
                />
                <Select
                  placeholder="Status"
                  icon={<IconStatusChange size={18} />}
                  mt={10}
                  data={['En activité', 'Sans emploi']}
                  required
                  {...form.getInputProps('status')}
                />
                <Select
                  placeholder="Status familial"
                  icon={<IconHierarchy2 size={18} />}
                  mt={10}
                  data={['Celibataire', 'Marié', 'Divorcé', 'Veuf']}
                  required
                  {...form.getInputProps('familyStatus')}
                />
              </div>
            </SimpleGrid>
          </Grid.Col>
          <Grid.Col md={3}>
            <Card padding="lg" radius="xs" mt={10}>
              <Card.Section
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                <Image
                  radius={5}
                  src={
                    previewAvatar
                      ? previewAvatar
                      : 'https://soma-tech.com/hrv1/uplode/img/user.png'
                  }
                  height={120}
                  width={120}
                  alt="Norway"
                />
              </Card.Section>
              <Group position="apart" mt="md" mb="xs">
                <FileInput
                  label="Uploader une photo"
                  placeholder="Upload files"
                  accept="image/png,image/jpeg"
                  {...form.getInputProps('file')}
                />
                <Select
                  placeholder="Sexe"
                  icon={<IconGenderBigender />}
                  data={[
                    {
                      value: 'femme',
                      label: 'Je suis une femme',
                    },
                    {
                      value: 'homme',
                      label: 'Je suis un homme',
                    },
                  ]}
                  required
                  {...form.getInputProps('sexe')}
                />
              </Group>
            </Card>
            <Button fullWidth noHover={true} mt={15} loading={isLoading}>
              mettre à jour
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Card>
  )
}

export default Information
