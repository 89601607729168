import { REMOVE_ALL_FILTER, REMOVE_FILTER, SET_FILTER, SORT_BY } from '../types'

export const setFilter = (payload) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    payload,
  })
}

export const removeFilter = (payload) => (dispatch) => {
  dispatch({
    type: REMOVE_FILTER,
    payload,
  })
}

export const removeAllFilter = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_FILTER,
  })
}

export const sortBy = (payload) => (dispatch) => {
  dispatch({
    type: SORT_BY,
    payload,
  })
}
