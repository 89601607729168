import React from 'react'
import { IconX } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { closeModal } from '../store/actions/modal.action'

const Banner = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector((state) =>
    state.modal.openModal?.type === 'widget_banner'
      ? state.modal.openModal.value
      : false,
  )
  const bannerData = useSelector((state) => state.modal.extra)

  return (
    <div>
      {isOpen && (
        <Content show={isOpen}>
          Vous ne pouvez pas ajouter cette quantité dans le panier pour ce
          produit <strong>{bannerData?.productTitle}</strong> — nous en avons{' '}
          {bannerData?.availableStock}&nbsp; en stock et vous en avez déjà{' '}
          {bannerData?.availableStock} dans votre panier.
          <IconX
            onClick={() => dispatch(closeModal({ type: 'widget_banner' }))}
            style={{ position: 'absolute', top: 25, right: 20 }}
          />
        </Content>
      )}
    </div>
  )
}

const Content = styled.div`
  height: 70px;
  line-height: 70px;
  padding-left: 40px;
  width: 100%;
  background: #fc5f5f;
  color: #fff;
  position: fixed;
  bottom: ${(props) => (props.show ? 0 : -70)};
  z-index: 99999;
  transition: 0.3s;
`
export default Banner
