import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Containers/Layout";
import { Table, Text, Card } from "@mantine/core";
import { useGetQuery } from "../../../hooks/useGetQuery";
import { Switch } from "@mantine/core";
import { usePostQuery } from "../../../hooks/usePostQuery";

const Testimonials = () => {
  const [isApproved, setIsApproved] = useState(false);
  const { data, refetch } = useGetQuery("testimonials", {
    sessionToken: sessionStorage.getItem("sessionToken"),
  });
  const { sendRequest } = usePostQuery("testimonials/approved", "PUT", false, {
    "Content-Type": "application/json",
  });

  const handleApproved = async (event, id) => {
    setIsApproved((isApproved) => !isApproved);
    await sendRequest({ id, isApproved });
    refetch();
  };

  return (
    <div>
      <Layout>
        <Text fw={500} fz={25} mb={10} style={{ fontFamily: "JosefinSlab" }}>
          Avis des clients
        </Text>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Table style={{ fontFamily: "JosefinSlab" }}>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Contenu</th>
                <th>Note</th>
                <th>Approuvé?</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?._testimonials.length > 0 &&
                data._testimonials.map((testimonial, index) => (
                  <tr>
                    <td>{testimonial.name}</td>
                    <td>{testimonial.content}</td>
                    <td>{testimonial.rating}</td>
                    <td>
                      <Switch
                        checked={testimonial.isApproved}
                        onChange={(event) =>
                          handleApproved(event, testimonial._id)
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
      </Layout>
    </div>
  );
};

export default Testimonials;
