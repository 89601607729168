import React from 'react'
import { Col } from 'react-grid-system'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import WishlistButton from './WishlistButton'
import { useTranslation } from 'react-i18next'
import 'react-multi-carousel/lib/styles.css'

const ProductItem = ({ productImage, title, newPrice, url, index, slug }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Col md={4} lg={3} xs={12} sm={6} key={index}>
      <ImageBg>
        <ProductImage
          src={productImage}
          width={'100%'}
          height={300}
          style={{ objectFit: 'cover' }}
          alt=""
        />
        <ButtonCart onClick={() => navigate(url)}>{t('shop.label')}</ButtonCart>
        <WishlistButton slug={slug} />
      </ImageBg>
      <Info>
        <Name>
          <Link to={url}>{title}</Link>
        </Name>
        <Price>
          {newPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })} Ar
        </Price>
      </Info>
    </Col>
  )
}
const ProductImage = styled.img``
const Info = styled.div`
  font-family: 'JosefinSlab';
  @media screen and (max-width: 800px) {
    margin-left: 15px;
  }
`
const Name = styled.p`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: lighter;
  letter-spacing: 1px;
`
const Price = styled.p`
  font-size: 15px;
  color: #333;
  margin-top: 5px;
`
const ButtonCart = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 13px;
  color: rgb(48, 48, 48);
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  font-weight: 600;
  padding: 20px 32px;
  width: 80%;
  text-align: center;
  font-family: 'JosefinSlab';
  letter-spacing: 1px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
`
const ImageBg = styled.div`
  height: 300px;
  background-color: #f6f6f6;
  margin: 20px 0px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    ${ButtonCart} {
      opacity: 1;
    }
  }
`

export default ProductItem
