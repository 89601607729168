import React from "react";

const Loading = () => {
  return (
    <div class="cssloader">
      <div class="sh1"></div>
      <div class="sh2"></div>
      <h4 class="lt">wedding loading</h4>
    </div>
  );
};

export default Loading;
