import {
  IconBasket,
  IconHeart,
  IconHome,
  IconInfoCircle,
  IconLogin,
  IconLogout,
  IconMail,
  IconPhoto,
  IconRegistered,
  IconServicemark,
  IconSettingsUp,
  IconShoppingBag,
  IconX,
} from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthContext } from '../../context/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from '../../store/actions/modal.action'
import { useTranslation } from 'react-i18next'

const Mobile = () => {
  const { isLoggedIn } = useAuthContext()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'menu'
      ? state.modal.openModal.value
      : false,
  )
  return (
    <ResponsiveMenu isOpen={modalStatus}>
      <MainMenu>
        <div>main menu</div>
        <div>
          <IconX
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(closeModal({ type: 'cart' }))}
          />
        </div>
      </MainMenu>
      <MenuItems>
        <Item>
          <RouterLink to={'/'}>
            <IconHome style={{ marginRight: 5 }} /> {t('menu.home')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/about-us'}>
            <IconInfoCircle style={{ marginRight: 5 }} /> {t('menu.about')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/our-services/wedding'}>
            <IconServicemark style={{ marginRight: 5 }} /> {t('menu.services')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/portfolio'}>
            <IconPhoto style={{ marginRight: 5 }} /> {t('menu.portfolio')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/collections'}>
            <IconShoppingBag style={{ marginRight: 5 }} /> {t('menu.shop')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/contact'}>
            <IconMail style={{ marginRight: 5 }} /> {t('menu.contact')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink onClick={() => dispatch(openModal('cart'))}>
            <IconBasket style={{ marginRight: 5 }} /> {t('menu.bag')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/wishlist'}>
            <IconHeart style={{ marginRight: 5 }} /> {t('menu.wishlist')}
          </RouterLink>
        </Item>
        <Item>
          <RouterLink to={'/auth'}>
            <IconLogin style={{ marginRight: 5 }} /> {t('menu.login')}
          </RouterLink>
        </Item>
        {!isLoggedIn && (
          <Item>
            <RouterLink to={'/auth'}>
              <IconRegistered style={{ marginRight: 5 }} /> {t('menu.register')}
            </RouterLink>
          </Item>
        )}
        {isLoggedIn && (
          <Item>
            <RouterLink to={'/'}>
              <IconLogout style={{ marginRight: 5 }} /> {t('menu.logout')}
            </RouterLink>
          </Item>
        )}
      </MenuItems>
    </ResponsiveMenu>
  )
}

const ResponsiveMenu = styled.div`
  height: 100vh;
  width: 300px;
  background: #fff;
  position: fixed;
  z-index: 99999;
  top: 0;
  padding: 40px 30px;
  transition: 0.3s;
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(-100%)'};
`
const MainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Futura';
  text-transform: uppercase;
  letter-spacing: 2px;
`
const MenuItems = styled.ul`
  list-style: none;
  margin-top: 25px;
`
const Item = styled.li`
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 2px;
  font-size: 14px;
  cusror: pointer;
  font-weight: 500;
`
const RouterLink = styled(Link)`
  display: flex;
  align-items: center;
`
export default Mobile
