import React, { useState, useEffect } from 'react';
import Layout from '../Containers/Layout';
import {
  Button,
  Card,
  Grid,
  Select,
  SimpleGrid,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import axios from 'axios';
import {
  IconBox,
  IconBrandFacebook,
  IconCalendar,
  IconDatabaseOff,
  IconDeviceFloppy,
  IconMail,
  IconMapPin,
  IconMenu,
  IconPhone,
  IconTextCaption,
  IconUser,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import ListArticle from './ListArticle';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../store/actions/modal.action';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { calculateDay } from '../../../utils/calculateDay';
import { priceFormatter } from '../../../utils/priceFormatter';

const NewOrderLogistics = () => {
  const [formValues, setFormValues] = useState([
    {
      label: 'Prénom du couple',
      name: 'name',
      value: '',
      icon: <IconUser size={20} />,
    },
    {
      label: 'Email',
      name: 'email',
      value: '',
      icon: <IconMail size={20} />,
    },
    {
      label: 'Numero téléphone',
      name: 'phone',
      value: '',
      icon: <IconPhone size={20} />,
    },
    {
      label: 'Compte facebook',
      name: 'fb',
      value: '',
      icon: <IconBrandFacebook size={20} />,
    },
    {
      label: 'Nombre de convives',
      name: 'person',
      value: '',
      icon: <IconUsers size={20} />,
    },
    {
      label: 'Lieu',
      name: 'location',
      value: '',
      icon: <IconMapPin size={20} />,
    },
    {
      label: 'Date',
      name: 'date',
      value: '',
      icon: <IconCalendar size={20} />,
    },
    {
      label: 'Menu',
      name: 'menu',
      value: '',
      icon: <IconMenu size={20} />,
    },
    {
      label: 'Observation',
      name: 'observation',
      value: '',
      icon: <IconTextCaption size={20} />,
    },
  ]);
  let [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logistics, setLogistics] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchArticle, setSearchArticle] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientRef = searchParams.get('ref');

  useEffect(() => {
    async function fetchArticles() {
      setFetching(true);
      let url = `${process.env.REACT_APP_BASE_URL}articles?page=${currentPage}&limit=10`;
      if (searchArticle) {
        url += `&q=${searchArticle}`;
      }
      const { data: { data: articles, totalPages } = {} } =
        await axios.get(url);
      setArticles(articles);
      setTotalPages(totalPages);

      if (articles.length == 0) {
        setCurrentPage(1);
      }
      setFetching(false);
    }
    fetchArticles();
  }, [currentPage, searchArticle]);

  useEffect(() => {
    async function getClientInfo() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}client?ref=${clientRef}`,
      );
      if (data[0].hasOwnProperty('orderLogistics')) {
        setLogistics(data[0].orderLogistics);
        const client = formValues.map((field) => {
          if (Object.keys(data[0]).includes(field.name)) {
            field.value = data[0][field.name];
          }
          return field;
        });
        setFormValues(client);
      }
    }
    getClientInfo();
  }, [clientRef]);

  const handleChange = (e, index) => {
    const values = [...formValues];
    values[index].value = e.target.value;
    setFormValues(values);
    localStorage.setItem(
      'formValues',
      JSON.stringify(
        formValues.reduce((r, v) => ({ ...r, [v.name]: v.value }), {}),
      ),
    );
  };

  const handleAddArticle = (article) => {
    setLogistics([
      ...logistics.filter((logistic) => logistic.designation !== article.name),
      {
        designation: article.name,
        price: article.price,
        priceCasse: article.priceCasse,
        startDate: '',
        endDate: '',
        qty: '',
        qtyDispo: article.qtyDispo,
        amount: 0,
        service: '',
      },
    ]);
  };

  const minDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  };

  const handleChangeLogistic = (e, index) => {
    let name, value;
    if (typeof e === 'string') {
      name = 'service';
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    let data = [...logistics];
    data[index][name] = value;
    if (name === 'quantity') {
      data[index]['amount'] = Math.round(
        data[index]['quantity'] * data[index]['rentalPrice'],
      );
    }
    if (name === 'startDate' || name === 'endDate') {
      let nbRental =
        calculateDay(data[index]['startDate'], data[index]['endDate']) / 3;
      data[index]['nbRental'] = Math.round(nbRental);
      data[index]['rentalPrice'] = Math.round(data[index]['price'] * nbRental);
    }

    setLogistics(data);
  };

  const handleDeleteLogistic = (index) => {
    let data = [...logistics];
    data.splice(index, 1);
    setLogistics(data);
  };

  const getTotalLogistics = () =>
    logistics.reduce((total, current) => {
      return total + current.amount;
    }, 0);

  const onSave = async () => {
    const params = Object.assign(
      {},
      {
        ...formValues.reduce((r, v) => ({ ...r, [v.name]: v.value }), {}),
        articles: logistics,
        clientRef,
      },
    );
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}logistics`,
      params,
    );
    if (data.status === 'success') {
      localStorage.removeItem('formValues');
      navigate('/admin/logistics');
    }
  };

  return (
    <Layout>
      <ListArticle
        articles={articles}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        handleAddArticle={handleAddArticle}
        handleSearchArticle={(value) => setSearchArticle(value)}
        isFetching={isFetching}
      />
      <Card padding="lg" radius="md" withBorder className="logistics">
        <Text
          fw={600}
          transform="uppercase"
          mb={10}
          style={{ letterSpacing: 1 }}
        >
          Information du client
        </Text>
        <SimpleGrid cols={3}>
          {formValues.map((obj, index) => (
            <div>
              <TextInput
                placeholder={obj.label}
                name={obj.name}
                onChange={(e) => handleChange(e, index)}
                size="md"
                defaultValue={obj.value}
                icon={obj.icon}
                type={obj.name === 'date' ? 'date' : 'text'}
              />
            </div>
          ))}
        </SimpleGrid>
      </Card>
      <Grid mt={10} className="logistic">
        <Grid.Col md={12}>
          <Card padding="lg" radius="md" withBorder>
            <>
              <Table className="table table-bordered auth-register">
                <thead>
                  <tr>
                    <th>Désignation</th>
                    <th>PU</th>
                    <th>Date prise</th>
                    <th>Date retour</th>
                    <th>Nombre de location</th>
                    <th>Quantité</th>
                    <th>Montant</th>
                    <th>Service</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {logistics &&
                    logistics.length > 0 &&
                    logistics.map((logistic, index) => (
                      <tr>
                        <td>{logistic.designation}</td>
                        <td>{logistic.rentalPrice || 0}</td>
                        <td>
                          <TextInput
                            placeholder="Date prise"
                            mx="auto"
                            maw={400}
                            type="date"
                            name="startDate"
                            defaultValue={logistic.startDate?.split('T')[0]}
                            min={minDate()}
                            onChange={(e) => handleChangeLogistic(e, index)}
                          />
                        </td>
                        <td>
                          <TextInput
                            placeholder="Date de retour"
                            mx="auto"
                            maw={400}
                            type="date"
                            name="endDate"
                            min={minDate()}
                            defaultValue={logistic.endDate?.split('T')[0]}
                            onChange={(e) => handleChangeLogistic(e, index)}
                          />
                        </td>
                        <td>{logistic.nbRental || 0}</td>
                        <td>
                          <TextInput
                            placeholder="Quantité"
                            mx="auto"
                            maw={400}
                            type="number"
                            name="quantity"
                            defaultValue={logistic.quantity}
                            onChange={(e) => handleChangeLogistic(e, index)}
                          />
                        </td>
                        <td>{priceFormatter(logistic.amount)}</td>
                        <td>
                          <Select
                            maw={320}
                            mx="auto"
                            placeholder="Services"
                            data={['Décoration', 'Mise en beauté', 'Carteries']}
                            transitionProps={{
                              transition: 'scale',
                              timingFunction: 'ease',
                            }}
                            withinPortal
                            name="service"
                            onChange={(e) => handleChangeLogistic(e, index)}
                          />
                        </td>
                        <td>
                          <Button
                            variant="unstyled"
                            onClick={() => handleDeleteLogistic(index)}
                          >
                            <IconX size={18} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {logistics.length === 0 && (
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={15}>
                        <div style={{ marginTop: 15 }}>
                          <IconDatabaseOff />
                          <p>No data</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div>
                <Text fw={600} ml={10} transform="uppercase">
                  <span style={{ letterSpacing: 2 }}>Total</span>:{' '}
                  {priceFormatter(getTotalLogistics())} Ar
                </Text>
                <div>
                  {logistics.length > 0 && (
                    <Button
                      color="dark"
                      style={{ letterSpacing: 2, fontSize: 12 }}
                      ml={10}
                      mt={20}
                      uppercase
                      onClick={onSave}
                    >
                      <IconDeviceFloppy size={20} style={{ marginRight: 5 }} />{' '}
                      sauvegarder
                    </Button>
                  )}
                  <Button
                    variant="default"
                    ml={10}
                    mt={20}
                    uppercase
                    onClick={() => dispatch(openModal({ type: 'logistics' }))}
                    style={{ letterSpacing: 2, fontSize: 12 }}
                  >
                    <IconBox size={20} style={{ marginRight: 5 }} /> Ajouter
                    article
                  </Button>
                </div>
              </div>
            </>
          </Card>
        </Grid.Col>
      </Grid>
    </Layout>
  );
};

export default NewOrderLogistics;
