import * as React from 'react'
const IconArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    aria-hidden="true"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.563 5.063c-.438-.438-1.188-.438-1.625 0s-.438 1.188 0 1.625l8.188 8.188h-27c-.625 0-1.125.5-1.125 1.125s.5 1.125 1.125 1.125h27l-8.188 8.188c-.438.438-.438 1.188 0 1.625s1.188.438 1.625 0l10.125-10.125a1.17 1.17 0 0 0 0-1.625z"
    />
  </svg>
)
export default IconArrowUp
