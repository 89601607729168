import axios from 'axios'
import { CREATE_NEW_EMPLOYEE, DELETE_EMPLOYEE, FETCH_EMPLOYEE } from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchEmployee = (allRecord = false) => {
  return async (dispatch, getState) => {
    let url = `${API}employee?limit=10`
    if (allRecord) {
      url += '&page=0'
    }
    const { data } = await axios.get(url)
    dispatch({
      type: FETCH_EMPLOYEE,
      payload: data,
    })
  }
}

export const createEmployee = (values) => {
  return async (dispatch, getState) => {
    const formValues = Object.keys(values).reduce((formData, key) => {
      formData.append(key, values[key])
      return formData
    }, new FormData())

    try {
      const { data } = await axios.post(`${API}employee`, formValues)
      dispatch({
        type: CREATE_NEW_EMPLOYEE,
        payload: data.data,
      })
    } catch (err) {}
  }
}

export const deleteEmployee = (id) => {
  return async (dispatch, getState) => {
    try {
      await axios.delete(`${API}employee/${id}`)
      dispatch({
        type: DELETE_EMPLOYEE,
        payload: { id },
      })
    } catch (err) {}
  }
}
