import React from 'react'
import { Layout } from '../components/Layout'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-grid-system'
import { QuantityInput } from '../components/common/NumberInput'
import { IconX } from '@tabler/icons-react'
import { closeModal, openModal } from '../store/actions/modal.action'
import { updateProductInCart } from '../store/actions/cart.action'
import { getStorage } from '../utils/encrypt'
import { deleteProductInCart } from '../store/actions/cart.action'
import { Button, Table } from '@mantine/core'
import IconBasket from '../components/icons/IconBasket'
import { createSearchParams, useNavigate } from 'react-router-dom'
import ProductItem from '../components/ProductItem'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../context/AuthContext'
const cart = getStorage('cart')

const Basket = () => {
  const cartProduct = useSelector((state) => state.cart.cart)
  const products = useSelector((state) => state.products.products)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuthContext()

  const { t } = useTranslation()

  const onChange = (quantity, product) => {
    if (quantity > product.availableStock) {
      dispatch(
        openModal({
          type: 'widget_banner',
          extra: {
            availableStock: product.availableStock,
            productTitle: product.title,
          },
        }),
      )
    } else {
      dispatch(
        updateProductInCart({
          productId: product._id,
          quantity,
          cart,
        }),
      )
    }
  }

  const processCheckout = () => {
    if (!isLoggedIn) {
      dispatch(closeModal({ type: 'cart' }))
      navigate({
        pathname: '/auth',
        search: createSearchParams({
          returnUrl:
            window.location.protocol +
            '//' +
            window.location.host +
            '/checkout',
        }).toString(),
      })
    } else {
      navigate('/checkout')
    }
  }

  return (
    <Layout withSlider={false}>
      <Container>
        {Object.keys(cartProduct).length > 0 ? (
          <>
            <p
              style={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '1.5rem',
                letterSpacing: '3px',
                fontFamily: 'Futura',
                fontWeight: 600,
              }}
            >
              PANIER <br />
              <span
                style={{
                  fontSize: 14,
                  textTransform: 'lowercase',
                  fontFamily: 'Futura',
                }}
              ></span>
            </p>
            <Row style={{ marginTop: 25 }}>
              <Table>
                <thead>
                  <tr style={{ borderTop: '0.0625rem solid #dee2e6' }}>
                    <th style={{ padding: 15 }}>Produit</th>
                    <th style={{ padding: 15 }}>Prix</th>
                    <th style={{ padding: 15 }}>Quantité</th>
                    <th style={{ padding: 15 }}>Sous total</th>
                  </tr>
                </thead>
                <tbody>
                  {cartProduct.items?.map((cartProduct, key) => (
                    <tr>
                      <td>
                        <Button
                          color="default"
                          variant="unstyled"
                          radius="xs"
                          onClick={() => {
                            dispatch(
                              deleteProductInCart(
                                cartProduct._id,
                                getStorage('cart'),
                              ),
                            )
                          }}
                        >
                          <IconX size={18} />
                        </Button>
                        <img
                          src={cartProduct.products.productImage}
                          alt=""
                          width={120}
                          style={{
                            height: 120,
                            objectFit: 'cover',
                            marginLeft: 15,
                          }}
                        />
                        <span style={{ marginLeft: 15 }}>
                          {cartProduct.products.title}
                        </span>
                      </td>
                      <td>
                        {' '}
                        {cartProduct.products.newPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          },
                        )}
                        &nbsp; Ar
                      </td>
                      <td>
                        <QuantityInput
                          availableStock={cartProduct.products.availableStock}
                          defaultValue={cartProduct.totalProductQuantity}
                          value={(value) =>
                            onChange(value, cartProduct.products)
                          }
                        />
                      </td>
                      <td>
                        {' '}
                        {cartProduct.totalProductPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          },
                        )}{' '}
                        Ar
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col md={8}></Col>
              <Col md={4}>
                <Table style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ borderTop: '0.0625rem solid #dee2e6' }}>
                      <td style={{ padding: '15px 0' }}>
                        <strong>Sous-total</strong>
                      </td>
                      <td style={{ float: 'right', padding: '15px 0' }}>
                        {cartProduct.totalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}{' '}
                        Ar
                      </td>
                    </tr>
                    <tr style={{ borderTop: '0.0625rem solid #dee2e6' }}>
                      <td style={{ padding: '15px 0' }}>
                        <strong>Expédition</strong>
                      </td>
                      <td style={{ float: 'right', padding: '15px 0' }}>
                        Livraison à Domicile
                      </td>
                    </tr>
                    <tr style={{ borderTop: '0.0625rem solid #dee2e6' }}>
                      <td style={{ padding: '15px 0' }}>
                        <strong>Total</strong>
                      </td>
                      <td style={{ float: 'right', padding: '15px 0' }}>
                        {cartProduct.totalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}{' '}
                        Ar
                      </td>
                    </tr>
                  </thead>
                </Table>
                <Button
                  color="dark"
                  radius="xs"
                  fullWidth
                  variant="outline"
                  onClick={processCheckout}
                  style={{ textTransform: 'uppercase', fontSize: 13 }}
                >
                  Valider la commande
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconBasket />
            <p style={{ marginTop: 25 }}>Votre panier est actuellement vide.</p>
            <Button
              color="dark"
              radius="xs"
              mt={25}
              onClick={() => navigate('/collections')}
            >
              Retour à la boutique
            </Button>
          </div>
        )}
        <Row>
          <Col lg={12}>
            <RelatedProduct>
              <Title>{t('shop.similar')}</Title>
              <Row style={{ maxWidth: 1300, margin: '0 auto' }}>
                {products?.data.slice(4).map((product, index) => (
                  <ProductItem {...product} />
                ))}
              </Row>
            </RelatedProduct>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const RelatedProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`
const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
`
const Container = styled.div`
  max-width: 1500px;
  margin: 150px auto;
  font-family: 'JosefinSlab';
`
export default Basket
