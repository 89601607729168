import { SegmentedControl, Center, Box } from '@mantine/core'
import {
  IconInfoCircle,
  IconMoneybag,
  IconCalendar,
  IconUser,
} from '@tabler/icons-react'
import React from 'react'

const TabsContainer = (props) => {
  return (
    <SegmentedControl
      color="dark"
      transitionDuration={200}
      transitionTimingFunction="linear"
      fullWidth
      onChange={(value) => props.tabValue(value)}
      data={[
        {
          label: (
            <Center>
              <IconInfoCircle size={15} />
              <Box ml={10}>Informations</Box>
            </Center>
          ),
          value: '1',
        },
        {
          label: (
            <Center>
              <IconMoneybag size={15} />
              <Box ml={10}>Historique de paiement</Box>
            </Center>
          ),
          value: '2',
        },
        {
          label: (
            <Center>
              <IconCalendar size={15} />
              <Box ml={10}>Gestion de congé</Box>
            </Center>
          ),
          value: '3',
        },
        {
          label: (
            <Center>
              <IconUser size={15} />
              <Box ml={10}>Gestion de présence</Box>
            </Center>
          ),
          value: '4',
        },
        { label: 'Fichiers', value: 'node' },
      ]}
    />
  )
}

export default TabsContainer
