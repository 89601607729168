import React, { useEffect, useState } from 'react'
import {
  useMantineTheme,
  Modal,
  TextInput,
  SimpleGrid,
  FileInput,
  Grid,
  Select,
  Card,
  Image,
  Group,
  Checkbox,
} from '@mantine/core'
import styled from 'styled-components'
import {
  IconAlignBoxBottomLeft,
  IconBuildingBank,
  IconCalendar,
  IconCashBanknote,
  IconEPassport,
  IconFileReport,
  IconGenderBigender,
  IconHierarchy2,
  IconHomeLink,
  IconMail,
  IconMap,
  IconMapPinMinus,
  IconNumber,
  IconPhone,
  IconReportMoney,
  IconServicemark,
  IconStatusChange,
  IconUser,
  IconUserCircle,
  IconUserPin,
  IconX,
} from '@tabler/icons-react'
import Button from '../../../components/common/Button'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import { DateInput, DatePickerInput } from '@mantine/dates'
import {
  createEmployee,
  fetchEmployee,
} from '../../../store/actions/employee.action'
import { createLeave, fetchLeaves } from '../../../store/actions/leave.action'
import axios from 'axios'

const NewLeave = () => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'leave'
      ? state.modal.openModal.value
      : false,
  )

  const employees = useSelector((state) => state.employees.employees)
  const leave = useSelector((state) => state.modal.extra?.leave)

  useEffect(() => {
    dispatch(fetchEmployee(true))
  }, [])

  useEffect(() => {
    if (leave && Object.keys(leave).length > 0) {
      leave['startDate'] = new Date(leave['startDate'])
      leave['endDate'] = new Date(leave['endDate'])
      form.setFieldValue('employee', leave.employee)
      form.setValues(leave)
    }
  }, [leave])

  const form = useForm({
    initialValues: {
      employee: '',
      type: '',
      isPaid: '',
      startDate: '',
      endDate: '',
      status: '',
      id: '',
    },
  })

  const handleSubmit = async (data) => {
    if (!leave) {
      data['employeeId'] = data.employee
      delete data['_id']
    } else {
      data['id'] = leave._id
    }
    dispatch(createLeave(data))
    dispatch(closeModal({ type: 'leave' }))
    form.reset()
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="md"
      radius={'xs'}
      className="auth-register employee"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          dispatch(closeModal({ type: 'leave' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle> {!leave ? 'Créer' : 'Modifier'} un congé</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        >
          <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
            <Grid>
              <Grid.Col md={12}>
                <SimpleGrid cols={1}>
                  <div>
                    <Select
                      placeholder="Employé"
                      icon={<IconUserCircle size={18} />}
                      mt={10}
                      data={employees.map((employee) => ({
                        value: employee._id,
                        label: employee.name,
                      }))}
                      required
                      {...form.getInputProps('employee')}
                    />
                    <Select
                      placeholder="Type"
                      icon={<IconAlignBoxBottomLeft size={18} />}
                      mt={10}
                      data={[
                        'Congé de maternité',
                        'Repos médical',
                        'Autorisation absence ordinaire',
                      ]}
                      required
                      {...form.getInputProps('type')}
                    />
                    <DateInput
                      placeholder="Date de début"
                      mt={10}
                      radius={'xs'}
                      mx="auto"
                      {...form.getInputProps('startDate')}
                      icon={<IconCalendar size={18} />}
                      valueFormat="DD/MM/YYYY"
                    />
                    <DateInput
                      placeholder="Date de retour"
                      mt={10}
                      radius={'xs'}
                      mx="auto"
                      valueFormat="DD/MM/YYYY"
                      {...form.getInputProps('endDate')}
                      icon={<IconCalendar size={18} />}
                    />
                    <Select
                      placeholder="Status"
                      icon={<IconFileReport size={18} />}
                      mt={10}
                      data={['Validée', 'Inacceptable', 'En attendant	']}
                      required
                      {...form.getInputProps('status')}
                    />
                    <Checkbox
                      label="Congé payé?"
                      {...form.getInputProps('isPaid')}
                      mt={15}
                    />
                  </div>
                  <Button type="sublmit" fullWidth noHover={true} mt={15}>
                    {!leave ? 'Ajouter' : 'Modifier'}
                  </Button>
                </SimpleGrid>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
  padding: 0px 10px 25px 10px;
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default NewLeave
