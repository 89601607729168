import * as React from 'react'
const IconPointage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M131.3 21.4C113.1 26 98.9 39.8 93.6 57.9c-.9 3.1-1.6 9-1.6 13.5 0 14.9 4.7 26.3 15 36.7 10.2 10.2 21.6 14.9 36.6 14.9 32.7 0 57-30 50.3-62.2-3.5-16.8-17.1-32.1-33.5-37.9-7.5-2.6-21.9-3.4-29.1-1.5zm25.4 23.7c22.3 10.6 22 42.5-.5 53.6-7.9 3.9-18.3 3.9-25.7.1-18.5-9.6-22.8-32.4-9-47.6 5.9-6.6 15.9-10.4 25-9.4 2.2.2 6.8 1.7 10.2 3.3zM82.6 134.5c-18.7 5.2-33.9 20-39.9 39-2.2 7-2.2 7.4-2.2 79v72l2.6 5.5c6.2 13 20.7 20.9 33.5 18.2l4.4-1v53.7c0 29.5.4 56.3.9 59.6 1.8 12.4 9.7 23.1 20.9 28.3 4.8 2.2 7.1 2.7 14.7 2.7 7.8 0 9.8-.4 15.2-2.9 3.4-1.5 7.1-3.9 8.4-5.2l2.2-2.3 4 3.5c4.8 4 14.6 7.4 21.6 7.4 11.3 0 23.3-6.3 29.6-15.4 6.9-10.1 6.5-.8 6.5-148.2V195.1l60.3-.3 60.2-.3 5.7-2.8c11.1-5.4 17.3-15.9 17.2-28.7-.2-9-3.2-15.7-10-21.9-9.5-8.7.6-8.1-132.8-8-95.5.1-119 .3-123 1.4zm239.3 20.9c5.6 2.9 6.6 9.9 2.2 14.8l-2.9 3.3-66.1.5-66 .5-2.3 2.3-2.3 2.3-.5 140.7-.5 140.7-2.3 3.3c-6.2 8.6-18.1 8.5-24.6-.1-2.1-2.8-2.1-3.8-2.6-61.7l-.5-58.8-2.4-2.6c-3.2-3.4-8-4.2-12.4-2.1-2 .9-3.9 2.4-4.4 3.3-.4.9-1 27.8-1.3 59.7-.5 56.1-.6 58.1-2.5 61.5-5.5 9.3-18.2 9.6-24.9.7l-2.1-2.8-.5-125.5-.5-125.4-2.6-2.6c-5.8-5.7-15.7-3.4-17.8 4.3-.7 2.4-1.1 22.6-1.1 56.6v52.9l-2.9 2.9c-3.8 3.7-8.8 3.9-12.8.5l-2.8-2.4-.3-67.9c-.2-46 .1-69.5.8-73 2.3-11.1 12.3-21.7 23.9-25.4 5.3-1.7 12.6-1.8 118.9-1.8 96.2-.1 113.7.1 116.1 1.3zM372.7 175.1c-2.4 1.4-4.7 6.2-4.7 10 0 2.2 1 4.1 3.4 6.5l3.4 3.4h45c43.5 0 45-.1 47.5-2 3.1-2.4 5-7.8 4.1-11.4-.3-1.4-1.7-3.7-3.1-5.1l-2.6-2.5h-45.6c-29.1 0-46.2.4-47.4 1.1z" />
    <path d="M241.4 246c-6.6 2.7-8.4 11.7-3.5 17.2l2.9 3.3 110.6.3c123.4.3 116.3.6 119.3-6.5 1.3-3.3 1.4-4.3.2-7.8-2.8-8 5.7-7.5-117.9-7.4-60.2 0-110.5.4-111.6.9zM240.1 318.4c-5.8 3.2-6.9 11.9-2.2 16.7l2.9 2.9h224.9l2.6-2.5c1.4-1.4 2.8-3.7 3.1-5.1.9-3.6-1-9-4.1-11.4-2.5-2-3.8-2-113.7-2-90.3.1-111.5.3-113.5 1.4zM237.9 391.9c-4.8 4.9-3.4 14.2 2.6 16.9 2 .9 29.6 1.2 113.3 1.2 122.1 0 113.4.5 116.8-6.2 2.2-4.2 1.1-9-2.7-12.2l-3.1-2.6h-224l-2.9 2.9zM241.4 461c-6.6 2.7-8.4 11.7-3.5 17.2l2.9 3.3 111.4.3c108.9.2 111.4.2 114.5-1.7 7-4.3 6.3-15.5-1.1-18.6-3-1.3-19-1.5-112.8-1.4-60.1 0-110.3.4-111.4.9z" />
  </svg>
)
export default IconPointage
