import React, { useState, useEffect } from 'react'

export const useGetQuery = (url, headers = {}, onLoad = true) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const refetch = async (id) => {
    try {
      setLoading(true)

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}${url}${id ? '/' + id : ''}`,
        {
          headers: {
            ...headers,
            sessionToken: sessionStorage.getItem('sessionToken'),
          },
        },
      )
      const json = await response.json()

      setData(json)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (url && onLoad) {
      refetch()
    }
  }, [onLoad])

  return { data, loading, error, refetch }
}
