import React, { useState, useRef, useEffect } from 'react'
import { Layout } from '../components/Layout'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'

const galleries = [
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755222/portfolio/242393610_1248648472316641_7906886452745841135_n_o6ys42.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755221/portfolio/242395276_1248647855650036_7264276206112240118_n_w5kp40.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755221/portfolio/242393924_1248647878983367_6984911388950393594_n_lj8536.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755220/portfolio/242401868_1248648095650012_2305412663776311328_n_fe81bo.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755219/portfolio/242396275_1248648172316671_2868420947176834971_n_sluwds.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755217/portfolio/242395949_1248648435649978_40768931533533232_n_zp8hko.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755206/portfolio/242395950_1248647948983360_3143699813606605862_n_dftfa6.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755204/portfolio/242411088_1248648202316668_3103556881423851664_n_wvpggq.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755201/portfolio/314500503_583565960239569_991366116539634753_n_qjmvxu.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755195/portfolio/254119361_2953913331498474_5088459350673368956_n_yw4jil.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755191/portfolio/245651801_2255015487972945_2759385250984327534_n_dasiy1.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755191/portfolio/245651801_2255015487972945_2759385250984327534_n_dasiy1.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755191/portfolio/261666657_1987171964793507_3909127005042805106_n_xjgm3k.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755190/portfolio/245866726_2252405104900650_2978979346482026197_n_qi8frb.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755186/portfolio/262719538_598623114694409_7362895582642423043_n_a6ewkc.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755185/portfolio/254250767_428920302237598_2440006485879594945_n_bhwxrn.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755181/portfolio/262687888_976657286258098_4129588294553673112_n_eqwf22.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755177/portfolio/262609782_201085782170563_201734201209820024_n_g3y6zh.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755176/portfolio/262726177_387042579837018_5645341962431390657_n_ya2sag.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755169/portfolio/314964779_585308576731974_8401089914574873006_n_nybgo8.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975355/event/IMG_1785_jbp7rs.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975342/event/IMG_1795_xilh3a.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975326/event/IMG_1824_gizpee.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942046/fiancaille/6R3A8213_krmf30.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942052/fiancaille/IMG_6513_vrsnf3.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679942058/fiancaille/IMG_6622_wrpuwt.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679975802/formation/241100391_1600394810291775_3583336108117441963_n_rmrsb1.jpg',
]

const Portfolio = () => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const portfolioRef = useRef(null)

  return (
    <Layout>
      <Container ref={portfolioRef}>
        <Title>nos réalisations</Title>
        {isOpen && (
          <Lightbox
            mainSrc={galleries[photoIndex]}
            nextSrc={galleries[(photoIndex + 1) % galleries.length]}
            prevSrc={
              galleries[(photoIndex + galleries.length - 1) % galleries.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + galleries.length - 1) % galleries.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleries.length)
            }
            reactModalStyle={{ width: 300, height: 300, objectFit: 'cover' }}
          />
        )}
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}
          style={{ padding: 25 }}
        >
          <Masonry columnsCount={3} gutter="10px">
            {galleries.map((image, i) => (
              <>
                <img
                  key={i}
                  src={image}
                  style={{
                    width: '100%',
                    display: 'block',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  data-aos="zoom-in"
                  data-aos-delay={i * 100}
                  alt=""
                  onClick={() => setIsOpen(true)}
                />
              </>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`
const Title = styled.h2`
  text-align: center;
  text-transform: capitalize;
  margin-top: 50px;
  font-family: 'JosefinSlab';
  letter-spacing: 1px;
  font-size: 35px;
  font-weight: 500;
`

export default Portfolio
