import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { Button as MantineButton } from '@mantine/core'

const Button = ({ children, type = 'submit', ...props }) => {
  return (
    <div>
      <Btn type={type} {...props}>
        {children}
      </Btn>
    </div>
  )
}

const Btn = styled(MantineButton).attrs((props) => props)`
  border: 2px solid #000;
  text-transform: uppercase;
  padding: 14px 40px;
  letter-spacing: 3px;
  font-weight: 600;
  background: ${(props) => (props.variant === 'outlined' ? '#fff' : '#00081c')};
  transition: 0.3s;
  color: ${(props) => (props.variant === 'outlined' ? '#000' : '#fff')};
  letter-spacing: 2.4px;
  font-family: 'JosefinSlab';
  font-weight: 600;
  ${down('md')} {
    font-size: 10px;
  }
  &:hover {
    background: ${(props) => (!props.noHover ? '#fff' : '#00081c')};
    color: ${(props) => (!props.noHover ? '#00081c' : '#fff')};
    cursor: pointer;
  }
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  height: 50px;
  border-radius: 0;
`

export default Button
