import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import 'rc-input-number/assets/index.css'
import { IconX } from '@tabler/icons-react'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal, openModal } from '../store/actions/modal.action'
import { useNavigate, createSearchParams } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  fetchProductsInCart,
  updateProductInCart,
} from '../store/actions/cart.action'
import { useAuthContext } from '../context/AuthContext'
import { Divider, LoadingOverlay } from '@mantine/core'
import { getStorage } from '../utils/encrypt'
import CartItem from './CartItem'
import Button from '../components/common/Button'
import NoProduct from '../icons/NoProduct'
import { useSessionStorage } from '../hooks/useSessionStorage'

const Cart = (props) => {
  const dispatch = useDispatch()
  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'cart'
      ? state.modal.openModal.value
      : false,
  )
  const cartProduct = useSelector((state) => state.cart.cart)
  const isLoading = useSelector((state) => state.cart.isLoading)
  const ref = useRef(null)
  const navigate = useNavigate()
  const { isLoggedIn } = useAuthContext()
  const { setItem } = useSessionStorage()
  const cart = getStorage('cart')

  useEffect(() => {
    AOS.init({
      once: true,
      easing: 'ease-in-out',
    })
    if (cart) {
      dispatch(fetchProductsInCart(cart))
    }
  }, [])

  const onChange = (quantity, product) => {
    if (quantity > product.availableStock) {
      dispatch(
        openModal({
          type: 'widget_banner',
          extra: {
            availableStock: product.availableStock,
            productTitle: product.title,
          },
        }),
      )
    } else {
      dispatch(
        updateProductInCart({
          productId: product._id,
          quantity,
          cart,
        }),
      )
    }
  }

  const processCheckout = () => {
    if (!isLoggedIn) {
      dispatch(closeModal({ type: 'cart' }))
      navigate({
        pathname: '/auth',
        search: createSearchParams({
          returnUrl:
            window.location.protocol +
            '//' +
            window.location.host +
            '/checkout',
        }).toString(),
      })
    } else {
      navigate('/checkout')
    }
  }

  return (
    <ModalContainer openModal={modalStatus}>
      <CartDrawer>
        <div style={{ padding: 30 }}>
          <LoadingOverlay
            visible={isLoading}
            overlayBlur={1}
            loaderProps={{ size: 'md', color: '#333', variant: 'bars' }}
          />
          <Head>
            <h3
              style={{
                fontFamily: 'JosefinSlab',
                fontSize: 15,
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              your cart{' '}
              <span style={{ fontSize: 13, fontFamily: 'JosefinSlab' }}>
                ({(cartProduct && cartProduct?.totalQuantity) || 0} produits)
              </span>
            </h3>
            <Close>
              <IconX
                color="gray"
                onClick={() => dispatch(closeModal({ type: 'cart' }))}
              />
            </Close>
          </Head>
          <CartFooter>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                width: '100%',
                right: 0,
                padding: 25,
                bottom: 70,
              }}
            >
              <h4>Sous-total :</h4>
              <h4>
                {cartProduct?.totalPrice?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                &nbsp;Ar
              </h4>
            </div>
            {cartProduct.items?.length !== 0 && (
              <>
                <CheckoutWrapper>
                  <Button
                    variant={'outlined'}
                    onClick={() => {
                      navigate('/cart')
                      dispatch(closeModal({ type: 'cart' }))
                    }}
                  >
                    voir le panier
                  </Button>
                  &nbsp;&nbsp;
                  <Button onClick={processCheckout} noHover={true}>
                    commander
                  </Button>
                </CheckoutWrapper>
              </>
            )}
          </CartFooter>
          {cartProduct && cartProduct.length !== 0 ? (
            <div>
              {cartProduct.items.map((cartProduct, key) => (
                <CartItem
                  ref={ref}
                  key={key}
                  cartProduct={cartProduct}
                  onChange={(value) => onChange(value, cartProduct.products)}
                />
              ))}
            </div>
          ) : (
            <p
              style={{
                marginTop: 25,
                textTransform: 'uppercase',
                letterSpacing: 1,
              }}
            >
              Your cart is empty
            </p>
          )}
        </div>
      </CartDrawer>
    </ModalContainer>
  )
}
const CartDrawer = styled.div.attrs((props) => props)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #fff;
  width: 480px;
  transition: 0.3s;
  z-index: 99999;
`
const ModalContainer = styled.div.attrs((props) => props)`
  height: 100vh;
  width: 100vw;
  backdrop-filter: ${(props) => (props.openModal ? 'blur(6px)' : '')};
  background-color: ${(props) => (props.openModal ? 'rgba(0,0,0,0.2)' : '')};
  z-index: ${(props) => (props.openModal ? 9999 : 0)};
  position: ${(props) => (props.openModal ? 'fixed' : 'absolute')};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  visibility: visible;
  transition: 0.3s;
  ${CartDrawer} {
    transform: ${(props) =>
      props.openModal ? 'translateX(-3%)' : 'translateX(100%)'};
  }
`
const CartFooter = styled.div``
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Close = styled.a``

const CheckoutWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 25px;
`
const Checkout = styled.button`
  background-color: #00081c;
  color: #fff;
  padding: 18px 22px;
  border-radius: 0px;
  font-family: 'JosefinSlab';
  font-size: calc(1rem * 0.8);
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  border: none;
`
const TotalPrice = styled.div`
  &:before {
    content: '';
    height: 2px;
    width: 2px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 2px 10px;
  }
`
const SubTotal = styled.div``
export default Cart
