import { Group } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { FETCH_ACTIVE_FILTERS } from '../../store/types'
import { removeFilter } from '../../store/actions/filter.action'

const ActiveFilter = ({ onRemove, data, keys }) => {
  const removeFilter = () => onRemove(keys, data)
  return (
    <Filter onClick={removeFilter}>
      {data} <IconX size={15} style={{ marginLeft: 10 }} color="gray" />
    </Filter>
  )
}

const ActiveFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filter.filters)

  useEffect(() => {
    dispatch({
      type: FETCH_ACTIVE_FILTERS,
      payload: searchParams.entries(),
    })
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      {Object.values(filters).map((filter, index) => (
        <Group grow>
          {Array.isArray(filter) && Object.keys(filters)[index] !== 'prices' ? (
            filter.map((f) => (
              <ActiveFilter
                onRemove={(name, value) => {
                  dispatch(removeFilter({ name, value }))
                }}
                keys={Object.keys(filters)[index]}
                data={f}
              />
            ))
          ) : (
            <ActiveFilter
              onRemove={(name, value) => {
                if (name === 'prices') {
                  searchParams.delete('filter.v.price.gte')
                  searchParams.delete('filter.v.price.lte')
                  setSearchParams(searchParams)
                }
                dispatch(removeFilter({ name, value }))
              }}
              keys={Object.keys(filters)[index]}
              data={filter}
            />
          )}
        </Group>
      ))}
      {/* {Object.keys(filters).length > 0 && (
        <Filter
          onClick={() => dispatch(removeAllFilter())}
          style={{ height: 30, marginTop: 3 }}
        >
          clear all <IconX size={15} style={{ marginLeft: 10 }} color="gray" />
        </Filter>
      )} */}
    </div>
  )
}

const Filter = styled.a`
  font-size: 10px;
  font-family: 'Futura';
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 15px;
  border: 1px solid #dddddd;
  color: #333;
  padding: 5px 15px;
  display: flex;
  width: auto;
  cursor: pointer;
`
export default ActiveFilters
