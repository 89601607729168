import React, { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import { Col, Row } from 'react-grid-system'
import { Stepper, Group, Divider } from '@mantine/core'
import NewOrder from './NewOrder'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { IconArrowLeft } from '@tabler/icons-react'
import Button from '../../components/common/Button'
import { openCartModal, openModal } from '../../store/actions/modal.action'
import PaymentMethod from './PaymentMethod'
import ConfirmOrder from './ConfirmOrder'
import ProcessPayment from './ProcessPayment'

const Checkout = () => {
  const [active, setActive] = useState(0)
  const [orderRef, setOrderRef] = useState(null)
  const dispatch = useDispatch()
  const cartItems = useSelector((state) => state.cart.cart)

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current))

  return (
    <Layout withSlider={false}>
      <Container className="checkout-container">
        {cartItems.length === 0 ? (
          <img
            src="/images/no-cart.jpeg"
            width={'100%'}
            style={{ marginTop: 50 }}
          />
        ) : (
          <Row style={{ marginTop: 120, marginBottom: 50 }}>
            <Col md={7}>
              <h2
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  letterSpacing: 3,
                  fontFamily: 'JosefinSlab',
                }}
              >
                Santikà Mariage
              </h2>
              <>
                <Stepper
                  style={{ marginTop: 20 }}
                  active={active}
                  onStepClick={setActive}
                  breakpoint="sm"
                >
                  <Stepper.Step
                    label="Information"
                    disabled={!localStorage.getItem('userInfo')}
                  >
                    <NewOrder nextStep={nextStep} />
                  </Stepper.Step>
                  <Stepper.Step
                    label="Paiement"
                    disabled={!localStorage.getItem('paymentMethodType')}
                  >
                    <PaymentMethod nextStep={nextStep} />
                  </Stepper.Step>
                  <Stepper.Step
                    label="Validation de la commande"
                    disabled={!localStorage.getItem('paymentMethodType')}
                  >
                    <ProcessPayment
                      nextStep={nextStep}
                      orderRef={(value) => setOrderRef(value)}
                    />
                  </Stepper.Step>
                  <Stepper.Completed label="Validation de la commande">
                    <ConfirmOrder orderRef={orderRef} />
                  </Stepper.Completed>
                </Stepper>
                <Group position="apart" mt="xl">
                  <a
                    href="#"
                    onClick={() => dispatch(openModal())}
                    style={{ fontFamily: 'Futura', letterSpacing: 1 }}
                  >
                    <IconArrowLeft style={{ paddingTop: 5 }} /> Retour au panier
                  </a>
                </Group>
              </>
            </Col>
            <Col md={5}>
              <CartItems>
                {cartItems?.items?.map((item, index) => (
                  <>
                    <ProductItem key={index}>
                      <ProductImage>
                        <Image
                          src={item.products.productImage}
                          width={85}
                          height={85}
                          style={{
                            borderRadius: 5,
                            position: 'relative',
                            objectFit: 'cover',
                          }}
                        />
                      </ProductImage>
                      <div style={{ marginLeft: 20 }}>
                        <Name>{item.products.title}</Name>
                        <ProductType>
                          {item.products.productType === 'custom'
                            ? 'Produit sur commande'
                            : ''}
                        </ProductType>
                        <span
                          style={{ fontSize: 13, fontFamily: 'JosefinSlab' }}
                        >
                          {item.totalProductQuantity} x
                          <span style={{ marginLeft: 5 }}>
                            {item.products.newPrice.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </span>
                          Ar
                        </span>
                      </div>
                    </ProductItem>
                  </>
                ))}
                <Divider style={{ marginTop: 50, marginBottom: 15 }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'JosefinSlab',
                    marginBottom: 5,
                  }}
                >
                  <p>Quantité total</p>
                  <p style={{ fontSize: 13, fontWeight: 600 }}>
                    {cartItems?.totalQuantity}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'JosefinSlab',
                  }}
                >
                  <p>Sous total</p>
                  <p style={{ fontSize: 13, fontWeight: 600 }}>
                    {cartItems?.totalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}{' '}
                    Ar
                  </p>
                </div>
                <Divider style={{ marginTop: 20, marginBottom: 15 }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'JosefinSlab',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                      letterSpacing: 2,
                    }}
                  >
                    TOTAL
                  </p>
                  <p style={{ fontSize: 15, fontWeight: 600 }}>
                    {cartItems?.totalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}{' '}
                    Ar
                  </p>
                </div>
              </CartItems>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    padding: 0 15px;
  }
`
const CartItems = styled.div`
  @media screen and (max-width: 800px) {
    margin-top: 15px;
  }
  margin-top: 150px;
  border: 1px solid #d0d5dd;
  padding: 0px 25px 15px;
  border-radius: 6px;
  background: #fdfdfe;
`
const ProductItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
`
const ProductImage = styled.div`
  position: relative;
  float: left;
`
const Image = styled.img``
const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
  font-family: 'JosefinSlab';
`
const ProductType = styled.div`
  font-size: 13px;
  font-family: 'Futura';
  margin-top: 5px;
  letter-spacing: 1px;
`
const Qty = styled.div`
  position: absolute;
  top: -10px;
  left: 70px;
  height: 25px;
  width: 25px;
  background: #7f7f7f;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`
export default Checkout
