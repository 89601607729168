import React, { useState } from 'react'
import { Layout } from '../components/Layout'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { Grid, Pagination } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import ProductItem from '../components/ProductItem'
import Sorting from '../components/Products/Sorting'
import ActiveFilters from '../components/Products/ActiveFilters'
import Filter from '../components/Products/Filter'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { fetchProducts } from '../store/actions/products.action'
import { useMediaQuery } from '@mantine/hooks'

const Collections = () => {
  const products = useSelector((state) => state.products.products)
  const isProductFilterLoading = useSelector(
    (state) => state.products.isProductFilterLoading,
  )
  const [searchParams, _] = useSearchParams()
  const dispatch = useDispatch()
  const smallScreen = useMediaQuery('(max-width: 800px)')

  useEffect(() => {
    dispatch(fetchProducts())
  }, [])
  console.log(products)
  const [price, setPrice] = useState([
    searchParams.get('filter.v.price.gte') || 0,
    searchParams.get('filter.v.price.lte') || 125000,
  ])

  return (
    <Layout withSlider={false}>
      <Row
        style={{
          marginTop: 100,
          padding: smallScreen ? '50px' : '50px 150px',
          borderTop: '1px solid #f5f5f5',
        }}
      >
        <Col md={12}>
          <Title>nouvelle collection</Title>
          <Caption>
            Découvrez sur cette page les nouveaux produits mis en ligne ces 30
            derniers jours.
          </Caption>
        </Col>
      </Row>
      <Container>
        <Grid>
          <Grid.Col md={2} sm={2} xs={12}>
            <Filter price={price} onChange={(value) => setPrice(value)} />
          </Grid.Col>
          <Grid.Col md={10} pl={20} sm={10} xs={12}>
            {isProductFilterLoading && <Loading />}
            <CollectionAction>
              {!smallScreen && <ActiveFilters />}
              <Sorting />
            </CollectionAction>
            <Row>
              {products.data?.map((product, index) => (
                <ProductItem key={index} {...product} />
              ))}
            </Row>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {products?.data?.length === 0 && (
                <NoResult>
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: 20,
                      letterSpacing: 2,
                    }}
                  >
                    no products found
                  </p>
                  <p style={{ letterSpacing: 1, fontSize: 12 }}>
                    Use fewer filters or <u>clear all</u>{' '}
                  </p>
                </NoResult>
              )}
              {products?.data?.length > 0 && (
                <Pagination
                  total={products.totalPages}
                  color="dark"
                  size="sm"
                  radius="xs"
                  withEdges
                  style={{ marginBottom: 50 }}
                  onChange={(page) =>
                    dispatch(fetchProducts(`page=${page}`, true))
                  }
                />
              )}
            </div>
          </Grid.Col>
        </Grid>
      </Container>
    </Layout>
  )
}

const NoResult = styled.div`
  font-family: 'Futura';
  text-align: center;
  margin-top: 50px;
`
const Title = styled.h1`
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Futura';
  font-size: 20px;
`
const Caption = styled.p`
  font-family: 'Futura';
  letter-spacing: 1px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
`
const Container = styled.div`
  @media only screen and (max-width: 770px) {
    margin: 0 25px;
  }
  @media only screen and (max-width: 1170px) {
    margin: 0 25px;
  }
  margin: 0 150px 50px 150px;
`
const CollectionAction = styled.div`
  display: flex;
  justify-content: space-between;
`
const Loading = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 9999;
  position: absolute;
  top: 0;
`
export default Collections
