import { Button, Group, Select, Text, TextInput } from '@mantine/core'
import {
  IconServicemark,
  IconStatusChange,
  IconPlus,
  IconDownload,
  IconSearch,
} from '@tabler/icons-react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modal.action'

const Filter = ({ onSearch, query = '', type = 'employee', children }) => {
  const dispatch = useDispatch()

  return (
    <Group mt="lg" mb="xs">
      <Text weight={500}>Rechercher:</Text>
      <TextInput
        placeholder="eg: rakoto"
        icon={<IconSearch size={20} />}
        radius={'xs'}
        value={query}
        onChange={onSearch}
      />
      <Select
        radius={'xs'}
        placeholder="Filtrer par service"
        data={[
          'Decoration',
          'Mise en beauté',
          'Sonorisation',
          'Carteries',
          'Photos & Vidéos',
        ]}
        icon={<IconServicemark />}
        transitionProps={{ transition: 'pop', duration: 200 }}
      />
      <Select
        radius={'xs'}
        data={['Actif', 'Sans emploi']}
        transitionProps={{ transition: 'pop', duration: 200 }}
        icon={<IconStatusChange />}
        placeholder="Filtrer par status"
      />
      <Button color="dark">Filtrer</Button>
      <Group style={{ position: 'absolute', right: 15 }}>
        {children}
        <Button
          color="dark"
          onClick={() => dispatch(openModal({ type: 'leave' }))}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <IconDownload size={20} style={{ marginRight: 5 }} />
          Créer
        </Button>
        <Button color="dark" style={{ display: 'flex', justifyContent: 'end' }}>
          <IconDownload size={20} style={{ marginRight: 5 }} />
          Exporter
        </Button>
      </Group>
    </Group>
  )
}

export default Filter
