import React, { useState } from 'react'
import styled from 'styled-components'
import Cart from './Cart'
import { up, down } from 'styled-breakpoints'
import Slider from './Slider'
import Footer from '../pages/partials/_footer'
import { IconCircleCaretDown, IconMenu2 } from '@tabler/icons-react'
import Mobile from './Menu/Mobile'
import { useDispatch } from 'react-redux'
import { openModal } from '../store/actions/modal.action'
import { useTranslation } from 'react-i18next'
import IconFrance from './icons/IconFrance'
import IconUSA from './icons/IconUSA'
import Banner from './Banner'

export const Layout = ({ children, withBg = false, withSlider = true }) => {
  const dispatch = useDispatch()
  const [openLocaleDropdown, setOpenLocaleDropdown] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(<IconUSA />)
  const { i18n } = useTranslation()

  const changeLocale = (flag, locale) => {
    setSelectedLocale(flag)
    setOpenLocaleDropdown(false)
    i18n.changeLanguage(locale)
  }

  return (
    <div style={{ background: withBg ? '#f7f3f3' : '' }}>
      <Cart />
      <Topbar>
        <Adress>Antaninandro Ampandrana, Madagascar</Adress>
        <Container>
          <Contact>+261 34 15 092 27 / +261 34 04 248 42</Contact>
          <Lang>
            <SelectedLang
              onClick={() => setOpenLocaleDropdown(!openLocaleDropdown)}
            >
              {selectedLocale}{' '}
              <IconCircleCaretDown size={15} style={{ marginLeft: 5 }} />{' '}
            </SelectedLang>
            <LangDropdown isOpen={openLocaleDropdown}>
              <ul style={{ listStyle: 'none' }}>
                <LangItem onClick={() => changeLocale(<IconFrance />, 'fr')}>
                  <a href="#">
                    <IconFrance />
                  </a>
                </LangItem>
                <LangItem onClick={() => changeLocale(<IconUSA />, 'en')}>
                  <a href="#">
                    <IconUSA />
                  </a>
                </LangItem>
              </ul>
            </LangDropdown>
          </Lang>
        </Container>
      </Topbar>
      <Mobile />
      <MobileMenu>
        <Logo>
          <span>santikà</span>
        </Logo>
        <Humburger onClick={() => dispatch(openModal({ type: 'menu' }))}>
          <IconMenu2 />
        </Humburger>
      </MobileMenu>
      <Slider withSlider={withSlider} />
      <main>{children}</main>
      <Banner />
      <Footer />
    </div>
  )
}

const Topbar = styled.div`
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${up('md')} {
    flex-direction: row;
  }
  ${down('md')} {
    flex-direction: column;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  padding: 0 50px;
  border-bottom: 1px solid gray;
`
const MobileMenu = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 999;
  display: none;
  background: #fff;
  transition: 0.3s;
  width: 100%;
  justify-content: space-between;
  padding: 0 18px;
  @media (max-width: 911px) {
    display: flex;
  }
  @media (max-width: 767px) {
    top: 45px;
  }
`
const Logo = styled.div`
  color: #333;
  font-weight: 500;
  font-family: 'JosefinSlab';
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
  text-align: center;
  padding: 8px 0px;
  ${(props) =>
    !props.isOpen
      ? `
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 600;
    letter-spacing: 5px;
  `
      : ''}
`
const Humburger = styled.div`
  margin-top: 8px;
  cursor: pointer;
`
const Adress = styled.div`
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
`
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Contact = styled.div`
  font-size: 13px;
`
const Lang = styled.div`
  margin-left: 15px;
  overflow: hidden;
  ${down('md')} {
    display: none;
  }
`
const SelectedLang = styled.p`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
`
const LangDropdown = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: absolute;
  z-index: 99999;
  margin-top: 7px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  transition: 0.5s;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`
const LangItem = styled.li`
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 2px 10px;
  &:hover {
    cursor: pointer;
    background: #eeeeee;
  }
`
const Menu = styled.ul`
  margin-top: 50px;
  list-style none;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: ${(props) => (props.isOpen === false ? 0 : 1)};
  transition: .3s;  
`
const Item = styled.li`
  padding: 5px 0px;
`
const Link = styled.a`
  text-transform: uppercase;
  font-size: 14px;
  font-family: JosefinSlab;
  cursor: pointer;
  letter-spacing: 1px;
`
