import React from 'react'
import { useDispatch } from 'react-redux'
import { ActionIcon, Button, Popover, Badge, Table } from '@mantine/core'
import { IconEye, IconFileDownload, IconTrash } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { openConfirmModal } from '../../../utils/openConfirmModal'
import { useDisclosure } from '@mantine/hooks'
import { deleteSalary } from '../../../store/actions/salary.action'
import { openModal } from '../../../store/actions/modal.action'

const Salary = ({ salary, key }) => {
  const [opened, { close, open }] = useDisclosure(false)

  const dispatch = useDispatch()

  const openDeleteModal = (id) => {
    openConfirmModal({
      content:
        "Cette action est si importante que vous devez la confirmer avec un modal. Veuillez cliquer sur l'un de ces boutons pour continuer.",
      onConfirm: () => dispatch(deleteSalary(id)),
    })
  }

  return (
    <tr>
      <td
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {salary.employee.name}
        <Popover
          position="left"
          withArrow
          shadow="md"
          opened={opened}
          key={key}
        >
          <Popover.Target>
            <Button variant="unstyled" onMouseEnter={open} onMouseLeave={close}>
              <IconEye />
            </Button>
          </Popover.Target>
          <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
            <Table>
              <tr>
                <td>
                  <strong>Prénom:</strong>
                </td>
                <td>{salary.employee.lastname}</td>
              </tr>
              <tr>
                <td>
                  <strong>CIN:</strong>
                </td>
                <td>{salary.employee.cin}</td>
              </tr>
              <tr>
                <td>
                  <strong>Service:</strong>
                </td>
                <td>{salary.employee.department}</td>
              </tr>
              <tr>
                <td>
                  <strong>Email:</strong>
                </td>
                <td>{salary.employee.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Status:</strong>
                </td>
                <td>
                  {' '}
                  <Badge>{salary.employee.status}</Badge>
                </td>
              </tr>
            </Table>
          </Popover.Dropdown>
        </Popover>
      </td>
      <td>
        <Button
          radius={'xs'}
          size="sm"
          color="pink"
          compact
          onClick={() =>
            dispatch(
              openModal({
                type: 'salary',
                extra: { employee: salary.employee },
              }),
            )
          }
        >
          Calculer
        </Button>
      </td>
      <td>{salary.employee.numero}</td>
      <td>{salary.month}</td>
      <td>{salary.total}</td>
      <td>{salary.attendanceBonus}</td>
      <td>{salary.eventBonus}</td>
      <td>{salary.penalties}</td>
      <td style={{ display: 'flex' }}>
        <ActionIcon
          color="red"
          variant="filled"
          mr={3}
          onClick={() => openDeleteModal(salary._id)}
        >
          <IconTrash size="1.125rem" />
        </ActionIcon>
        <ActionIcon color="dark" variant="filled">
          <Link to={`/admin/hr/employee/${salary._id}`}>
            <IconFileDownload size="1.125rem" />
          </Link>
        </ActionIcon>
      </td>
    </tr>
  )
}

export default Salary
