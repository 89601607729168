import { Badge, Container, Group } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PdfDownloader from '../../components/PdfDownloader'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { IconFileInvoice } from '@tabler/icons-react'

const Invoice = () => {
  const [orderItems, setOrderItems] = useState([])
  const [order, setOrder] = useState([])
  const [total, setTotal] = useState(0)
  const { ref } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    const fetchInvoice = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}orders?ref=${ref}`,
      )
      setOrderItems(data[0].orderItems)
      setOrder(data[0])

      setTotal(
        data[0].orderItems.reduce((total, cartItem) => {
          return total + cartItem.item.totalProductPrice
        }, 0),
      )
    }
    fetchInvoice()
  }, [])

  return (
    <div>
      <Container style={{ fontFamily: 'JosefinSlab' }}>
        <div id="testId" style={{ padding: 50 }}>
          <div className="header">
            <img src="/images/santika.png" width={70} alt="" />
            <Company>Santikà</Company>
          </div>
          <Group position="apart">
            <div>
              <Label>{t('invoice.text1')}</Label>
            </div>
            <div>
              <Badge
                style={{ background: !order.paid ? '#C50E82' : '' }}
                size="xl"
                variant="filled"
              >
                <span style={{ color: '#FFF' }}>
                  Facture {!order.paid ? 'non' : ''} payé
                </span>
              </Badge>
            </div>
          </Group>
          <About>
            <strong>Hi Karolina,</strong>
            <p>
              {t('invoice.text2')} <br /> {t('invoice.text3')}
            </p>
          </About>
          <DeliveryAddress>
            <div>
              <Title>
                <u>{t('invoice.text10')}</u>
              </Title>
              <p>
                {order.name} {order.lastname}
              </p>
              <p>{order.email}</p>
              <p>{order.address}</p>
              <p>{order.zipCode}</p>
              <p>{order.postalCode}</p>
            </div>
            <div>
              <h4>
                {t('invoice.text4')} n° <u>{order.ref}</u>
              </h4>
              <h4>{t('invoice.text5')}</h4>
              <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
            </div>
          </DeliveryAddress>
          <Item>
            <h4>{t('invoice.text6')}</h4>
            <hr style={{ marginTop: 12 }} />
            <OrderItems>
              {orderItems &&
                orderItems.map((orderItem, index) => (
                  <div style={{ display: 'flex', marginTop: 10 }}>
                    <div>
                      <Image
                        src={orderItem.item.products.productImage}
                        width={80}
                      />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      <Name>{orderItem.item.products.title}</Name>
                      <Quantity>
                        Quantity: {orderItem.item.totalProductQuantity}
                      </Quantity>
                      <p>
                        Total:{' '}
                        {orderItem.item.totalProductPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          },
                        )}
                        Ar{' '}
                      </p>
                    </div>
                  </div>
                ))}
            </OrderItems>
            <hr style={{ marginTop: 50 }} />
            <div>
              <Group position="apart" mt={15}>
                <span>
                  <strong>{t('invoice.text7')}</strong>
                </span>
                <span style={{ textTransform: 'capitalize' }}>
                  {order.paymentMethod}
                </span>
              </Group>
              <Group position="apart">
                <span>
                  <strong>{t('invoice.text8')}</strong>
                </span>
                <strong>
                  {total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  Ar
                </strong>
              </Group>
              <Group position="apart">
                <span>
                  <strong>{t('invoice.text9')}</strong>
                </span>
                <span>Free</span>
              </Group>
            </div>
          </Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PdfDownloader downloadFileName="CustomPdf" rootElementId="testId" />
        </div>
      </Container>
    </div>
  )
}

const Company = styled.span`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 12px;
`
const Label = styled.h3`
  margin-top: 25px;
  font-size: 25px;
  font-weight: 600;
`
const About = styled.div`
  margin-top: 23px;
  font-size: 16px;
`
const DeliveryAddress = styled.div`
  margin-top: 23px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`
const Title = styled.h4`
  font-weight: 600;
`
const Item = styled.div`
  margin-top: 50px;
`
const OrderItems = styled.div`
  margintop: 25px;
`
const Image = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
`
const Name = styled.span``
const Quantity = styled.span`
  display: block;
`
export default Invoice
