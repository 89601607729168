import { createStyles, Text, SimpleGrid, Container, rem } from '@mantine/core'
import {
  IconTruck,
  IconCertificate,
  IconCoin,
  IconTruckDelivery,
} from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'

const useStyles = createStyles((theme) => ({
  feature: {
    position: 'relative',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: 'absolute',
    height: rem(100),
    width: rem(160),
    top: 0,
    left: 0,
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor,
    }).background,
    zIndex: 1,
  },

  content: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
  },

  icon: {
    color: '#00000',
    marginRight: 10,
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: 'Futura',
    letterSpacing: 1,
  },
}))

function Feature({ icon: Icon, title, description, className, ...others }) {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.feature, className)} {...others}>
      <div className={classes.content}>
        <Icon size={30} className={classes.icon} stroke={1.5} />
        <Text fw={700} fz="sm" mb="xs" mt={5} className={classes.title}>
          {title}
        </Text>
      </div>
    </div>
  )
}

const mockdata = [
  {
    icon: IconTruckDelivery,
    title: 'Free Worldwide shipping',
  },
  {
    icon: IconCertificate,
    title: 'Best Quality Product',
  },
  {
    icon: IconCoin,
    title: 'Very Affordable Pricing',
  },
]

export function Features() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />)
  const smallScreen = useMediaQuery('(max-width: 800px)')

  return (
    <div
      style={{
        background: '#f5f5f5',
        marginTop: 100,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 250px',
      }}
    >
      {!smallScreen && items}
    </div>
  )
}
