import React from 'react'
import Management from '../../Containers/Management'
import Filter from '../../../../components/HRManagement/Filter'
import Payroll from '../../../../components/HRManagement/Payroll/Payroll'
import { Card, Text } from '@mantine/core'

const Absence = () => {
  return (
    <div>
      <Management>
        <Filter />
        <Card padding="lg" radius="md" mt={10} className="hr-management">
          <Card.Section
            style={{
              background: 'rgb(34, 139, 230)',
              color: '#fff',
              padding: 8,
              fontWeight: 600,
            }}
          >
            <Text align="center">Gestion de pointage</Text>
          </Card.Section>
          <Payroll />
        </Card>
      </Management>
    </div>
  )
}

export default Absence
