import React, { useEffect, useState } from 'react'
import Layout from '../Containers/Layout'
import { Text, Card, Table, Button } from '@mantine/core'
import { useGetQuery } from '../../../hooks/useGetQuery'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { usePostQuery } from '../../../hooks/usePostQuery'

const Products = (props) => {
  const { data: products, refetch } = useGetQuery('products')
  const { sendRequest } = usePostQuery('products', 'DELETE')

  const onDelete = async (productId) => {
    await sendRequest({ id: productId })
    refetch()
  }

  const onUpdate = async (productId) => {
    window.location.href =
      '/santika/admin/products/createOrEdit?id=' + productId
  }

  return (
    <div>
      <Layout>
        <Card padding="lg" radius="md" withBorder>
          <Table
            style={{
              textTransform: 'uppercase',
              letterSpacing: 1,
              color: '#66686a',
            }}
          >
            <thead>
              <tr>
                <th>Image</th>
                <th>Nom</th>
                <th>Caption</th>
                <th>Prix</th>
                <th>Stock disponible</th>
                <th>Categorie</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products?.data.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={product.productImage}
                        width={50}
                        height={50}
                        style={{ objectFit: 'cover', borderRadius: 3 }}
                        alt=""
                      />
                    </td>
                    <td style={{ fontSize: 12 }}>{product.title}</td>
                    <td>{product.caption}</td>
                    <td>{product.newPrice}</td>
                    <td>{product.availableStock}</td>
                    <td>{product.category?.name}</td>
                    <td>
                      <Button
                        size={'xs'}
                        color="dark"
                        onClick={() => onUpdate(product._id)}
                      >
                        <IconPencil size={20} />
                        &nbsp; Modifier
                      </Button>
                      &nbsp;
                      <Button
                        size={'xs'}
                        variant="default"
                        onClick={() => onDelete(product._id)}
                      >
                        <IconTrash size={20} />
                        &nbsp; Supprimer
                      </Button>
                      &nbsp;
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
      </Layout>
    </div>
  )
}

export default Products
