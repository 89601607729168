import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store/store'
import { Notifications } from '@mantine/notifications'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import AuthProvider from './components/AuthProvider/AuthProvider'
import './global.css'
import './chat.css'
import './i18n'
import { BrowserRouter } from 'react-router-dom'
import { NavigationProgress } from '@mantine/nprogress'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={{
          colors: {
            brand: [
              '#F0BBDD',
              '#ED9BCF',
              '#EC7CC3',
              '#ED5DB8',
              '#F13EAF',
              '#F71FA7',
              '#FF00A1',
              '#E00890',
              '#C50E82',
              '#AD1374',
            ],
          },
          fontFamily: 'Rubik',
        }}
      >
        <AuthProvider>
          <Notifications position="bottom-center" className="notification" />
          {/* <BrowserRouter
            basename={
              localStorage.getItem('i18nextLng')
                ? localStorage.getItem('i18nextLng')
                : '/fr'
            }
          > */}
          <BrowserRouter>
            <ModalsProvider>
              <NavigationProgress />
              <App />
            </ModalsProvider>
          </BrowserRouter>
        </AuthProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
