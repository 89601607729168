import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'
import thunk from 'redux-thunk'

const REDUX_PERSIST_KEY = '__state'
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(REDUX_PERSIST_KEY, serializedState)
  } catch (e) {
    console.warn(e)
  }
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_PERSIST_KEY)
    if (!serializedState) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

const store = createStore(reducers, loadState(), applyMiddleware(thunk))
store.subscribe(() => saveState(store.getState()))

export default store
