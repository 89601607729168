import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  SimpleGrid,
  rem,
} from '@mantine/core'
import { Layout } from '../components/Layout'
import Button from '../components/common/Button'
import { IconArrowLeft, IconArrowLeftCircle } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
    marginTop: 150,
  },

  title: {
    fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,
    fontFamily: `JosefinSlab, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}))

const NotFound = () => {
  const { classes } = useStyles()

  return (
    <Layout withSlider={false}>
      <Container className={classes.root}>
        <SimpleGrid
          spacing={80}
          cols={2}
          breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}
        >
          <Image src={'/images/notFound.svg'} className={classes.mobileImage} />
          <div>
            <Title className={classes.title}>
              Quelque chose ne tourne pas rond...
            </Title>
            <Text
              color="dimmed"
              size="lg"
              style={{ fontFamily: 'JosefinSlab' }}
            >
              La page que vous essayez d'ouvrir n'existe pas. Vous avez
              peut-être mal tapé l'adresse ou la page a été déplacée vers une
              autre URL. Si tu pensez qu'il s'agit d'une erreur, contactez
              l'assistance.
            </Text>
            <Button style={{ marginTop: 15 }}>Revenir à l'accueil</Button>
          </div>
          <Image
            src={'/images/notFound.svg'}
            className={classes.desktopImage}
          />
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export default NotFound
