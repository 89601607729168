import {
  ADD_PRODUCT_TO_WISHLIST,
  FETCH_PRODUCT_TO_WISHLIST,
  REMOVE_PRODUCT_TO_WISHLIST,
} from '../types'

export const fetchProductToWishlist = () => async (dispatch) => {
  dispatch({
    type: FETCH_PRODUCT_TO_WISHLIST,
  })
}

export const addProductToWishlist = (productId) => async (dispatch) => {
  dispatch({
    type: ADD_PRODUCT_TO_WISHLIST,
    payload: productId,
  })
}

export const removeProductToWishlist = (productId) => async (dispatch) => {
  dispatch({
    type: REMOVE_PRODUCT_TO_WISHLIST,
    payload: productId,
  })
}
