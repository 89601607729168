import React, { useRef, useEffect } from 'react'
import Slider from '../components/Slider'
import { Layout } from '../components/Layout'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { up, down, between } from 'styled-breakpoints'

const About = () => {
  const aboutRef = useRef(null)

  return (
    <Layout>
      <Row ref={aboutRef}>
        <Col sm={12} md={6} lg={6} style={{ padding: 0 }}>
          <LeftSection data-aos="zoom-in-right">
            <Title>Qui sommes-nous?</Title>
            <Description>
              En 2013, étant encore célibataire, notre fondatrice a su que les
              futurs mariés ont du mal à confier leurs projets à des inconnus.
              Certes, les portfolios des prestataires en disaient beaucoup, mais
              prévoir la qualité des prestations lors du jour J fait surgir une
              grande incertitude. En ayant déjà eu des expériences en matière de
              carteries et de décoration, c’était à son tour de se marier.
            </Description>
          </LeftSection>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: 0 }}>
          <Image
            src="https://res.cloudinary.com/dkqcgiblz/image/upload/v1679910001/about/6R3A6238_yftgtq.jpg"
            alt=""
          />
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: 0 }}>
          <Image
            src="https://res.cloudinary.com/dkqcgiblz/image/upload/v1679911539/about/242395950_1248647948983360_3143699813606605862_n_dftfa6_xprr4j.jpg"
            alt=""
          />
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: 0 }}>
          <RightSection data-aos="zoom-in-left">
            <Title>
              Comment avoir un très beau mariage avec les moyens du bord tout en
              étant sûr de la qualité?
            </Title>
            <Description>
              La fondatrice a elle-même expérimenté de nombreuses difficultés
              pour la préparation de son mariage, en se posant la question
              suivante : le prestataire serait-il capable de reproduire mon
              souhait pour le jour J? N’ayant pas pu trouver le wedding planner
              idéal, elle a tout organisé dans les moindres détails. En ayant
              fait de son mariage un mariage « cobaye », elle a compris que
              d’autres futurs mariés pourront bien avoir besoin de son aide.
            </Description>
            <Description>
              Chercher une personne de confiance, pouvant vous comprendre et
              vous accompagner tout au long des préparatifs, c’est comme
              chercher une perle rare. Cette personne doit être en même temps
              connaître vos goûts, quelqu’un en qui vous pouvez compter. Toutes
              les caractéristiques de ce genre de personne ont été retenues et
              devenues les engagements de Santikà! Santikà est désormais cette
              personne décrite, conciliant à la fois l’expertise et la passion
              de toute son équipe.
            </Description>
          </RightSection>
        </Col>
      </Row>
    </Layout>
  )
}

const LeftSection = styled.div`
  height: 486px;
  width: 100%;
  text-align: left;
  background: #f4feff !important;
  position: relative;
  padding: 150px;
  @media (max-width: 1182px) {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`
const RightSection = styled.div`
  height: 486px;
  width: 100%;
  text-align: left;
  background: #fff4f4 !important;
  position: relative;
  padding: 80px;
  @media (max-width: 1678px) {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
`
const Image = styled.img`
  width: 100%;
  height: 486px;
  @media (max-width: 1678px) {
    height: 100%;
  }
  object-fit: cover;
`
const Title = styled.div`
  color: #c09e6f;
  font-size: 25px;
  font-weight: 400;
  font-family: 'JosefinSlab';
`

const Description = styled.p`
  font-family: 'JosefinSlab';
  margin-top: 15px;
  line-height: 25px;
`

export default About
