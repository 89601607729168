import React, { useEffect, useRef, useState } from 'react'
import { Grid, Group, Alert, Text, TextInput } from '@mantine/core'
import Button from '../../components/common/Button'
import { useForm } from '@mantine/form'
import axios from 'axios'
import { IconAlertCircle } from '@tabler/icons-react'

const PersonalInfo = ({ user, step }) => {
  const [messageFlash, setMessageFlash] = useState('')
  const nodeRef = useRef(null)

  const form = useForm({
    initialValues: {
      username: '',
      lastname: '',
      email: '',
      phone: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
  })

  useEffect(() => {
    form.setValues(user)
  }, [])

  const handleSubmit = async (data) => {
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}users/update/${user._id}`,
      { ...data },
    )
    setMessageFlash('User updated')
  }

  return (
    <div style={{ maxWidth: 500, margin: '0 auto' }}>
      <Text fw={600} style={{ fontSize: 25, marginBottom: 20 }}>
        Personal information
      </Text>
      {messageFlash && (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="cyan"
          radius="xs"
          withCloseButton
          mb={10}
        >
          {messageFlash}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(handleSubmit)} method="post">
        <TextInput
          label="Username"
          size="md"
          radius={'xs'}
          {...form.getInputProps('username')}
        />
        <TextInput
          mt={10}
          label="Lastname"
          radius={'xs'}
          size="md"
          {...form.getInputProps('lastname')}
        />
        <TextInput
          mt={10}
          label="Email"
          radius={'xs'}
          size="md"
          {...form.getInputProps('email')}
        />
        <TextInput
          mt={10}
          label="Phone"
          radius={'xs'}
          size="md"
          {...form.getInputProps('phone')}
        />
        <Group grow>
          <TextInput
            mt={10}
            radius={'xs'}
            label="Birth day"
            size="md"
            {...form.getInputProps('birthDay')}
          />
          <TextInput
            mt={10}
            radius={'xs'}
            label="Birth month"
            size="md"
            {...form.getInputProps('birthMonth')}
          />
          <TextInput
            mt={10}
            radius={'xs'}
            label="Birth year"
            size="md"
            {...form.getInputProps('birthYear')}
          />
        </Group>
        <Button style={{ marginTop: 15 }}>Save</Button>
      </form>
    </div>
  )
}

export default PersonalInfo
