import * as React from 'react'
const IconOrder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M90.5 20.7a83.79 83.79 0 0 0-63 47.7c-4.7 10-6.5 17.5-7.2 29.6-2.8 50.8 41.2 92.3 91.4 86.1 38.5-4.7 67.6-33.7 72.5-72.3 5-40-21.6-78.9-61.2-89.3-6.4-1.6-26.5-2.8-32.5-1.8zm27 22.9c21.3 5.2 39.8 24.5 44.4 46.2 1.4 6.7 1.4 18.6 0 25.4-3.5 16.9-17 34-32.9 41.6-9.9 4.7-16.3 6.2-26.5 6.2-18.5 0-35.5-8.1-47.3-22.6-9-11-13.3-23.4-13.3-38.4-.1-30.3 22.7-56 53.1-60 6.6-.9 14.4-.4 22.5 1.6z" />
    <path d="M392.7 116.2c-5.4 1.5-11.2 7.3-12.7 12.6-1 4-.7 7.6 1.1 14 .3.9-2.3 2-8.1 3.6-42.3 11.3-72.6 47-75.8 89.6l-.7 8.4-3.7.9c-8.5 2-11.6 11.5-5.9 17.9l2.9 3.3 15.4.5 15.4.5-18.7 18.2-18.6 18.2-29.5-43.7c-25.7-38.2-30-44-33.7-46.1-8.5-4.7-24.6-7.7-49.8-9.2-19.8-1.1-22.5-.7-25.7 4.4-.7 1.2-10.5 40.8-21.7 87.9-11.1 47.2-20.4 85.6-20.5 85.5-.2-.2-9.3-38.3-20.4-84.7-11-46.5-20.7-86-21.5-87.8-2.5-5.5-6.1-6.4-21.7-5.1-7.3.5-18 1.7-23.7 2.5C2 209.4 0 210.8 0 218.6c0 6.7.6 8.1 4.3 10 2.6 1.4 4.2 1.5 12.6.3 5.3-.7 13.4-1.3 18-1.3l8.4-.1 24.2 102c13.3 56.1 24.5 103 24.9 104.2 2 6.1 11.9 8.5 17.1 4 2.4-2 4.2-8.8 22.1-83.7 10.6-44.8 21.9-92 25-104.9l5.6-23.3 9.2.6c14.7 1.1 24.7 2.5 31.6 4.6l6.5 2 32.3 47.8c21.4 31.8 33.1 48.3 34.8 49.1 1.4.6 4.1 1.1 6 1.1 3.8 0 3 .7 52.8-47.3l17.4-16.7 35.2.2 35.3.3-19.4 5.8c-10.7 3.1-21.9 7-24.9 8.5-4.9 2.5-9.3 7.2-42.8 46.2-20.5 23.9-39.8 46.8-42.8 50.7-6.3 8.5-10 10.9-13.8 9.1-1.4-.6-15.2-15.4-30.6-32.9-24.8-28.1-28.5-31.9-31.7-32.5-5-.9-10 1.6-11.9 6.1-1.2 2.9-1.4 14.6-1.4 73V471H5.3l-2.7 2.6c-3.6 3.7-3.7 11-.2 15.1l2.4 2.8H220l2.7-2.8 2.8-2.7.3-62 .2-62 16.4 18.3c23.1 25.9 26.8 28.7 38.1 29.5 12.8.8 18.5-3.3 39-28.3 4.9-6.1 22.1-26.3 38.1-45.1 33.2-38.6 30-36.1 52.5-42.4 33.3-9.3 46.8-14.9 54-22.4l4.4-4.6h18c15.1 0 18.6-.3 21.5-1.8 3.4-1.7 3.5-1.8 3.8-8.5.3-7.9-.4-9.2-5.8-10.8l-3.7-1.1-.6-8.3c-2.4-34.3-24.3-66.6-55.7-82.5-7.6-3.8-17.5-7.3-25.4-9-5.3-1.1-5.5-1.2-4.6-3.6 1.8-4.5 1.2-11.8-1.3-16.5-3.9-7.3-13.4-11.1-22-8.7zm27.2 50.9c33 8.4 56.5 36.3 60.6 71.6l.7 6.3H318v-3.4c0-1.9.6-6.7 1.4-10.8 6.7-34.7 33.6-60.3 69.1-65.7 8-1.3 22.2-.4 31.4 2z" />
  </svg>
)
export default IconOrder
