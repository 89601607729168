import React, { useEffect, useState } from 'react'
import {
  useMantineTheme,
  Modal,
  TextInput,
  SimpleGrid,
  FileInput,
  Grid,
  Select,
  Card,
  Image,
  Group,
} from '@mantine/core'
import styled from 'styled-components'
import {
  IconBuildingBank,
  IconCalendar,
  IconCashBanknote,
  IconEPassport,
  IconGenderBigender,
  IconHierarchy2,
  IconHomeLink,
  IconMail,
  IconMap,
  IconMapPinMinus,
  IconNumber,
  IconPhone,
  IconReportMoney,
  IconServicemark,
  IconStatusChange,
  IconUser,
  IconUserPin,
  IconX,
} from '@tabler/icons-react'
import Button from '../../../components/common/Button'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import { DatePickerInput } from '@mantine/dates'
import {
  createEmployee,
  fetchEmployee,
} from '../../../store/actions/employee.action'

const NewEmployee = () => {
  const [previewAvatar, setPreviewAvatar] = useState(false)
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.loader.isLoading)
  const employee = useSelector((state) => state.modal.extra?.employee)

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'employee'
      ? state.modal.openModal.value
      : false,
  )

  useEffect(() => {
    form.setValues({
      ...employee,
      birthday: employee ? new Date(employee.birthday) : null,
      startDate: employee ? new Date(employee.startDate) : null,
      id: employee ? employee._id : null,
    })
  }, [employee])

  const form = useForm({
    initialValues: {
      name: '',
      numero: '',
      birthday: '',
      cin: '',
      email: '',
      department: '',
      status: '',
      bankId: '',
      startDate: '',
      lastname: '',
      birthLocation: '',
      phone: '',
      city: '',
      salary: '',
      iban: '',
      sexe: '',
      city: '',
      address: '',
      familyStatus: '',
      file: '',
      id: '',
    },
  })

  const handleSubmit = async (data) => {
    dispatch(createEmployee(data))

    if (data?.id) {
      dispatch(fetchEmployee())
    }
    // if (!isLoading) {
    //   form.reset()
    //   dispatch(closeModal({ type: 'employee' }))
    // }
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="xxl"
      radius={'xs'}
      className="auth-register employee"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          dispatch(closeModal({ type: 'employee' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>
          {' '}
          {employee?._id ? 'Modifier' : 'Créer'} un employé
        </ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        ></div>
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col md={9}>
              <SimpleGrid cols={2}>
                <div>
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Numero"
                    disabled
                    required
                    {...form.getInputProps('numero')}
                    icon={<IconNumber size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Nom"
                    required
                    {...form.getInputProps('name')}
                    icon={<IconUser size={18} />}
                  />
                  <DatePickerInput
                    mt={10}
                    radius={'xs'}
                    placeholder="Date de naissance"
                    form
                    mx="auto"
                    {...form.getInputProps('birthday')}
                    icon={<IconCalendar size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="CIN"
                    required
                    {...form.getInputProps('cin')}
                    icon={<IconEPassport size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Email"
                    required
                    type="email"
                    {...form.getInputProps('email')}
                    icon={<IconMail size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Adresse"
                    {...form.getInputProps('address')}
                    required
                    icon={<IconHomeLink size={18} />}
                  />
                  <Select
                    placeholder="Service"
                    icon={<IconServicemark />}
                    mt={10}
                    data={['Mise en bauté', 'Décoration', 'Carteries', 'Resto']}
                    required
                    {...form.getInputProps('department')}
                  />

                  <Select
                    placeholder="Banque"
                    icon={<IconBuildingBank size={18} />}
                    mt={10}
                    data={[
                      'BOA Madagascar',
                      'BNI Madagascar',
                      'BFV Madagascar',
                      'BMOI',
                    ]}
                    required
                    {...form.getInputProps('bankId')}
                  />
                </div>
                <div>
                  <DatePickerInput
                    placeholder="Date de début"
                    mt={10}
                    radius={'xs'}
                    mx="auto"
                    {...form.getInputProps('startDate')}
                    icon={<IconCalendar size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Prénom"
                    required
                    {...form.getInputProps('lastname')}
                    icon={<IconUserPin size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Lieu de naissance"
                    required
                    {...form.getInputProps('birthLocation')}
                    icon={<IconMapPinMinus size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Téléphone"
                    required
                    {...form.getInputProps('phone')}
                    icon={<IconPhone size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Ville"
                    required
                    {...form.getInputProps('city')}
                    icon={<IconMap size={18} />}
                  />
                  <TextInput
                    withAsterisk
                    mt={10}
                    size={'md'}
                    radius={'xs'}
                    name="username"
                    variant="default"
                    placeholder="Salaire"
                    required
                    {...form.getInputProps('salary')}
                    icon={<IconReportMoney size={18} />}
                  />
                  <Select
                    placeholder="Status"
                    icon={<IconStatusChange size={18} />}
                    mt={10}
                    data={['En activité', 'Sans emploi']}
                    required
                    {...form.getInputProps('status')}
                  />
                  <Select
                    placeholder="Status familial"
                    icon={<IconHierarchy2 size={18} />}
                    mt={10}
                    data={['Celibataire', 'Marié', 'Divorcé', 'Veuf']}
                    required
                    {...form.getInputProps('familyStatus')}
                  />
                </div>
              </SimpleGrid>
            </Grid.Col>
            <Grid.Col md={3}>
              <Card padding="lg" radius="xs" mt={10}>
                <Card.Section
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 10,
                  }}
                >
                  <Image
                    src={
                      previewAvatar
                        ? previewAvatar
                        : 'https://soma-tech.com/hrv1/uplode/img/user.png'
                    }
                    height={120}
                    width={120}
                    alt="Norway"
                  />
                </Card.Section>
                <Group position="apart" mt="md" mb="xs">
                  <FileInput
                    label="Uploader une photo"
                    placeholder="Upload files"
                    accept="image/png,image/jpeg"
                    {...form.getInputProps('file')}
                    // onChange={(file) =>
                    //   setPreviewAvatar(URL.createObjectURL(file))
                    // }
                  />
                  <Select
                    placeholder="Sexe"
                    icon={<IconGenderBigender />}
                    data={[
                      {
                        value: 'femme',
                        label: 'Je suis une femme',
                      },
                      {
                        value: 'homme',
                        label: 'Je suis un homme',
                      },
                    ]}
                    required
                    {...form.getInputProps('sexe')}
                  />
                </Group>
              </Card>
              <Button fullWidth noHover={true} mt={15} loading={isLoading}>
                {employee?._id ? 'Modifier' : 'Créer'}
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
  padding: 0px 10px 25px 10px;
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default NewEmployee
