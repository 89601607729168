import { createStyles, Avatar, Text, Group } from '@mantine/core'
import {
  IconPhoneCall,
  IconAt,
  IconMap,
  IconMapPin,
  IconWorld,
} from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}))

export function Author({ avatar, name, title, phone, email }) {
  const { classes } = useStyles()
  return (
    <div>
      <Group noWrap>
        <Avatar src={avatar} size={35} radius="md" />
        <div>
          <Group noWrap spacing={5} mt={3}>
            <IconAt stroke={1.5} size="1rem" className={classes.icon} />
            <Text fz="xs" c="dimmed">
              {email}
            </Text>
          </Group>
          <Group noWrap spacing={5} mt={5}>
            🇲🇬
            <Text fz="xs" c="dimmed">
              {phone}
            </Text>
          </Group>
        </div>
      </Group>
    </div>
  )
}
