import React from 'react'
import { Layout } from '../components/Layout'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row } from 'react-grid-system'
import ProductItem from '../components/ProductItem'

const Wishlist = () => {
  const products = useSelector((state) => state.products.products)
  const wishlist = useSelector((state) => state.wishlist.wishlist)

  const getProductsFromWishlist = () => {
    return products?.data.filter((product) => wishlist.includes(product.slug))
  }

  return (
    <Layout withSlider={false}>
      <Container>
        <p
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: '1.5rem',
            letterSpacing: '3px',
            fontFamily: 'Futura',
            fontWeight: 600,
          }}
        >
          Mes favoris <br />
          <span
            style={{
              fontSize: 14,
              textTransform: 'lowercase',
              fontFamily: 'Futura',
            }}
          >
            {getProductsFromWishlist().length} produits
          </span>
        </p>
        <Row style={{ maxWidth: 1300, margin: '0 auto' }}>
          {getProductsFromWishlist().map((product, index) => (
            <ProductItem key={index} {...product} />
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1200px;
  margin: 150px auto;
  font-family: 'JosefinSlab';
`
export default Wishlist
