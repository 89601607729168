import * as React from 'react'
const IconData = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={70}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M171.1 1.5C102.7 8.6 49.3 28.3 20.9 56.9 11.4 66.5 6.1 74.8 2.4 85.6L0 92.9v157.8c0 175.2-.5 161.7 6.7 175.4 19.2 36.8 76.6 63.5 158.3 73.6 20.7 2.5 79.2 2.5 100 0 28.2-3.4 36.2-5.1 39.4-8.4 3.7-3.6 3.7-10.9.1-14.6-3.8-3.7-6.3-3.9-19.1-1.7-23.2 4-40.6 5.3-69.9 5.3-57.5 0-105.4-9.2-144.1-27.7-23.8-11.5-39.5-24.3-46.4-38.1l-3.5-7-.3-22c-.2-12.1-.2-24.2 0-27l.3-4.9 5.5 4.9c27 24 78.3 42.1 139 49 27.8 3.2 86.1 2.9 101.5-.5 7.8-1.7 11.5-9.1 8-15.9-2.6-5.1-5.7-5.6-23.8-4-21.6 1.8-58.2 1.6-77.7-.5-80.7-8.5-141.4-36.8-151.4-70.6-1.3-4.4-1.6-10.8-1.6-35.2v-29.9l6.3 5.4c30.2 25.6 81 43 144.2 49.4 21.8 2.2 66.4 2.1 88-.1 63.7-6.6 117.5-25.4 145.2-50.9l4.3-4v14.2c0 8.5.4 15.1 1.1 16.4 3.3 6.4 11.6 7.7 17.1 2.8l3.3-2.9.3-88.4c.2-61.8 0-90.7-.8-96.2-2.3-15.9-8.3-27.1-21.4-40.1C381.5 29.7 335.3 11.6 272 2.9 251.8.2 192.2-.7 171.1 1.5zM244 22c46.9 3.5 87.9 13.5 118.5 28.7 19.9 10 36.4 24.2 42.6 36.8 3 6.2 3.4 7.9 3.4 15 0 11.1-3.9 19-14.4 29.5-24.8 24.6-71.5 42-131.9 49.2-23.4 2.8-67.1 3-89.9.5-74.9-8.4-131.9-33.3-147.3-64.3-3-6.1-3.4-7.8-3.5-14.9 0-7.2.4-8.8 3.4-15C41 54.8 105.8 28.1 183.5 22.1c16-1.2 44.9-1.3 60.5-.1zM49.9 169.4c32 18.3 79.5 30.8 132 34.7 20.5 1.5 65.4.7 84.1-1.5 58.6-7 106.1-23.6 135.2-47.3l7.9-6.5-.3 31.9-.3 31.8-3.1 6.5c-15.7 33.2-80.1 59.8-159.8 66.1-18.9 1.5-60.3.6-78.6-1.6-47.7-5.8-88.1-18.5-115-36-11.1-7.2-22.9-19.5-27.1-28l-3.4-7-.3-31.8-.3-31.8 9.1 7.2c4.9 3.9 13.9 9.9 19.9 13.3z" />
    <path d="m483.9 302.9-2.9 2.9v33.5l-6.7-6.5c-15.9-15.2-33.8-23.6-55-25.7-43.2-4.4-84.8 19.5-103.3 59.3-12.9 27.7-12.7 59.3.5 87.3 5.4 11.4 10.5 18.7 19.8 28.2 8.6 8.8 16.4 14.5 27.2 19.9 17.1 8.5 27.6 10.6 50.2 10 16.3-.4 17.1-.6 28.1-4.3 31.5-10.6 55.2-34.3 65.7-65.7 3.4-10.2 3.8-12.6 4.3-24.3l.4-13-2.7-2.8c-2.3-2.2-3.7-2.7-7.7-2.7-4.1 0-5.4.5-7.7 2.8-2.5 2.4-2.9 3.7-3.5 11.5-3 37.1-26.1 65.4-61.1 74.8-11.2 3-28.8 3-40 0-28.9-7.7-51.7-30.8-58.5-59.2-2.7-11.4-2.7-27.7 0-38.5 2.6-10.2 9.5-24.2 16-32.1 23.2-28.5 64.4-37.7 95.9-21.3 8.5 4.4 19.6 13 23 17.9l2.2 3.1h-19.3c-21.5 0-22.8.3-25.4 6.6-1.9 4.4-.8 8.9 2.8 11.9l3 2.5H502v-73.2l-2.9-2.9c-2.4-2.4-3.8-2.9-7.6-2.9s-5.2.5-7.6 2.9z" />
  </svg>
)
export default IconData
