import React, { useEffect, useState } from 'react'
import Layout from '../Containers/Layout'
import {
  Badge,
  Card,
  Table,
  Button,
  Text,
  LoadingOverlay,
  Select,
} from '@mantine/core'
import axios from 'axios'
import _ from 'lodash'
import { IconCalendar, IconPlus } from '@tabler/icons-react'
import IncomingStock from './IncomingStock'
import { openModal } from '../../../store/actions/modal.action'
import { useDispatch } from 'react-redux'
import { DateInput } from '@mantine/dates'
import moment from 'moment'
import IconData from '../../../components/icons/IconData'
import PreviewInvoice from './invoice/PreviewInvoice'

const Inventory = () => {
  const [inventory, setInventory] = useState(null)
  const [designation, setDesignation] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [articles, setArticles] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    fetchLogisticsInventory()
    fetchArticles()
  }, [])

  const fetchLogisticsInventory = async (date = '', article = '') => {
    setLoading(true)
    let url = `${process.env.REACT_APP_BASE_URL}logistics/inventory`
    if (date) {
      url += `?date=${date}`
    }
    if (article) {
      url += `?article=${article}`
    }
    const { data } = await axios.get(url)
    setLoading(false)
    setInventory(_.groupBy(data, 'designation'))
  }

  const fetchArticles = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}articles?page=0`,
    )
    setArticles(
      data.data.map((article) => article.name).filter((article) => article),
    )
  }

  const calculateQtyDispo = (inventories) => {
    let total = 0,
      qtyInitial = 0,
      sumRest = 0
    inventories.map((inventory, index) => {
      let rest = 0
      qtyInitial = inventory.qtyInitial
      rest += qtyInitial - (inventory.outgoingItem - inventory.incomingItem)
      sumRest += qtyInitial - rest
    })
    return qtyInitial - sumRest
  }

  const incomingStock = (designation) => {
    setDesignation(designation)
    dispatch(openModal({ type: 'incomingStock' }))
  }

  const onFilterInventory = (value) => {
    const date = moment(value).format('YYYY-MM-DD')
    fetchLogisticsInventory(date)
  }

  const onFilterArticle = (value) => {
    fetchLogisticsInventory('', value)
  }

  const onPreviewInvoice = async (invoiceId) => {
    dispatch(
      openModal({ type: 'preview_invoice_logistics', extra: { invoiceId } }),
    )
  }

  return (
    <Layout>
      <PreviewInvoice />
      <IncomingStock
        designation={designation}
        onCallback={fetchLogisticsInventory}
      />
      <Card padding="lg" radius="md" withBorder className="logistics">
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={1.5}
          transitionDuration={500}
          loaderProps={{ variant: 'bars', color: 'dark' }}
        />
        <div
          style={{
            marginLeft: 5,
            marginBottom: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Text fw={600} fz={25} variant="h1">
              Mouvement de stock
            </Text>
            <Text variant="p">
              Aperçu direct des quantités disponible pour chaque article pour la
              date du {moment().format('DD/MM/YYYY')}
            </Text>
          </div>
          <div style={{ display: 'flex' }}>
            <Select
              mx="auto"
              size="md"
              placeholder="Filtrer par article"
              searchable
              data={articles}
              transitionProps={{
                transition: 'scale',
                timingFunction: 'ease',
              }}
              withinPortal
              onChange={onFilterArticle}
            />
            <DateInput
              placeholder="Filtrer par date"
              size="md"
              onChange={onFilterInventory}
              icon={<IconCalendar />}
              maw={400}
              mx="auto"
            />
          </div>
        </div>
        <div>
          {inventory && Object.keys(inventory).length === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <IconData />
              <Text align="center" mt={10}>
                Aucun résultat pour ce date
              </Text>
            </div>
          ) : (
            <div>
              {inventory &&
                Object.keys(inventory).map((designation, key) => (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <span>{designation}</span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <Table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Facture</th>
                          <th>Qte initiale</th>
                          <th>Sortie</th>
                          <th>Entree</th>
                          <th>Inventaire physique sortie</th>
                          <th>Inventaire physique entree</th>
                          <th>Observation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inventory[designation].map((item, index) => (
                          <tr>
                            <td>{item.date}</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => onPreviewInvoice(item.invoice)}
                                style={{ borderBottom: '1px dotted #333' }}
                              >
                                FACT N°{item.invoice}
                              </a>
                            </td>
                            <td>{item.qtyInitial}</td>
                            <td>{item.outgoingItem}</td>
                            <td>{item.incomingItem || 0}</td>
                            <td>------------</td>
                            <td>------------</td>
                            <td>----------------------</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={9}>
                            <div style={{ float: 'right' }}>
                              Qte disponible calculer:{' '}
                              <Badge color="dark" variant="filled">
                                {calculateQtyDispo(inventory[designation])}
                              </Badge>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={9}>
                            <div style={{ float: 'right' }}>
                              Qte disponible constatee: ___
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={9}>
                            <div style={{ float: 'right' }}>
                              <Button
                                variant="default"
                                onClick={() => incomingStock(designation)}
                              >
                                <IconPlus />
                                &nbsp; Rendre un article
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ))}
            </div>
          )}
        </div>
      </Card>
    </Layout>
  )
}

export default Inventory
