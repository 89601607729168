import { Text, Container } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ConfirmOrder = ({ orderRef }) => {
  const { t } = useTranslation()

  return (
    <Container style={{ fontFamily: 'JosefinSlab' }}>
      <div style={{ textAlign: 'center' }}>
        <img src="/images/clapping.jpeg" width={150} alt="" />
        <Text fw={600} fz={25} style={{ letterSpacing: 1 }}>
          {t('checkout.text1')}
        </Text>
        <p>{t('checkout.text2')}</p>
        <p>
          Référence du commande : <u>{orderRef}!</u>{' '}
        </p>
        <br />
        <p>
          Consulter la{' '}
          <Link
            to={`/invoice/${localStorage.getItem('orderRef')}`}
            style={{ textDecoration: 'underline' }}
          >
            facture
          </Link>
        </p>
        <p style={{ marginTop: 15 }}>
          <img
            src="/images/santika.png"
            width={50}
            alt=""
            style={{ marginRight: 5 }}
          />
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              letterSpacing: 2,
            }}
          >
            Santikà
          </span>
        </p>
      </div>
    </Container>
  )
}

export default ConfirmOrder
