import {
  ADD_PRODUCT_TO_WISHLIST,
  FETCH_PRODUCT_TO_WISHLIST,
  REMOVE_PRODUCT_TO_WISHLIST,
} from '../types'

const initialState = {
  wishlist: [],
}

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_WISHLIST:
      return {
        ...state,
        wishlist: [
          ...state.wishlist.filter((current) => current !== action.payload),
          action.payload,
        ],
      }
    case REMOVE_PRODUCT_TO_WISHLIST:
      const wishlist = state.wishlist.filter(
        (current) => current !== action.payload,
      )
      return {
        ...state,
        wishlist,
      }
    case FETCH_PRODUCT_TO_WISHLIST:
      return {
        ...state.wishlist,
      }
    default: {
      return state
    }
  }
}

export default wishlistReducer
