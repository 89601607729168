import React, { useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import Button from '../../components/common/Button'
import { useDisclosure } from '@mantine/hooks'
import {
  Modal,
  Button as UIButton,
  Textarea,
  TextInput,
  useMantineTheme,
  SimpleGrid,
  Grid,
} from '@mantine/core'
import { RatingComponent } from 'react-rating-emoji'
import 'react-rating-emoji/dist/index.css'
import ReactStars from 'react-stars'
import { down } from 'styled-breakpoints'
import { useForm } from '@mantine/form'
import parse from 'html-react-parser'
import { notifications } from '@mantine/notifications'
import { usePostQuery } from '../../hooks/usePostQuery'
import { useGetQuery } from '../../hooks/useGetQuery'
import { useTranslation } from 'react-i18next'
import { IconQuote } from '@tabler/icons-react'

const DialogTestimonial = ({ isOpen, hideModal }) => {
  const [rating, setRating] = useState(0)
  const [opened, { close }] = useDisclosure(isOpen)
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { sendRequest, loading } = usePostQuery('reviews', 'POST', false, {
    'Content-Type': 'application/json',
  })

  const form = useForm({
    initialValues: {
      name: '',
      content: '',
    },
  })

  const handleRating = (newRating) => {
    setRating(newRating)
  }

  const handleSubmit = async (values) => {
    if (rating === 0) {
      notifications.show({
        title: 'Notification',
        message: 'Choississez une étoile.',
        color: 'red',
      })
    } else {
      await sendRequest({ ...values, rating, isApproved: false })
      notifications.show({
        title: 'Notification',
        message: 'Merci pour votre avis 😍 !',
      })
      form.reset()
      hideModal(false)
    }
  }

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={close}
        centered
        withCloseButton={false}
        size="sm"
        className="modal"
        transitionProps={{ transition: 'pop', duration: 200 }}
        overlayProps={{
          color:
            theme.colorScheme === 'light'
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 8,
        }}
      >
        <ModalHeader onClick={hideModal}>X</ModalHeader>
        <ModalBody>
          <Inner>
            <ModalTitle>{t('review.modal.title')}</ModalTitle>
            <ModalCaption>{t('review.modal.caption')}</ModalCaption>
            <RatingComponent rating={rating} onClick={handleRating} />
            <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
              <TextInput
                label={t('review.modal.name')}
                withAsterisk
                mt={25}
                name="name"
                {...form.getInputProps('name')}
                style={{ fontFamily: 'JosefinSlab' }}
                required
                radius={'xs'}
              />
              <Textarea
                label={t('review.modal.review')}
                withAsterisk
                style={{ fontFamily: 'JosefinSlab' }}
                mt={10}
                name="content"
                radius={'xs'}
                {...form.getInputProps('content')}
              />
              <UIButton
                mt={10}
                fullWidth
                style={{ background: '#000', fontFamily: 'JosefinSlab' }}
                radius="0"
                type="submit"
              >
                {t('review.modal.button')}
              </UIButton>
            </form>
          </Inner>
        </ModalBody>
      </Modal>
    </>
  )
}

const Testimonials = () => {
  const [isOpen, setOpen] = useState(false)
  const { data, loading } = useGetQuery('reviews')
  const { t } = useTranslation()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Wrapper>
        <Title>{t('review.title')}</Title>
        <Grid>
          {!loading &&
            data &&
            data?.map((review, index) => (
              <Grid.Col md={3}>
                <ReviewItem>
                  <Quote>
                    <IconQuote size={60} color="#f5f5f5" />
                  </Quote>
                  <Author>{review.name}</Author>
                  <Rating>
                    <ReactStars
                      count={5}
                      size={'12px'}
                      value={review.rating}
                      edit={false}
                      color2={'#ffd700'}
                    />
                  </Rating>
                  <Content>{review.content}</Content>
                </ReviewItem>
              </Grid.Col>
            ))}
          <Grid.Col md={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 15,
              }}
            >
              <Button>{t('review.allReview')}</Button>&nbsp;
              <Button onClick={() => setOpen(true)}>
                {t('review.submitReview')}
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </Wrapper>
      <DialogTestimonial isOpen={isOpen} hideModal={() => setOpen(false)} />
    </>
  )
}

const Title = styled.h2`
  font-size: 36px;
  font-family: 'JosefinSlab';
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
`
const Wrapper = styled.div`
  max-width: 1000px;
  margin: 40px auto;
`
const Content = styled.p`
  line-height: 20px;
  font-family: 'JosefinSlab';
  font-size: 14px;
  letter-spacing: 1px;
  font-style: italic;
  ${down('md')} {
    padding: 0 15px;
  }
`
const Author = styled.span`
  font-weight: 600;
  display: block;
  font-size: 12px;
  font-family: 'JosefinSlab';
  text-transform: uppercase;
  letter-spacing: 1.5px;
`
const BtnTestimonial = styled.div`
  display: flex;
  justify-content: center;
  margin: 45px 15px 0px 15px;
`
const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9999;
`
const ModalBody = styled.div`
  position: relative;
  padding: 10px;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-family: JosefinSlab;
  font-weight: 600;
  margin-bottom: 5px;
`
const ModalCaption = styled.p`
  font-family: JosefinSlab;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
`
const Rating = styled.div`
  margin-bottom: 15px;
`
const Inner = styled.div`
  border: 1px solid #eee;
  padding: 15px;
`
const Quote = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`
const ReviewItem = styled.div`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 15px;
`

export default Testimonials
