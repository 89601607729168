import React, { useState, useEffect } from 'react'
import { Layout } from '../../components/Layout'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuthContext } from '../../context/AuthContext'
import { createStyles, Card, rem } from '@mantine/core'
import {
  IconInfoCircle,
  IconLock,
  IconServerCog,
  IconShoppingCartPlus,
  IconUserCircle,
} from '@tabler/icons-react'
import Orders from './Orders'
import PersonalInfo from './PersonalInfo'
import UpdatePassword from './UpdatePassword'
import Request from './Request'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
    fontFamily: 'JosefinSlab',
    fontSize: 23,
  },

  title: {
    lineHeight: 1,
    fontFamily: 'Rubik',
    fontSize: 25,
  },
  orderInfo: {
    fontFamily: 'Rubik',
    lineHeight: 2,
  },
}))

const CustomerArea = () => {
  const [orders, setOrders] = useState([])
  const [step, setStep] = useState(1)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { isLoggedIn, user } = useAuthContext()

  useEffect(() => {
    console.log(isLoggedIn)
  }, [])

  const fetchOrders = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}orders?user=${user?._id}`,
    )
    setOrders(data)
  }

  useEffect(() => {
    fetchOrders()
  }, [user])

  const isActive = (currentStep) => {
    return {
      color: step === currentStep ? '#fd787f' : '',
      fontWeight: step === currentStep ? 600 : '',
      display: 'flex',
      alignItems: 'center',
    }
  }

  return (
    <Layout withSlider={false}>
      <Row
        style={{
          marginTop: 100,
          padding: '50px 100px',
          borderTop: '1px solid #f5f5f5',
        }}
      >
        <Col md={12}>
          <Title>Vos historiques</Title>
          <Caption>
            Découvrez sur cette page l'historiques de vos commandes, vos
            facutres, etc..
          </Caption>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={2}>
            <UserInfo>
              <Avatar>
                <IconUserCircle size={35} color="#ccc" />
              </Avatar>
              <Name>
                {user?.username} {user?.lastname}
              </Name>
              <Email>{user?.email}</Email>
            </UserInfo>
            <List>
              <Item>
                <Link onClick={() => setStep(1)} style={isActive(1)}>
                  <IconInfoCircle style={{ paddingRight: 5 }} /> Vos
                  informations
                </Link>
              </Item>
              <Item>
                <Link onClick={() => setStep(4)} style={isActive(4)}>
                  <IconLock style={{ paddingRight: 5 }} /> Login & Password
                </Link>
              </Item>
              <Item>
                <Link onClick={() => setStep(2)} style={isActive(2)}>
                  <IconShoppingCartPlus style={{ paddingRight: 5 }} /> commandes
                </Link>
              </Item>
              <Item>
                <Link onClick={() => setStep(3)} style={isActive(3)}>
                  <IconServerCog style={{ paddingRight: 5 }} /> Réclamations
                </Link>
              </Item>
            </List>
          </Col>
          <Col md={10}>
            <Card withBorder radius="md" p="xl">
              {step === 1 && <PersonalInfo step={1} user={user} />}
              {step === 2 && <Orders orders={orders} />}
              {step === 4 && <UpdatePassword user={user} />}
              {step === 3 && <Request />}
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const Title = styled.h1`
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Futura';
  font-size: 20px;
`
const Caption = styled.p`
  font-family: 'Futura';
  letter-spacing: 1px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
`
const Container = styled.div`
  margin: 0 150px 50px 150px;
`
const List = styled.ul`
  list-style: none;
`
const Item = styled.li`
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  font-family: 'Futura';
  transition: 0.5s;
  margin-bottom: 15px;
  &:hover {
    font-weight: bold;
  }
`
const UserInfo = styled.div``
const Avatar = styled.div`
  height: 70px;
  width: 70px;
  background: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Name = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
`
const Email = styled.span`
  color: #333;
  margin-bottom: 25px;
  display: block;
`
export default CustomerArea
