import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

const ChatBody = ({ socket, conversation, newMessage, userId }) => {
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const scrollRef = useRef();

  useEffect(() => {
    setMessages([...messages, newMessage]);
  }, [newMessage]);

  useEffect(() => {
    socket.on("getMessages", (data) => {
      setMessages([
        ...messages,
        data
      ])
    });
  }, [newMessage]);

  useEffect(() => {
    const API = process.env.REACT_APP_BASE_URL;
    const fetchMessages = async () => {
      try {
        const { data: messages } = await axios.get(
          `${API}messenger/messages/${conversation?._id}`
        );
        setMessages(messages);
      } catch (err) {
        console.log(err);
      }
    };
    fetchMessages();
  }, [conversation]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div>
      <ul>
        {JSON.stringify(arrivalMessage)}
        {messages &&
          messages.map((message, index) => (
            <li
              className={message.sender === userId ? "replies" : "sent"}
              key={index}
              ref={scrollRef}
            >
              <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
              <p>{message.text}</p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ChatBody;
