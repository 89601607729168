import React from 'react'
import { Select as MantineSelect } from '@mantine/core'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { sortBy } from '../../store/actions/filter.action'
import { useSearchParams } from 'react-router-dom'

const Sorting = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  return (
    <Select
      placeholder="Filter"
      className="sorting"
      variant="unstyled"
      onChange={(value) => dispatch(sortBy(value))}
      defaultValue={searchParams.get('sort_by')}
      data={[
        { value: 'best-selling', label: 'best selling' },
        { value: 'low-to-high', label: 'price, low to high' },
        { value: 'high-to-low', label: 'price, high to low' },
        { value: 'new-to-old', label: 'date, new to old' },
        { value: 'old-to-new', label: 'date, old to new' },
        { value: 'default', label: 'default' },
      ]}
    />
  )
}

const Select = styled(MantineSelect)`
  font-family: 'Futura';
  text-transform: uppercase;
`
export default Sorting
