import { useEffect, useState } from 'react'
import {
  createStyles,
  UnstyledButton,
  Menu,
  Image,
  Group,
  rem,
} from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

const data = [
  { label: 'English', image: '/images/lang/en.png' },
  { label: 'French', image: '/images/lang/fr.png' },
]

const useStyles = createStyles((theme, { opened }) => ({
  control: {
    width: rem(200),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export function SelectLang() {
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened })
  const [selected, setSelected] = useState(data[0])
  const { i18n } = useTranslation()

  useEffect(() => {
    if (localStorage.getItem('i18nextLng') === 'en') {
      setSelected(data[0])
    } else {
      setSelected(data[1])
    }
  }, [])

  const changeLanguage = (item) => {
    let locale = 'fr'
    if (item.label.toLowerCase() === 'english') {
      locale = 'en'
    }
    setTimeout(() => {
      let newUrl
      if (locale === 'fr') {
        newUrl = window.location.pathname.replace(`/en/`, `/${locale}/`)
      } else if (locale === 'en') {
        newUrl = window.location.pathname.replace(`/fr/`, `/${locale}/`)
      }
      window.location.replace(newUrl)
      localStorage.setItem('i18nextLng', locale)
      i18n.changeLanguage(locale)
      setSelected(item)
    }, 500)
  }

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => changeLanguage(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ))

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
      style={{ border: '1px solid #ccc', marginTop: 25 }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Image src={selected.image} width={22} height={22} />
            <span className={classes.label}>{selected.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  )
}
