import { OPEN_MODAL, CLOSE_MODAL, CLOSE_MODAL_PRODUCT } from '../types'

export const openModal = (payload) => async (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload,
  })
}

export const closeModal = (payload) => async (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload,
  })
}

export const closeModalProduct = () => async (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_PRODUCT,
    payload: {
      modalStatus: false,
    },
  })
}
