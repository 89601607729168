import * as React from 'react'
const IconInventory = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M42.5 52.4c-4.2 1.8-10.2 8.8-11.4 13.3-1.5 5.3-1.5 375.3 0 380.6 1.3 4.6 6.7 10.9 11.3 13.1 3.1 1.5 22.8 1.6 213.6 1.6 190.8 0 210.5-.1 213.6-1.6 4.6-2.2 10-8.5 11.3-13.1 1.3-4.4 1.6-127.3.3-127.3-.4 0-5.3 4.6-11 10.3L460 339.5V440H52V72h408v100.5l10.2 10.2c5.7 5.7 10.6 10.3 11 10.3 1.3 0 1-122.9-.3-127.3-1.3-4.6-6.7-10.9-11.3-13.1-3.1-1.5-22.8-1.6-213.8-1.6-171.1.1-210.9.3-213.3 1.4z" />
    <path d="M201.7 113.1c-10.7 2.6-21.8 12.3-25.6 22.4-2.8 7.5-2.8 18.5 0 26 2.9 7.8 11.1 16.4 19.1 20.1l6.3 2.9h109l6.3-2.9c4.3-2 8-4.7 11.6-8.7 6.9-7.4 9.6-14.3 9.6-24.4 0-10.1-2.7-17-9.6-24.4-3.6-4-7.3-6.7-11.6-8.7l-6.3-2.9-52.5-.2c-28.9-.1-54.2.3-56.3.8zM309.4 136c3.8 2.4 7.6 8.7 7.6 12.4 0 3.6-3.3 9.5-6.8 12.1-2.6 1.9-4.3 2-54.2 2-49.9 0-51.6-.1-54.2-2-3.5-2.6-6.8-8.5-6.8-12.1 0-3.7 3.8-10 7.6-12.4 2.8-1.9 5.1-2 53.4-2s50.6.1 53.4 2zM423.2 176.5c-3.6 3-4.7 7.5-2.8 11.8.8 1.8 13.4 15.1 28 29.7l26.6 26.5-91.5.5-91.5.5-2.7 2.8c-2.3 2.3-2.8 3.6-2.8 7.7s.5 5.4 2.8 7.7l2.7 2.8 91.5.5 91.5.5-26.6 26.5c-14.6 14.6-27.2 28-28 29.8-1.8 4-1 7.8 2.4 11.4 2.2 2.3 3.4 2.8 7.6 2.8h5l38.3-38.3 38.3-38.3v-10.8l-38.3-38.3-38.3-38.3h-4.6c-3.5 0-5.4.6-7.6 2.5z" />
  </svg>
)
export default IconInventory
