import { useState } from 'react'
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core'
import { MainLinks } from '../Components/_mainLinks'
import { IconLogout } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Cookies from 'js-cookie'

export default function Layout({ children }) {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const [opened, setOpened] = useState(false)
  const timeout = { enter: 600, exit: 600 }

  const onLogout = () => {
    sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = `/auth`
  }

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Navbar.Section grow mt="xs">
            <MainLinks />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                margin: '0 50px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Rubik',
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  letterSpacing: 1,
                }}
              >
                <img src="/images/santika.png" width={50} alt="" />{' '}
                <span>Santikà Mariage</span>
              </Text>
              <Text
                onClick={onLogout}
                style={{ display: 'flex', cursor: 'pointer' }}
              >
                <IconLogout />
                &nbsp; Se deconnecter
              </Text>
            </div>
          </div>
        </Header>
      }
    >
      <TransitionGroup component="main" classNames="page-main">
        <CSSTransition timeout={timeout} classNames="fade" appear>
          <main className="main-dashboard">{children}</main>
        </CSSTransition>
      </TransitionGroup>
    </AppShell>
  )
}
