import React, { useState } from 'react'
import Layout from '../Containers/Layout'
import { Text, Card } from '@mantine/core'
import { TextEditor } from '../../../components/TextEditor'
import { Input, FileInput, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconSend } from '@tabler/icons-react'
import axios from 'axios'
import { IconCheck } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'

const SendNewsletter = () => {
  const [files, setFiles] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      message: '',
    },
  })

  const handleSubmit = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('message', message)
    if (files && files.length > 0) {
      files.map((file) => formData.append('file', file))
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}newsletters/sendEmail`,
      formData,
    )
    setLoading(false)
    notifications.show({
      title: 'Notification',
      message: data.message,
      color: 'teal',
      icon: <IconCheck />,
    })
    setTimeout(() => {
      window.location.href = '/admin/newsletters'
    }, 1000)
    form.reset()
  }

  return (
    <div>
      <Layout>
        <Text fw={500} fz={25} mb={10} style={{ fontFamily: 'JosefinSlab' }}>
          Envoyer un message
        </Text>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Input.Wrapper
              label="Message"
              maw={'100%'}
              style={{ fontFamily: 'JosefinSlab' }}
              mx="auto"
              withAsterisk
              mt={10}
            >
              <TextEditor content={(value) => setMessage(value)} />
            </Input.Wrapper>
            <FileInput
              mt={10}
              style={{ fontFamily: 'JosefinSlab' }}
              label="Pièce jointe"
              multiple
              onChange={(files) => setFiles(files)}
            />
            &nbsp;
            <Button
              loading={loading}
              loaderPosition="left"
              type="submit"
              mt={10}
            >
              <IconSend /> &nbsp; Envoyer
            </Button>
          </form>
        </Card>
      </Layout>
    </div>
  )
}

export default SendNewsletter
