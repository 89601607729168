import Axios from 'axios'
import { hideLoader, showLoader } from '../store/actions/loader.action'
import { nprogress } from '@mantine/nprogress'

const interceptor = (store) => {
  const TOKEN = sessionStorage.getItem('sessionToken')
  Axios.interceptors.request.use(
    (req) => {
      // you can add some information before send it.
      req.headers['sessionToken'] = TOKEN
      store.dispatch(showLoader())
      nprogress.start()
      return req
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  Axios.interceptors.response.use(
    (next) => {
      store.dispatch(hideLoader())
      nprogress.complete()
      return Promise.resolve(next)
    },
    (error) => {
      // You can handle error here and trigger warning message without get in the code inside
      store.dispatch(hideLoader())
      return Promise.reject(error)
    },
  )
}
export default {
  interceptor,
}
