import axios from 'axios'
import { CREATE_NEW_SALARY, DELETE_SALARY, FETCH_SALARIES } from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchSalaries = (page = 1) => {
  return async (dispatch, getState) => {
    let url = `${API}salaries?page=${page}`
    const { data } = await axios.get(url)
    dispatch({
      type: FETCH_SALARIES,
      payload: data,
    })
  }
}

export const createSalary = (values) => {
  return async (dispatch, getState) => {
    const formValues = Object.keys(values).reduce((formData, key) => {
      formData.append(key, values[key])
      return formData
    }, new FormData())

    try {
      const { data } = await axios.post(`${API}salaries`, formValues)
      dispatch({
        type: CREATE_NEW_SALARY,
        payload: data.data,
      })
    } catch (err) {}
  }
}

export const deleteSalary = (id) => {
  return async (dispatch, getState) => {
    try {
      await axios.delete(`${API}salaries/${id}`)
      dispatch({
        type: DELETE_SALARY,
        payload: { id },
      })
    } catch (err) {}
  }
}
