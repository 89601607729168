import React, { useEffect, useState } from 'react'
import { useMantineTheme, Modal, LoadingOverlay } from '@mantine/core'
import styled from 'styled-components'
import { IconX } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { closeModal } from '../../../../store/actions/modal.action'

const PreviewInvoice = () => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const [invoice, setInvoice] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'preview_invoice_logistics'
      ? state.modal.openModal.value
      : false,
  )
  const invoiceId = useSelector((state) => state.modal.extra?.invoiceId)

  useEffect(() => {
    const previewInvoice = async () => {
      setLoading(true)
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}logistics/invoice/preview?invoiceId=${invoiceId}`,
      )
      setInvoice(data)
      setLoading(false)
    }
    previewInvoice()
  }, [invoiceId])

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="65%"
      radius={'xs'}
      className="auth-register"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          dispatch(closeModal({ type: 'preview_invoice_logistics' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        >
          <ModalTitle>Facture n°{invoiceId}</ModalTitle>
          <LoadingOverlay
            visible={isLoading}
            overlayBlur={1.5}
            transitionDuration={500}
            loaderProps={{ variant: 'bars', color: 'dark' }}
          />
          {!isLoading && (
            <>
              <div dangerouslySetInnerHTML={{ __html: invoice }} />
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default PreviewInvoice
