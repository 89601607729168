import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'

const ProtectedRoute = ({ children, isLoading, isLoggedIn }) => {
  if (isLoading) {
    return <p>Loading</p>
  }
  if (!isLoading && !isLoggedIn) {
    //return <Navigate to="/auth" replace />
  }
  return children
}

export default ProtectedRoute
