import Button from '../../components/common/Button'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'

const Service = () => {
  const { t, ready } = useTranslation()
  const [diaporama, setDiaporama] = useState(null)

  useEffect(() => {
    let i = 0
    window.setInterval(() => {
      i++
      if (i < services.length - 1) i = 0
      setDiaporama(services[i].image)
    }, 1000)
  }, [])

  const services = t('services', { returnObjects: true })

  return (
    <Container>
      <Title>
        Santikà <br />
        {t('label')}
      </Title>
      <Link to={'/portfolio'}>
        <Button variant="outlined">{t('buttonService')}</Button>
      </Link>
      <Row style={{ marginTop: 50 }}>
        {ready &&
          services.map((service, index) => (
            <Column
              sm={6}
              md={4}
              lg={3}
              {...service}
              key={index}
              index={index}
              style={{ padding: 0 }}
              data-aos="zoom-in"
              data-aos-delay={index * 100}
            >
              {index !== 10 && (
                <Overlay index={index}>
                  <Caption index={index}>{service.title}</Caption>
                  <Description index={index}>{service.description}</Description>
                  <Next>
                    <svg
                      version="1.1"
                      id="arrow_icon"
                      x="0px"
                      y="0px"
                      width={50}
                      viewBox="0 0 45.9 20.9"
                      enable-background="new 0 0 45.9 20.9"
                      class="svg replaced-svg"
                    >
                      <g>
                        <path
                          fill="#FFFFFF"
                          d="M35.7,0.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L41.5,9H1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h40.5   l-7.2,7.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l8.9-9c0.4-0.4,0.4-1,0-1.4L35.7,0.3z"
                        ></path>
                      </g>
                    </svg>
                  </Next>
                </Overlay>
              )}
              {index !== 5 && index !== 10 && (
                <img
                  src={service.image}
                  alt={service.title}
                  width="100%"
                  height="100%"
                  placeholderSrc="/images/placeholder.jpeg"
                />
              )}
              {index === 10 && (
                <ImageDiaporama diaporama={diaporama}></ImageDiaporama>
              )}
            </Column>
          ))}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  padding-top: 35px;
`
const Title = styled.h2`
  padding-bottom: 25px;
  font-weight: normal;
  font-style: normal;
  font-size: 26px;
  font-family: 'JosefinSlab';
  letter-spacing: 4px;
  text-transform: uppercase;
`
const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.8s;
  padding: 40px;
  text-align: left;
  visibility: ${(props) => (props.index !== 5 ? 'hidden' : 'visible')};
  transform: ${(props) =>
    props.index !== 5 ? 'translateX(100%)' : 'translateX(0)'};
`
const Column = styled(Col)`
  flex: 25%;
  position: relative;
  &:hover {
    ${Overlay} {
      visibility: visible;
      background: ${(props) => (props.index !== 5 ? props.bgColor : '#fff')};
      transform: translateX(0%);
    }
  }
  @media screen and (max-width: 992px) {
    flex: 50%;
  }
`

const Caption = styled.h2`
  font-size: 26px;
  color: ${(props) => (props.index !== 5 ? '#fff' : '#333')};
  font-weight: normal;
  font-family: 'JosefinSlab';
  text-transform: uppercase;
  letter-spacing: 2.4px;
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 100px;
    margin-top: 10px;
    background: ${(props) => (props.index !== 5 ? '#fff' : '#333')};
  }
  @media (max-width: 1688px) {
    font-size: ${(props) => (props.index === 5 ? '15px' : '')};
    font-weight: ${(props) => (props.index === 5 ? 600 : '')};
  }
`
const Description = styled.p`
  font-size: 17px;
  color: ${(props) => (props.index !== 5 ? '#fff' : '#333')};
  margin-top: 25px;
  font-family: ${(props) => (props.index === 5 ? 'Futura' : 'JosefinSlab')};
  letter-spacing: 1.4px;
  line-height: 25px;
  @media (max-width: 1688px) {
    font-size: ${(props) => (props.index === 5 ? '17px' : '17px')};
    line-height: 25px;
  }
`
const Next = styled.div`
  position: absolute;
  right: 35px;
  bottom: 35px;
`
const ImageDiaporama = styled.div`
  background-position: center;
  background-image: url(${(props) => props.diaporama});
  background-size: cover;
  display: flex;
  height: 100%;
  width: 100%;
  transition: background 0.5s linear;
`

export default Service
