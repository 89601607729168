import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Column } from '../../components/grid/Column'
import { Row } from '../../components/grid/Row'
import {
  IconBrandFacebook,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconChevronRight,
  IconAlertCircle,
  IconPhone,
  IconBrandInstagram,
} from '@tabler/icons-react'
import { Popover, Text, Alert, Divider } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import IconArrowUp from '../../components/icons/IconArrowUp'
import { SelectLang } from '../../components/SelectLang'
import { Author } from '../../components/Author'
import axios from 'axios'

const Footer = () => {
  const newsletterRef = useRef(null)
  const [successNewsletter, setSuccessNewsletter] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const value = newsletterRef.current.value

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}newsletters`,
        {
          email: value,
        },
      )
      if (data.status === 'success') {
        setSuccessNewsletter(true)
        setError(false)
        newsletterRef.current.value = ''
      }
    } catch (err) {
      const status = err.response.data.status
      if (400 === status) {
        setError(true)
        setSuccessNewsletter(false)
      }
    }
  }

  return (
    <div>
      <FooterContainer>
        <NewsletterContainer>
          <Label>
            {t('newsletter.title.partOne')} <br />{' '}
            {t('newsletter.title.partTwo')}
          </Label>
          <FormNewsletter onSubmit={handleSubmit}>
            <Input
              type="email"
              required
              placeholder={t('newsletter.placeholder')}
              ref={newsletterRef}
            />
            <Button type="submit">
              <IconChevronRight />
            </Button>
          </FormNewsletter>
          <div
            class="success-newsletter d-none mt-4"
            style={{
              background: successNewsletter ? '#fff' : '',
              boxShadow: successNewsletter
                ? 'rgb(0 0 0 / 25%) 0px 0px 28px 0px'
                : '',
              padding: successNewsletter ? '2rem' : '',
              maxWidth: 510,
              margin: '10px auto',
            }}
          >
            {successNewsletter && (
              <>
                <span
                  style={{
                    fontSize: '26px',
                    lineHeight: '31.2px',
                    letterSpacing: '6px',
                    textTransform: 'uppercase',
                    color: '#000',
                    marginBottom: '10px',
                    display: 'block',
                    fontWeight: 'normal',
                  }}
                >
                  {t('newsletter.successMsg.thanks')} !
                </span>
                <p>{t('newsletter.successMsg.message')}</p>
              </>
            )}
            {error && (
              <Alert
                icon={<IconAlertCircle size="1rem" />}
                title="Erreur!"
                color="red"
              >
                Cette email exist déjà !
              </Alert>
            )}
          </div>
        </NewsletterContainer>
        <FooterMenu>
          <Row>
            <Column col={3} style={{ textAlign: 'center' }}>
              <FooterMenuTitle>Copyright</FooterMenuTitle>
              <p style={{ fontSize: 13, fontFamily: 'JosefinSlab' }}>
                © Santikà 2023 <br />
                contact@santika.mg
                {/* Design & Development by Max */}
                {/* <Popover
                  width="target"
                  position="bottom"
                  arrowPosition="right"
                  shadow="md"
                >
                  <Popover.Target style={{ marginTop: 5, cursor: 'pointer' }}>
                    <Text>Max</Text>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Author
                      avatar="/images/author.jpeg"
                      name={'Max'}
                      title={'SOFTWARE ENGINEER'}
                      email={'niels.adna@gmail.com'}
                      phone={'033 61 948 82'}
                    />
                  </Popover.Dropdown>
                </Popover> */}
              </p>
            </Column>
            <Column col={3} style={{ textAlign: 'center' }}>
              <FooterMenuTitle>Contact</FooterMenuTitle>
              <p style={{ fontSize: 13, fontFamily: 'JosefinSlab' }}>
                Ampandrana Madagascar, Antananarivo <br />
                <div style={{ marginTop: 5 }}>
                  <IconPhone size={15} /> +261 34 15 092 27 / +261 34 04 248 42
                </div>
                <br />
              </p>
            </Column>
            <Column col={3} style={{ textAlign: 'center' }}>
              <FooterMenuTitle>{t('footer.follow')}</FooterMenuTitle>
              <Social>
                <SocialItem>
                  <IconBrandFacebook />
                </SocialItem>
                <SocialItem>
                  <IconBrandTwitter />
                </SocialItem>
                <SocialItem>
                  <IconBrandYoutube />
                </SocialItem>
                <SocialItem>
                  <IconBrandWhatsapp />
                </SocialItem>
              </Social>
            </Column>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Column col={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>
                  <img
                    src="/images/payment.png"
                    height={45}
                    style={{ marginTop: 15 }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SelectLang />
                  <p
                    style={{ marginTop: 10, fontSize: 13, textAlign: 'center' }}
                  >
                    <Popover
                      width="target"
                      position="bottom"
                      arrowPosition="right"
                      shadow="md"
                      style={{ width: 250 }}
                    >
                      <Popover.Target
                        style={{ marginTop: 5, cursor: 'pointer' }}
                      >
                        <Text>
                          {' '}
                          <a href="javscript:void(0)">
                            Made with{' '}
                            <g-emoji
                              class="g-emoji"
                              alias="heart"
                              fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/2764.png"
                            >
                              <img
                                class="emoji"
                                alt="heart"
                                height="15"
                                width="15"
                                src="https://github.githubassets.com/images/icons/emoji/unicode/2764.png"
                              />
                            </g-emoji>
                            &nbsp;by Max
                          </a>
                        </Text>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Author
                          avatar="/images/author.jpeg"
                          name={'Max'}
                          title={'SOFTWARE ENGINEER'}
                          email={'niels.adna@gmail.com'}
                          phone={'Madagascar'}
                        />
                      </Popover.Dropdown>
                    </Popover>
                  </p>
                </div>
              </div>
            </Column>
          </Row>
        </FooterMenu>
      </FooterContainer>
      <BottomFooter>
        <span>{t('footer.letsgo')}</span>
      </BottomFooter>
      <ScrollTop
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        <IconArrowUp style={{ transform: 'rotate(270deg)' }} />
      </ScrollTop>
    </div>
  )
}

const FooterContainer = styled.div`
  background: #f6f6f6;
  padding: 40px 20px 80px 20px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`
const NewsletterContainer = styled.div`
  text-align: center;
  margin-top: 34px;
`
const Label = styled.span`
  font-size: 26px;
  line-height: 35.2px;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'JosefinSlab';
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`
const FormNewsletter = styled.form`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
const Input = styled.input`
  border-color: #000000 !important;
  font-size: 26px;
  font-weight: 100 !important;
  border-width: 1px;
  padding: 20px 100px;
  @media (max-width: 800px) {
    padding: 20px;
  }
  border-style: solid;
  font-family: 'JosefinSlab';
  font-size: 25px;
  &:focus {
    outline: none;
  }
`
const Button = styled.button`
  height: '100%';
  width: 80px;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
`
const FooterMenu = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`
const FooterMenuTitle = styled.h3`
  font-size: 26px !important;
  font-weight: 700 !important;
  font-family: 'JosefinSlab';
  margin-bottom: 20px;
  margin-top: 25px;
`
const Social = styled.ul`
  display: inline;
  list-style: none;
`
const SocialItem = styled.li`
  display: inline;
  padding: 0 15px;
`
const BottomFooter = styled.div`
  height: 70px;
  background: #000;
  color: #fff;
  line-height: 70px;
  font-size: 18px;
  text-align: center;
  font-family: 'JosefinSlab';
  letter-spacing: 2.4px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
`
const ScrollTop = styled.div`
  height: 60px;
  width: 60px;
  background: #fff;
  position: absolute;
  z-index: 10;
  bottom: 70px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 47%;
  color: #333;
  cursor: pointer;
`
export default Footer
