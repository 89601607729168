import React, { useEffect, useState } from 'react'
import {
  useMantineTheme,
  Modal,
  TextInput,
  SimpleGrid,
  Grid,
  Select,
  Textarea,
} from '@mantine/core'
import styled from 'styled-components'
import { IconX } from '@tabler/icons-react'
import { TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import Button from '../../common/Button'
import { createAttendance } from '../../../store/actions/attendance.action'
import { fetchEmployee } from '../../../store/actions/employee.action'

const NewAttendance = () => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'attendance'
      ? state.modal.openModal.value
      : false,
  )
  const employee = useSelector((state) => state.modal.extra.employee)
  const date = useSelector((state) => state.modal.extra.date)

  useEffect(() => {
    form.setFieldValue('name', employee?.name)
    form.setFieldValue('numero', employee?.numero)
    form.setFieldValue('day', date?.date)
    form.setFieldValue('month', date?.month)
    form.setFieldValue('year', date?.year)
  }, [employee, date])

  const form = useForm({
    initialValues: {
      name: '',
      numero: '',
      day: '15',
      month: '',
      year: '',
      status: '',
      type: '',
      startHour: '',
      endHour: '',
      observation: '',
    },
  })

  const handleSubmit = async (data) => {
    const { day, month, year } = data
    const payload = {
      ...data,
      employeeId: employee?._id,
      date: `${day}/${month}/${year}`,
    }
    dispatch(createAttendance(payload))
    dispatch(fetchEmployee())
  }

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="md"
      radius={'xs'}
      className="auth-register employee"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          dispatch(closeModal({ type: 'attendance' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>Présence des employés</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        ></div>
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col md={12}>
              <div>
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="name"
                  variant="default"
                  disabled
                  {...form.getInputProps('name')}
                />
                <TextInput
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="name"
                  variant="default"
                  disabled
                  {...form.getInputProps('numero')}
                />
                <SimpleGrid cols={3}>
                  <div>
                    <TextInput
                      withAsterisk
                      mt={10}
                      size={'md'}
                      radius={'xs'}
                      name="name"
                      variant="default"
                      style={{ textAlign: 'center' }}
                      {...form.getInputProps('day')}
                      disabled
                    />
                  </div>
                  <div>
                    <TextInput
                      withAsterisk
                      mt={10}
                      size={'md'}
                      radius={'xs'}
                      name="name"
                      className="date"
                      variant="default"
                      style={{ textAlign: 'center' }}
                      {...form.getInputProps('month')}
                      disabled
                    />
                  </div>
                  <div>
                    <TextInput
                      withAsterisk
                      mt={10}
                      size={'md'}
                      radius={'xs'}
                      name="name"
                      variant="default"
                      style={{ textAlign: 'center' }}
                      {...form.getInputProps('year')}
                      disabled
                    />
                  </div>
                </SimpleGrid>
                <Select
                  placeholder="Présence"
                  mt={10}
                  data={['Journée complète', 'Absence']}
                  required
                  {...form.getInputProps('type')}
                />
                <TimeInput
                  withAsterisk
                  mt={10}
                  {...form.getInputProps('startHour')}
                />
                <TimeInput
                  withAsterisk
                  mt={10}
                  {...form.getInputProps('endHour')}
                />
                <Textarea
                  withAsterisk
                  mt={10}
                  size={'md'}
                  radius={'xs'}
                  name="name"
                  variant="default"
                  {...form.getInputProps('observation')}
                />
                <Button mt={6} fullWidth noHover>
                  Valider
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </form>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
  padding: 0px 10px 25px 10px;
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default NewAttendance
