import React from 'react'
import { useMantineTheme, Modal, TextInput, Grid, Table } from '@mantine/core'
import styled from 'styled-components'
import { IconX } from '@tabler/icons-react'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import Button from '../../common/Button'

const UpdateSalary = () => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const employee = useSelector((state) => state.modal.extra?.employee)

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'salary'
      ? state.modal.openModal.value
      : false,
  )

  const form = useForm({
    initialValues: {
      attendanceBonus: '',
      eventBonus: '',
      penalties: '',
    },
  })

  const handleSubmit = async (data) => {}

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="xl"
      radius={'xs'}
      className="auth-register employee"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          form.reset()
          dispatch(closeModal({ type: 'salary' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>ajouter un salaire</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        ></div>
        <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col md={12}>
              <div>
                <Grid>
                  <Grid.Col md={6}>
                    <Table cellpadding="0" cellspacing="0" border="0">
                      <tr>
                        <td>Employé :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            value={employee?.name}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Mois :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            mt={10}
                            {...form.getInputProps('name')}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Table>
                      <tr>
                        <td>Num° :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            disabled
                            value={employee?.numero}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Salaire de base :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            mt={10}
                            value={employee?.salary}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Table>
                      <tr>
                        <td>Prime d'assiduité :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            {...form.getInputProps('attendanceBonus')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Bonus évènements :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            mt={10}
                            {...form.getInputProps('eventBonus')}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Table>
                      <tr>
                        <td>Pénalités et sanctions :</td>
                        <td>
                          <TextInput
                            withAsterisk
                            size={'md'}
                            radius={'xs'}
                            name="name"
                            variant="default"
                            {...form.getInputProps('penalties')}
                          />
                        </td>
                      </tr>
                    </Table>
                  </Grid.Col>
                  <Grid.Col md={12}>
                    <Grid>
                      <Grid.Col md={6}></Grid.Col>
                      <Grid.Col md={6}>
                        <Table>
                          <tbody>
                            <tr>
                              <td>Salaire de base : </td>
                              <td>{employee?.salary}</td>
                            </tr>
                            <tr>
                              <td>Prime d'assiduité (+) : </td>
                              <td
                                style={{ color: '#e74c3c', textAlign: 'right' }}
                              >
                                {employee?.salary}
                              </td>
                            </tr>
                            <tr>
                              <td>Bonus évènements (+) : </td>
                              <td
                                style={{ color: '#e74c3c', textAlign: 'right' }}
                              >
                                {employee?.salary}
                              </td>
                            </tr>
                            <tr>
                              <td>Pénalités et sanctions (-) : </td>
                              <td
                                style={{ color: '#e74c3c', textAlign: 'right' }}
                              >
                                {employee?.salary}
                              </td>
                            </tr>
                            <tr>
                              <td>Total a payé : </td>
                              <td style={{ color: '#e74c3c' }}>
                                <Button noHover>{employee?.salary}</Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>
                <Button mt={10} fullWidth noHover>
                  Valider
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </form>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
  padding: 0px 10px 25px 10px;
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default UpdateSalary
