import React, { useRef } from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { PartnerItem } from '../../components/PartnerItem'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
  };
const Partner = () => {
  const { t, ready } = useTranslation()
  const sliderRef = useRef(null)

  const branches = t('branch.items', { returnObjects: true })

  return (
    <Container>
      <Wrapper>
        <Title>{t('branch.title')}</Title>
        <Caption>{t('branch.description')}</Caption>
      </Wrapper>
      <Row
        style={{
          maxWidth: 1200,
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          position: 'relative'
        }}
      >
        <div className='partner-slider' style={{ display: 'flex', position: 'absolute', zIndex: 9999, top: '50%', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ height: '50px', width: '50px', cursor: 'pointer', marginLeft: '-30px', background: '#fd787f', display: 'flex', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: 2, borderBottomLeftRadius: 2}} onClick={() => sliderRef?.current?.slickPrev()}>
                <svg fill="#fff" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 476.213 476.213" >
                <polygon points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5 
                    57.427,253.107 476.213,253.107 "/>
                </svg>
            </div>
            <div style={{ height: '50px', width: '50px', cursor: 'pointer', marginRight: '-10px', background: '#fd787f', display: 'flex', justifyContent: 'center', alignItems: 'center', borderTopRightRadius: 2, borderBottomRightRadius: 2}} onClick={() => sliderRef?.current?.slickNext()}>
                <svg fill="#fff" height="30px" width="30px" style={{ transform: 'rotate(-180deg)'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 476.213 476.213" >
                <polygon points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5 
                    57.427,253.107 476.213,253.107 "/>
                </svg>
            </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
            {ready &&
                branches.map((branch, index) => (
                <PartnerItem
                    postedAt={'Service traiteur'}
                    title={branch.name}
                    description={branch.description}
                    image={branch.image}
                />
            ))}
        </Slider>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 70px !important;
  padding-bottom: 70px !important;
  background-color: #f7f3f3 !important;
`
const Title = styled.h2`
  font-size: 36px;
  font-family: 'JosefinSlab';
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
`
const Description = styled.div`
  height: 85%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  padding: 25px;
  font-size: 14px;
  font-family: 'JosefinSlab';
  opacity: 0;
  border-radius: 3px;
  transition: opacity 0.3s;
  text-align: left;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`
const Caption = styled.p`
  font-family: 'JosefinSlab';
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
  letter-spacing: 1px;
  ${down('md')} {
    padding: 0 15px;
    margin-bottom: 15px;
  }
  line-height: 25px;
`
const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Item = styled.div`
  text-align: center;
  position: relative;
  @media screen and (max-width: 516px) {
    width: 50%;
  }
  &:hover {
    cursor: pointer;
    ${Description} {
      opacity: 1;
    }
  }
`
export default Partner
