import { useEffect, useRef, useState } from 'react'
import { createStyles, NumberInput, ActionIcon, rem } from '@mantine/core'
import { IconPlus, IconMinus } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${rem(6)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,

    '&:focus-within': {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,

    '&:disabled': {
      borderColor:
        theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: 'transparent',
    },
  },

  input: {
    textAlign: 'center',
    paddingRight: `${theme.spacing.sm} !important`,
    paddingLeft: `${theme.spacing.sm} !important`,
    height: 5,
    flex: 1,
  },
}))

export function QuantityInput({
  min = 1,
  max = 10,
  availableStock,
  defaultValue,
  ...props
}) {
  const { classes } = useStyles()
  const handlers = useRef(null)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (value > availableStock) {
      setValue(availableStock)
    }
  }, [value])

  return (
    <div className={classes.wrapper}>
      <ActionIcon
        size={18}
        variant="transparent"
        onClick={() => handlers.current?.decrement()}
        disabled={value === min}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <IconMinus size="1rem" stroke={1.5} />
      </ActionIcon>

      <NumberInput
        variant="unstyled"
        min={min}
        max={max}
        value={value}
        handlersRef={handlers}
        onChange={(value) => {
          setValue(value)
          props.value(value)
        }}
        classNames={{ input: classes.input }}
      />

      <ActionIcon
        size={18}
        variant="transparent"
        onClick={() => handlers.current?.increment()}
        disabled={value === max}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <IconPlus size="1rem" stroke={1.5} />
      </ActionIcon>
    </div>
  )
}
