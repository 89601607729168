import React, { useContext } from 'react'
import { UnstyledButton, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import IconProduct from '../../../components/icons/IconProduct'
import IconBox from '../../../components/icons/IconBox'
import IconLogistic from '../../../components/icons/IconLogistic'
import IconInventory from '../../../components/icons/IconInventory'
import IconNewsletter from '../../../components/icons/IconNewsletter'
import IconOrder from '../../../components/icons/IconOrder'
import IconCategories from '../../../components/icons/IconCategories'
import IconReview from '../../../components/icons/IconReview'
import IconMessage from '../../../components/icons/IconMessage'
import IconEmployee from '../../../components/icons/IconEmployee'
import IconLeave from '../../../components/icons/IconLeave'
import IconPointage from '../../../components/icons/IconPointage'
import { AuthContext } from '../../../context/AuthContext'

const MainLink = ({ icon, color, label, link, role, index }) => {
  const { user } = useContext(AuthContext)
  return (
    <>
      {role.includes(user?.role) && (
        <Link to={link}>
          <UnstyledButton
            sx={(theme) => ({
              display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[0]
                  : theme.black,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              },
            })}
          >
            {!link && index === 0 ? (
              <Group position="apart">
                <Text size="xs" weight={500} color="dimmed">
                  Gestion du site web
                </Text>
              </Group>
            ) : (
              <>
                {!label && index === 8 ? (
                  <Group position="apart">
                    <Text size="xs" weight={500} color="dimmed">
                      Application Web
                    </Text>
                  </Group>
                ) : (
                  <>
                    {!label && index === 12 ? (
                      <Text size="xs" weight={500} color="dimmed">
                        Resources Humaines
                      </Text>
                    ) : (
                      <Group>
                        {icon}
                        <Text
                          size="md"
                          style={{ fontFamily: 'Rubik', fontSize: 15 }}
                        >
                          {label}
                        </Text>
                      </Group>
                    )}
                  </>
                )}
              </>
            )}
          </UnstyledButton>
        </Link>
      )}
    </>
  )
}

const data = [
  {
    icon: null,
    label: '',
    role: ['admin'],
  },
  {
    icon: <IconProduct />,
    color: 'blue',
    label: 'Produits',
    link: '/admin/products',
    role: ['admin'],
  },
  {
    icon: <IconMessage />,
    color: 'teal',
    label: 'Message',
    link: '/admin/contacts',
    role: ['admin'],
  },
  {
    icon: <IconReview />,
    color: 'violet',
    label: 'Avis des clients',
    link: '/admin/testimonials',
    role: ['admin'],
  },
  {
    icon: <IconCategories />,
    color: 'grape',
    label: 'Categories',
    link: '/admin/categories',
    role: ['admin'],
  },
  {
    icon: <IconOrder />,
    color: 'grape',
    label: 'Commandes',
    link: '/admin/orders',
    role: ['admin'],
  },
  {
    icon: <IconNewsletter />,
    color: 'grape',
    label: 'Abonnés',
    link: '/admin/newsletters',
    role: ['admin'],
  },
  {
    icon: <IconNewsletter />,
    color: 'grape',
    label: 'Réclamation',
    link: '/admin/request',
    role: ['admin'],
  },
  {
    icon: null,
    label: '',
    role: ['admin'],
  },
  {
    icon: <IconBox />,
    color: 'grape',
    label: 'Articles',
    link: '/admin/articles',
    role: ['admin', 'employee'],
  },
  {
    icon: <IconLogistic />,
    color: 'grape',
    label: 'Logistics',
    link: '/admin/logistics',
    role: ['admin', 'employee'],
  },
  {
    icon: <IconInventory />,
    color: 'grape',
    label: 'Mouvement de stock',
    link: '/admin/logistics/inventory',
    role: ['admin', 'employee'],
  },
  {
    icon: null,
    label: '',
    role: ['admin'],
  },
  {
    icon: <IconEmployee />,
    color: 'grape',
    label: 'Empoyés',
    link: '/admin/hr/employee',
    role: ['admin'],
  },
  {
    icon: <IconLeave />,
    color: 'grape',
    label: 'Demande de congés',
    link: '/admin/hr/leaves',
    role: ['admin'],
  },
  {
    icon: <IconPointage />,
    color: 'grape',
    label: "Gestion d'absence",
    link: '/admin/hr/absence',
    role: ['admin'],
  },
]

export function MainLinks() {
  const links = data.map((link, index) => (
    <MainLink {...link} key={link.label} index={index} />
  ))
  return <div>{links}</div>
}
