import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { Card, CardSection, Badge, Button } from '@mantine/core'
import styled from 'styled-components'
import axios from 'axios'
import { useAuthContext } from '../../context/AuthContext'
import moment from 'moment/moment'
import NewRequest from './NewRequest'
import { IconSend, IconPlus } from '@tabler/icons-react'
import 'moment/locale/fr'

const Request = () => {
  const [requests, setRequests] = useState()
  const [requestResponses, setRequestResponses] = useState()
  const [isReply, setIsReply] = useState(false)
  const [isNewRequest, setIsNewRequest] = useState(false)
  const { user } = useAuthContext()
  const searchParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const fetchRequest = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}requests/${user?._id}`,
      )
      setRequests(data.requests)
    }
    fetchRequest()
  }, [])

  const fetchRequestResponse = async (requestId) => {
    setIsReply(false)
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}requests/${requestId}?response=true`,
    )
    setRequestResponses(data.requests)
    searchParams.set('request_id', requestId)
    window.history.pushState({}, null, '?' + searchParams.toString())
  }

  return (
    <div>
      <Row>
        <Col md={4}>
          <Card withBorder>
            <CardSection>
              <CardHeader>Vos dernières demandes</CardHeader>
              <CardBody>
                {requests &&
                  requests.map((request, index) => (
                    <MessageContent
                      onClick={() => fetchRequestResponse(request._id)}
                    >
                      <span>#34552 - {request.object}</span>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <small>Fermé</small>
                        <small>{moment(request.createdAt).fromNow()}</small>
                      </div>
                    </MessageContent>
                  ))}
              </CardBody>
            </CardSection>
          </Card>
        </Col>
        <Col md={8}>
          {requestResponses &&
            requestResponses.map((response, index) => (
              <MessageContent>
                <span>#34552 - {response.object}</span>
                <Badge
                  color="teal"
                  radius="xs"
                  style={{ marginLeft: 8, textTransform: 'capitalize' }}
                >
                  {response.userId === user?._id && user?.role === 'admin'
                    ? 'Santikà'
                    : response.user.username}
                </Badge>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <small>
                    <Badge color="dark" radius="sm" size="sm">
                      Fermé
                    </Badge>
                  </small>
                  <span>{moment(response.createdAt).fromNow()}</span>
                </div>
                <p>{response.message}</p>
              </MessageContent>
            ))}
          {isReply || isNewRequest ? (
            <NewRequest
              user={user}
              requestId={searchParams.get('request_id')}
              fetchRequestResponse={fetchRequestResponse}
              setIsReply={setIsReply}
            />
          ) : requestResponses ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => setIsReply(true)}>
                <IconSend size={18} style={{ marginRight: 5 }} /> Répondre
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
              }}
            >
              <h3>Ouvrir une demande</h3>
              <Button
                onClick={() => {
                  setIsNewRequest(true)
                  localStorage.removeItem('requestId')
                }}
              >
                <IconPlus size={18} style={{ marginRight: 5 }} /> Créer
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Request

const CardHeader = styled.div`
  background: #f6f6f6;
  padding: 13px 23px;
  font-size: 18px;
  font-weight: 500;
`
const CardBody = styled.div`
  padding: 5px 12px;
`
const MessageContent = styled.div`
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 5px;
  cursor: pointer;
  margin-bottom: 15px;
`
