import React, { useEffect, useState } from 'react'
import Layout from '../Containers/Layout'
import {
  createStyles,
  Card,
  Group,
  Switch,
  Text,
  rem,
  Table,
  Badge,
} from '@mantine/core'
import axios from 'axios'
import {
  IconBrandNytimes,
  IconCheck,
  IconDetails,
  IconEye,
} from '@tabler/icons-react'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
    fontFamily: 'JosefinSlab',
    fontSize: 23,
  },

  title: {
    lineHeight: 1,
    fontFamily: 'Rubik',
    fontSize: 25,
  },
  orderInfo: {
    fontFamily: 'Rubik',
    lineHeight: 2,
  },
}))

const Orders = () => {
  const [orders, setOrders] = useState([])
  const [checked, setChecked] = useState({})
  const { classes } = useStyles()

  const fetchOrders = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}orders`)
    setOrders(data)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    const { key: ref, value: paid } = checked
    const setOrderAsPaid = async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}orders/setOrderAsPaid`,
        {
          ref,
          paid,
        },
      )
      if (data.status === 'success') {
        fetchOrders()
      }
    }
    setOrderAsPaid()
  }, [checked])

  const total = (orderItems) => {
    return orderItems.reduce((total, orderItem) => {
      return total + orderItem.item.totalProductPrice
    }, 0)
  }
  const items = orders.map((item, key) => (
    <Group position="apart" className={classes.item} noWrap spacing="xl">
      <Row>
        <Col md={3}>
          <div className={classes.orderInfo}>
            <p>
              <strong>
                <u>Nom et pénom</u>
              </strong>{' '}
              : {item.user.username} {item.user.lastname} <br />
            </p>
            <p>
              <strong>
                <u>Email</u>
              </strong>
              : {item.email}
            </p>
            <p>
              <strong>
                <u>Adresse</u>
              </strong>
              : {item.address}
            </p>
            <p>
              <strong>
                <u>Numéro téléphone</u>
              </strong>
              : {item.phone}
            </p>
            <p>
              <strong>
                <u>Mode de paiement</u>
              </strong>
              : {item.paymentMethod}
            </p>
            <p>
              <strong>
                <u>Status</u>
              </strong>
              :{' '}
              <Badge color={`${item.paid ? 'blue' : 'red'}`}>
                {item.paid ? 'payé' : 'non payé'}
              </Badge>
            </p>
            <br />
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <IconEye />
              &nbsp;{' '}
              <Link to={`/invoice/${item.ref}`} target="_blank">
                Voir la facture
              </Link>
            </p>
          </div>
        </Col>
        <Col md={9}>
          <h4 style={{ fontFamily: 'Rubik' }}>PRODUITS</h4>
          <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Prix</th>
                <th>Quantity</th>
                <th>Type</th>
                <th>Spécification</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {item.orderItems.map((orderItem, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={orderItem.item.products.productImage}
                      width={50}
                      height={50}
                      style={{ objectFit: 'cover' }}
                      alt=""
                    />
                  </td>
                  <td>{orderItem.item.products.title}</td>
                  <td>{orderItem.item.products.newPrice}</td>
                  <td>{orderItem.item.totalProductQuantity}</td>
                  <td>{orderItem.item.productType}</td>
                  <td>
                    {orderItem.item.productCustomDescription
                      ? parse(orderItem.item.productCustomDescription)
                      : '-'}
                  </td>
                  <td>{orderItem.item.totalProductPrice}Ar</td>
                </tr>
              ))}
              <tr>
                <td colSpan={7}>
                  <h3>Total: {total(item.orderItems)}Ar</h3>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Switch
        onLabel="Non payé"
        offLabel="Payé"
        className={classes.switch}
        size="lg"
        onChange={(event) =>
          setChecked({ key: item.ref, value: event.currentTarget.checked })
        }
      />
    </Group>
  ))

  return (
    <Layout>
      <Card withBorder radius="md" p="xl" className={classes.card}>
        <Text fz="lg" className={classes.title} fw={500}>
          Liste des commandes
        </Text>
        <Text fz="xs" c="dimmed" mt={3} mb="xl"></Text>
        {items}
      </Card>
    </Layout>
  )
}

export default Orders
