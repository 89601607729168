import React from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;