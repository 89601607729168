import React from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import Button from './common/Button'
import { IconDownload } from '@tabler/icons-react'
var canvas = document.createElement('canvas')
canvas.width = 1920
canvas.height = 1280

const PdfDownloader = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId)
    html2canvas(input, {
      useCORS: true,
    }).then((canvas) => {
      let imgWidth = 208
      let imgHeight = (canvas.height * imgWidth) / canvas.width
      const imgData = canvas.toDataURL('img/png')
      const pdf = new jsPDF('p', 'mm', 'a4')
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight)
      pdf.save('download.pdf')
    })
  }

  return (
    <Button onClick={downloadPdfDocument} style={{ marginBottom: 50 }}>
      <IconDownload /> <span>Télécharger</span>
    </Button>
  )
}

export default PdfDownloader
