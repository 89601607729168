import * as React from 'react'
const IconNewsletter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M0 194.5V359h21V52h440V30H0v164.5z" />
    <path d="M40.8 234.7c.2 90.1.5 163.9.7 164.1.2.2 59.9.6 132.7.9 95 .4 132.5.9 133 1.7.4.6.8 2.3.8 3.7 0 4.3 4.8 19.8 8.5 27.7 7.8 16.6 20.7 31.5 35.9 41.7 10.5 7 19.5 11.1 31.6 14.3 8.3 2.2 11.5 2.5 25.5 2.5 14.2 0 17.1-.3 26-2.6 34.8-9.3 62.4-36.2 73.1-71.3 2.6-8.3 2.8-10.2 2.8-27.4.1-16.3-.2-19.5-2.2-26.9-2.7-10-7.7-21.2-13.2-29.7l-4-6V71H40.5l.3 163.7zm430-130.3-.3 11.4-88 76.7c-48.4 42.2-90 78-92.5 79.6-14.8 9.5-32.5 8.6-47.1-2.4-3.1-2.3-45-38.6-93.2-80.6L62 112.7V93h409.1l-.3 11.4zM113 186c26.1 22.8 50.9 44.4 55.2 48.1l7.6 6.7-56 44.3c-30.8 24.4-56.5 44.5-56.9 44.7-.5.2-.9-41.1-.9-93.9 0-89.6.1-94.1 1.8-92.7.9.8 23.1 20.1 49.2 42.8zm357.6 119.7c-.2.2-2.4-1-4.9-2.6-7.6-5.2-20.8-11-31.2-13.7-8.7-2.3-12-2.7-25-2.7s-16.4.4-25 2.6c-11.8 3.1-27.4 10.6-36.5 17.5-14.6 11.1-27 27.1-33.7 43.6-2.8 6.9-4 11.5-6.9 25.3l-.4 2.3H62v-19.5l50.7-40c27.8-22 57.2-45.2 65.3-51.6l14.6-11.6 16 14c8.7 7.7 18.2 15.7 20.9 17.8 6.1 4.6 16 9.3 24 11.4 7.6 1.9 21.4 1.9 29 0 8-2 21-8.5 26.5-13.2 2.5-2.1 39.8-34.6 83-72.2l78.5-68.4.3 80.3c.1 44.2 0 80.5-.2 80.7zM428 310c31.4 7.6 55.5 32.9 61.6 64.8 6.7 34.8-11.6 70.7-44.1 86.8-12.6 6.2-22.4 8.5-36 8.5-39.8 0-73.2-28.4-80.1-68.2-7.1-40.8 20.3-81.8 61.5-91.9 10.6-2.6 26.1-2.6 37.1 0z" />
    <path d="M405.4 328c-1.2.4-3.1 2.1-4.3 3.6-2 2.5-2.1 3.7-2.1 25V379h-45.8l-2.6 3.1c-4.2 5-3.1 12.9 2.3 16.6 2.2 1.6 5.6 1.8 24.3 2.1l21.7.3.3 22.1.3 22 3.3 2.9c5.4 4.9 13.7 3.6 16.7-2.6 1.2-2.7 1.5-7.4 1.5-23.9V401h19.4c22.2 0 24.7-.3 28.1-3.8 3-2.9 3.4-9.8.9-13.7-2.5-3.9-6.4-4.5-28.5-4.5H420v-21.5c0-23.8-.5-26.1-6-29-3.2-1.7-5.4-1.8-8.6-.5z" />
  </svg>
)
export default IconNewsletter
