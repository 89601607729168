import React, { useEffect, useState } from "react";
import Layout from "./Containers/Layout";
import {
  Table,
  Text,
  Card,
  Button,
  TextInput,
  Input,
  FileInput,
} from "@mantine/core";
import { useGetQuery } from "../../hooks/useGetQuery";
import { IconArrowLeft, IconSend, IconTrash } from "@tabler/icons-react";
import { usePostQuery } from "../../hooks/usePostQuery";
import { useForm } from "@mantine/form";
import { TextEditor } from "../../components/TextEditor";

const Contact = () => {
  const [repliedTo, setRepliedTo] = useState(null);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const { data, refetch } = useGetQuery("contacts");
  const { sendRequest } = usePostQuery("contacts", "DELETE");
  const { sendRequest: sendMail, loading } = usePostQuery(
    "contacts/sendMail",
    "POST",
    true
  );

  const form = useForm({
    initialValues: {
      message: "",
    },
  });

  const onDelete = async (id) => {
    await sendRequest({ id });
    refetch();
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("to", repliedTo);
    formData.append("subject", "Message de test");
    formData.append("message", message);
    formData.append("username", "Randria");

    if (files && files.length > 0) {
      files.map((file) => formData.append("file", file));
    }

    await sendMail(formData);
    form.reset();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Layout>
        <Text fw={500} fz={25} mb={10} style={{ fontFamily: "JosefinSlab" }}>
          {!repliedTo ? "Liste des messages" : "Répondre à ce message"}
        </Text>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          {!repliedTo ? (
            <Table style={{ fontFamily: "JosefinSlab" }}>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Numero téléphone</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?._contacts?.length > 0 &&
                  data._contacts.map((contact, index) => (
                    <tr>
                      <td>{contact.name}</td>
                      <td>{contact.phone}</td>
                      <td>{contact.email}</td>
                      <td>{contact.message}</td>
                      <td>
                        <Button
                          size={"xs"}
                          onClick={() => setRepliedTo(contact.email)}
                        >
                          <IconSend size={20} />
                          &nbsp; Répondre
                        </Button>
                        &nbsp;
                        <Button
                          size={"xs"}
                          color="red"
                          onClick={() => onDelete(contact._id)}
                        >
                          <IconTrash size={20} />
                          &nbsp; Supprimer
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <div style={{ maxWidth: 800, margin: "0 auto" }}>
              <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput
                  size={"md"}
                  label="Email"
                  style={{ fontFamily: "JosefinSlab" }}
                  disabled
                  value={repliedTo}
                />
                <Input.Wrapper
                  label="Message"
                  maw={"100%"}
                  style={{ fontFamily: "JosefinSlab" }}
                  mx="auto"
                  withAsterisk
                  mt={10}
                >
                  <TextEditor content={(value) => setMessage(value)} />
                </Input.Wrapper>
                <FileInput
                  mt={10}
                  style={{ fontFamily: "JosefinSlab" }}
                  label="Pièce jointe"
                  multiple
                  onChange={(files) => setFiles(files)}
                />
                <Button
                  variant="default"
                  onClick={() => setRepliedTo(null)}
                  mt={10}
                >
                  <IconArrowLeft /> &nbsp; Retour
                </Button>
                &nbsp;
                {message !== "<p></p>" && (
                  <Button
                    loading={loading}
                    loaderPosition="left"
                    type="submit"
                    mt={10}
                  >
                    <IconSend /> &nbsp; Envoyer
                  </Button>
                )}
              </form>
            </div>
          )}
        </Card>
      </Layout>
    </div>
  );
};

export default Contact;
