import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from '@mantine/core'
import {
  IconGauge,
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandTwitter,
} from '@tabler/icons-react'
import { Link, redirect } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import styled from 'styled-components'

const mockdata = [
  {
    title: 'Extreme performance',
    description:
      'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
    icon: IconGauge,
  },
]

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    position: 'relative',
    height: '100%',
  },

  cardTitle: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: '#fd787f',
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.xs,
    },
  },
}))

export function PartnerItem({ title, description, image }) {
  const smallScreen = useMediaQuery('(max-width: 800px)')
  const { classes, theme } = useStyles()
  const features = mockdata.map((feature) => (
    <Card
      key={title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
      style={{ boxShadow: 'none', height: !smallScreen ? 280 : 'auto', position: 'relative' }}
    >
      <img src={image} style={{ width: '50px' }} alt="" />
      <Text fz="lg" fw={600} className={classes.cardTitle} mt="none">
        {title}
      </Text>
      <Text fz="md" c="dimmed" mt="sm" style={{ fontFamily: 'JosefinSlab' }}>
        {description}
      </Text>
      <Social>
        <div>
          <IconBrandFacebook size={30} />
        </div>
        <div>
          <IconBrandInstagram size={30} />
        </div>
        <div>
          <IconBrandYoutube size={30} />
        </div>
      </Social>
    </Card>
  ))

  return (
    <Container style={{ paddingLeft: 0 }}>
      <SimpleGrid
        spacing="xl"
        mt={15}
        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  )
}

const Social = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0;
`
