import axios from 'axios'
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCT,
} from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchProducts = (params = null, hasMore = false) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCTS })
    const { data } = await axios.get(
      `${API}products${params ? '?' + params : ''}`,
    )
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: data })
  }
}

export const getSingleProduct = (slug) => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`${API}products/${slug}`)
    dispatch({
      type: FETCH_SINGLE_PRODUCT,
      payload: data,
    })
  }
}
