import axios from 'axios'
import { FETCH_ARTICLES } from '../types'
import { DELETE_ARTICLE } from '../types'

const API = process.env.REACT_APP_BASE_URL

export const fetchArticles = () => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`${API}articles?limit=2000`)
    dispatch({
      type: FETCH_ARTICLES,
      payload: data,
    })
  }
}

export const deleteArticle = (id) => {
  return async (dispatch, getState) => {
    const { data } = await axios.delete(`${API}articles/${id}`)
    dispatch({
      type: DELETE_ARTICLE,
      payload: data,
    })
  }
}
