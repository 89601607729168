import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { Text, TextInput, Button as MantineButton } from '@mantine/core'
import Button from '../../components/common/Button'
import { usePostQuery } from '../../hooks/usePostQuery'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { Col, Row } from 'react-grid-system'
import {
  IconBrandFacebook,
  IconBrandGoogle,
  IconCheck,
  IconLock,
  IconMail,
} from '@tabler/icons-react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modal.action'
import { useGetQuery } from '../../hooks/useGetQuery'
import axios from 'axios'

const Auth = () => {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { code } = useParams()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { refetch } = useGetQuery('')

  useEffect(() => {
    if (code) {
      refetch(`users/confirm/${code}`)
      notifications.show({
        title: 'Notification',
        message: 'Votre compte a bien été confirmé',
        color: 'teal',
        icon: <IconCheck />,
      })
      setTimeout(() => {
        window.location.href = '/auth'
      }, 1300)
    }
    if (user) navigate('/')
  }, [code])

  const { sendRequest: processLogin } = usePostQuery(
    'users/login',
    'POST',
    false,
    {
      'Content-Type': 'application/json',
    },
  )

  const form = useForm({
    initialValues: {
      username: '',
      lastname: '',
      email: '',
      password: '',
      confirmPwd: '',
    },
  })

  const handleSubmit = async (values) => {
    await processLogin(values)
    const token = sessionStorage.getItem('sessionToken')
    if (token) {
      const decodedToken = decodeToken(token)
      const role = decodedToken.role
      if (['admin', 'employee'].includes(role)) {
        navigate(`/admin/${role === 'admin' ? 'products' : 'articles'}`)
        window.location.reload()
      } else {
        if (searchParams.get('returnUrl')) {
          window.location.replace(searchParams.get('returnUrl'))
        } else {
          window.location.href = '/customer'
        }
      }
    }
  }

  return (
    <Layout withSlider={false}>
      <Container>
        <WrapSecurity className="security">
          <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
            <Inner>
              <Title>connexion à votre compte santikà</Title>
              <small style={{ textAlign: 'center', display: 'block' }}>
                Vous devez être connecté à votre compte client Santikà pour
                accéder à cette page.
              </small>
              <Row>
                <Col md={6}>
                  <span className="modal-actions-title">déjà client ?</span>
                  <TextInput
                    withAsterisk
                    mt={25}
                    size={'lg'}
                    radius={'xs'}
                    type="email"
                    name="name"
                    variant="default"
                    placeholder="Votre email"
                    {...form.getInputProps('email')}
                    style={{ fontFamily: 'JosefinSlab' }}
                    required
                    icon={<IconMail size={18} />}
                  />
                  <TextInput
                    mt={7}
                    mb={10}
                    withAsterisk
                    size={'lg'}
                    radius={'xs'}
                    name="name"
                    type="password"
                    placeholder="Votre mot de passe"
                    variant="default"
                    {...form.getInputProps('password')}
                    style={{ fontFamily: 'JosefinSlab' }}
                    required
                    icon={<IconLock size={18} />}
                  />
                  <Button
                    style={{
                      background: '#242629',
                      width: '100%',
                      fontWeight: 500,
                      fontSize: 13,
                    }}
                    noHover={true}
                  >
                    se connecter
                  </Button>
                  <Text mt={15} fw={600} mb={10}>
                    Utiliser les réseaux sociaux
                  </Text>
                  <MantineButton
                    style={{ background: '#dd4b39' }}
                    color="#dd4b39"
                    fullWidth
                    size="md"
                    mb={5}
                    radius={'sm'}
                    leftIcon={<IconBrandGoogle />}
                    onClick={() =>
                      window.open(
                        'https://santika.mg/api/v1/social/auth/google',
                        '_self',
                      )
                    }
                  >
                    Se connecter avec Google
                  </MantineButton>
                  <MantineButton
                    style={{ background: '#47639e' }}
                    radius={'sm'}
                    color="#47639e"
                    fullWidth
                    size="md"
                    leftIcon={<IconBrandFacebook />}
                    onClick={() =>
                      window.open(
                        'https://santika.mg/api/v1/social/auth/facebook',
                        '_self',
                      )
                    }
                  >
                    Se connecter avec Facebook
                  </MantineButton>
                </Col>
                <Col md={6}>
                  <span className="modal-actions-title">
                    pas encore de compte ?
                  </span>
                  <p
                    style={{
                      fontSize: 14,
                      textAlign: 'center',
                    }}
                  >
                    Créer un compte chez Santikà vous permettra de passer votre
                    commande, mais aussi d'enregistrer vos favoris, retrouver
                    votre historique de commande et nous envoyer de l'amour.
                  </p>
                  <Button
                    style={{
                      background: '#242629',
                      width: '100%',
                      fontWeight: 500,
                      marginTop: 58,
                      fontSize: 13,
                    }}
                    type="button"
                    noHover={true}
                    onClick={() => dispatch(openModal({ type: 'auth' }))}
                  >
                    s'inscrire
                  </Button>
                </Col>
              </Row>
            </Inner>
          </form>
        </WrapSecurity>
      </Container>
    </Layout>
  )
}

const AuthContainer = styled.div`
  @media screen and (min-width: 800px) {
    width: 400px;
    height: 600px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
  @media screen and (max-width: 800px) {
    padding: 25px;
    margin-top: 25px;
  }
`
const Logo = styled.div`
  display: flex;
  justify-content: center;
`
const Title = styled.h3`
  font-family: 'JosefinSlab';
  font-weight: 500;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  font-weight: 500;
  text-align: center;
`
const ToggleAuth = styled.div`
  margin-top: 15px;
  font-family: 'JosefinSlab';
  width: 100%;
`
const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-position: center;
  background-size: cover;
`
const Container = styled.div`
  max-width: 1000px;
  margin: 100px auto;
  font-family: 'JosefinSlab';
  padding: 10px;
`
const AuthTitle = styled.h2``
const WrapSecurity = styled.div`
  background: #fff;
  padding: 20px;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`
const Inner = styled.div`
  background: #fff;
  @media only screen and (min-width: 770px) {
    padding: 50px;
    border: 1px solid #ddd;
  }
`
export default Auth
