import { FETCH_ARTICLES } from '../types'

const initialState = {
  articles: [],
}

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES: {
      return {
        ...state,
        articles: action.payload?.data,
      }
    }
    default: {
      return state
    }
  }
}

export default articleReducer
