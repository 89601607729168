import React, { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { API, SESSION_TOKEN } from '../../constant'
import { useEffect } from 'react'
import { useSessionStorage } from '../../hooks/useSessionStorage'

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { getItem, removeItem } = useSessionStorage()

  const authToken = getItem('sessionToken')
  const fetchLoggedInUser = async (token) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${API}users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          sessionToken: authToken,
        },
      })
      const data = await response.json()
      if (data._id) setIsLoggedIn(true)
      setUserData(data)
    } catch (error) {
      removeItem(SESSION_TOKEN)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUser = (user) => {
    setUserData(user)
  }

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isLoading, isLoggedIn }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
