import React, { useEffect } from 'react'
import {
  useMantineTheme,
  Modal,
  Table,
  Button,
  Pagination,
  TextInput,
} from '@mantine/core'
import styled from 'styled-components'
import { IconPlus, IconSearch, IconX } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../store/actions/modal.action'
import { useDebouncedCallback } from 'use-debounce';
import ContentPlaceholder from '../../../components/common/ContentPlaceholder'

const ListArticle = ({
  articles,
  setCurrentPage,
  currentPage,
  totalPages,
  handleAddArticle,
  handleSearchArticle,
  isFetching
}) => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'logistics'
      ? state.modal.openModal.value
      : false,
  )

  const debounced = useDebouncedCallback(
    (value) => {
      handleSearchArticle(value)
    },
    500
  );

  return (
    <Modal
      opened={modalStatus}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      size="xxl"
      radius={'xs'}
      className="auth-register"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 6,
      }}
      zIndex={9999}
    >
      <ModalHeader
        onClick={() => {
          dispatch(closeModal({ type: 'logistics' }))
        }}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <ModalTitle>listes des articles</ModalTitle>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Futura',
            letterSpacing: 1,
            fontSize: 13,
          }}
        >
          <TextInput
            sx={{ flexBasis: '60%' }}
            placeholder="Rechercher un article..."
            icon={<IconSearch size={16} />}
            variant="unstyled"
            mb={15}
            onChange={(e) => debounced(e.target.value)}
          />
          {isFetching ? <div style={{ width: '100%'}}>
            <ContentPlaceholder />
          </div> :
            <>
                <Table>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prix</th>
                        <th>Prix cassée</th>
                        <th>Qty disponible</th>
                        <th>Qty perdu</th>
                        <th>Qty cassée</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {articles &&
                        articles.map((article, index) => (
                        <tr>
                            <td style={{ textAlign: 'left' }}>{article.name}</td>
                            <td>{article.price}</td>
                            <td>{article.priceCasse}</td>
                            <td>{article.qtyDispo}</td>
                            <td>{article.qtyLost}</td>
                            <td>{article.qtyBroken}</td>
                            <td>
                            <Button
                                variant="white"
                                onClick={() => handleAddArticle(article)}
                            >
                                <IconPlus width={15} color="#333" />
                            </Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                <div>
                  <Pagination
                    total={totalPages}
                    color="dark"
                    size="sm"
                    radius="xs"
                    withEdges
                    defaultValue={currentPage}
                    style={{ marginTop: 15, marginBottom: 15 }}
                    onChange={setCurrentPage}
                  />
                </div>
            </>
          }
        </div>
      </ModalBody>
    </Modal>
  )
}

const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
  margin-bottom: 25px;
`
const ModalBody = styled.div`
  position: relative;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
`

export default ListArticle
