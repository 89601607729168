import React from 'react'
import { Text, Card, Badge } from '@mantine/core'
import Layout from '../Containers/Layout'
import styled from 'styled-components'
import AdminRequest from '../../user/Request'

const Request = () => {
  return (
    <Layout>
      <Text fw={500} fz={25} mb={10} style={{ fontFamily: 'JosefinSlab' }}>
        Liste des réclamations
      </Text>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <AdminRequest />
      </Card>
    </Layout>
  )
}

export default Request
