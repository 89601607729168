import {
  UnstyledButton,
  Checkbox,
  Text,
  Image,
  SimpleGrid,
  createStyles,
  rem,
} from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import Button from '../../components/common/Button'
import { notifications } from '@mantine/notifications'

const useStyles = createStyles((theme, { checked }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms ease, border-color 150ms ease',
    border: `${rem(1)} solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor })
            .border
        : theme.colorScheme === 'dark'
        ? theme.colors.dark[8]
        : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.sm,
    backgroundColor: checked
      ? theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .background
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[8]
      : theme.white,
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
  item: {
    fontFamily: 'JosefinSlab',
    fontSize: 15,
    textTransform: 'uppercase',
    letterSpacing: 2,
    fontWeight: 600,
  },
}))

export function ImageCheckbox({
  checked,
  defaultChecked,
  onChange,
  title,
  description,
  className,
  image,
  ...others
}) {
  const [value, handleChange] = useUncontrolled({
    value: checked.key === title ? true : false,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  })

  const { classes, cx } = useStyles({ checked: value })

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(!value)}
      className={cx(classes.button, className)}
    >
      <Image src={image} alt={title} width={60} />
      <div className={classes.body}>
        <Text
          weight={500}
          size="sm"
          className={classes.item}
          sx={{ lineHeight: 1 }}
        >
          {title}
        </Text>
      </div>
      <Checkbox
        checked={value}
        onChange={() => {}}
        tabIndex={-1}
        styles={{ input: { cursor: 'pointer' } }}
      />
    </UnstyledButton>
  )
}

const mockdata = [
  {
    title: 'PAIEMENT par carte bancaire',
    image: '/images/mastercard.png',
  },
  {
    title: 'airtel money',
    image: '/images/airtel.jpeg',
  },
  {
    title: 'mvola',
    image: '/images/mvola.jpeg',
  },
  {
    title: 'orange money',
    image: '/images/orange.png',
  },
]

export default function PaymentMethod(props) {
  const [checked, setChecked] = useState(false)
  const [paymentMethodType, setPaymentMethodType] = useState(
    localStorage.getItem('paymentMethodType') || null,
  )

  useEffect(() => {
    if (localStorage.getItem('paymentMethodType')) {
      setChecked({ key: localStorage.getItem('paymentMethodType') })
    }
  }, [])

  const selectedPayment = (item) => {
    setChecked({ key: item })
    setPaymentMethodType(item)
    localStorage.setItem('paymentMethodType', item)
  }

  const savePaymentMethod = () => {
    if (!paymentMethodType) {
      notifications.show({
        title: 'Notification',
        message: 'Please choose your payment method type',
        color: 'red',
      })
      return
    }
    props.nextStep(true)
  }

  const items = mockdata.map((item) => (
    <ImageCheckbox
      {...item}
      key={item.title}
      checked={checked}
      onChange={() => selectedPayment(item.title)}
    />
  ))
  return (
    <div>
      <h2
        style={{
          fontFamily: 'JosefinSlab',
          margin: '15px 0px',
          letterSpacing: 2,
          textTransform: 'uppercase',
          fontSize: 17,
          color: '#333',
        }}
      >
        Choose payment method
      </h2>
      <SimpleGrid
        cols={1}
        breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'sm', cols: 1 },
        ]}
      >
        {items}
      </SimpleGrid>
      <Button style={{ marginTop: 15 }} onClick={savePaymentMethod}>
        continuez vos achats
      </Button>
    </div>
  )
}
