import {
  FETCH_ACTIVE_FILTERS,
  REMOVE_ALL_FILTER,
  REMOVE_FILTER,
  SET_FILTER,
  SORT_BY,
} from '../types'

const initialState = {
  filters: {},
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_FILTERS:
      const params = action.payload
      const filters = {}
      const prices = []
      for (const [key, value] of params) {
        if (key.indexOf('filter.v.price') > -1) {
          prices.push(`Ar ${value} - `)
        }
        if (prices.length > 0) {
          filters['prices'] = prices
        }
        filters[key] = value
        if (value.includes(',')) {
          filters[key] = value.split(',').map((item) => item)
        }
      }

      delete filters['filter.v.price.gte']
      delete filters['filter.v.price.lte']
      delete filters['page']

      return {
        ...state,
        filters: filters,
      }
    case SET_FILTER: {
      const { name, value, isChecked } = action.payload
      return {
        filters: Object.assign(
          { ...state.filters },
          {
            [name]: isChecked
              ? !state.filters[name]
                ? [value]
                : [...state.filters[name], value]
              : state.filters[name].filter((v) => v !== value),
          },
        ),
      }
    }
    case REMOVE_FILTER: {
      const { name, value } = action.payload
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: Array.isArray(state.filters[name])
            ? state.filters[name].filter((v) => v !== value)
            : [],
        },
      }
    }
    case REMOVE_ALL_FILTER: {
      return {
        ...state,
        filters: {},
      }
    }
    case SORT_BY: {
      return {
        ...state,
        filters: Object.assign({ ...state.filters, sort_by: action.payload }),
      }
    }
    default:
      return state
  }
}

export default filterReducer
