import * as React from 'react'
const IconLogistic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M149.8 52.1c-1 .5-12.1 18.2-24.8 39.3l-23 38.4v156.6c0 119.2.3 157.1 1.2 159.1 2.6 5.8-6.1 5.5 153.4 5.5h146.6l3.4-3.4 3.4-3.4V129.8l-23-38.4c-12.7-21.1-23.9-38.8-25-39.4-2.6-1.4-209.8-1.3-212.2.1zM245 97v25H132.1l15-25 15-25H245v25zm119.2-1.5c7.7 12.6 14.3 23.8 14.9 24.7.9 1.7-2 1.8-55.6 1.8H267V72l41.7.2 41.7.3 13.8 23zM389 287v143H123V144h266v143z" />
    <path d="M199.1 185.4c-6.5 3.6-6.6 15.1-.2 18.6 1.3.6 21.2 1 57.1 1 35.9 0 55.8-.4 57.1-1 2.4-1.3 4.9-6.1 4.9-9.5 0-3.8-2.4-7.9-5.5-9.3-4-1.8-110.1-1.6-113.4.2zM460 168.4V184h-30v21h30v15.5c0 8.5.3 15.5.6 15.5 1.2 0 50.9-40.2 51.1-41.3.2-1-48.2-40.5-50.9-41.5-.4-.2-.8 6.6-.8 15.2zM3.1 186.6c-2.7 2.3-3.1 3.2-3.1 7.9s.4 5.6 3.1 7.9l3.1 2.6H82v-21H6.2l-3.1 2.6zM25.7 357.4C11.6 368.7 0 378.4 0 378.9c0 1 48.2 39.9 50.5 40.8 1.3.4 1.5-1.8 1.5-14.6V390h30v-22H52.1l-.3-15.6-.3-15.6-25.8 20.6zM430 379v11h36.5c38.9 0 42-.3 44.4-4.8 1.4-2.6 1.4-9.7.1-12.3-2.6-4.7-4.5-4.9-44-4.9h-37v11z" />
  </svg>
)
export default IconLogistic
