import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_SINGLE_PRODUCT,
} from '../types'

const initialState = {
  products: [],
  product: {},
  isProductFilterLoading: false,
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS: {
      return {
        ...state,
        isProductFilterLoading: true,
      }
    }
    case FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.payload,
        isProductFilterLoading: false,
      }
    }
    case FETCH_SINGLE_PRODUCT: {
      return {
        ...state,
        product: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default productReducer
