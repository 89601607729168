import React from 'react'
import { TextInput, Button, NativeSelect, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Row, Col } from 'react-grid-system'
import axios from 'axios'

const NewRequest = ({ user, requestId, fetchRequestResponse, setIsReply }) => {
  const form = useForm({
    initialValues: {
      name: user?.username,
      email: user?.email,
      object: '',
      priority: '',
      message: '',
      userId: user?._id,
    },
  })

  const handleSubmit = async (value) => {
    value['parentId'] = requestId
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}requests`,
      value,
    )
    if (data.status === 'success') {
      fetchRequestResponse(requestId)
      form.reset()
      setIsReply(false)
    }
  }

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Row>
          <Col md={6} style={{ marginTop: 15 }}>
            <TextInput
              label="Nom"
              mt={10}
              size="lg"
              disabled
              {...form.getInputProps('name')}
            />
          </Col>
          <Col md={6} style={{ marginTop: 15 }}>
            <TextInput
              label="Courier"
              mt={10}
              size="lg"
              disabled
              {...form.getInputProps('email')}
            />
          </Col>
          <Col md={9} style={{ marginTop: 15 }}>
            <TextInput
              label="Objet"
              mt={10}
              size="lg"
              required
              {...form.getInputProps('object')}
            />
          </Col>
          <Col md={3} style={{ marginTop: 15 }}>
            <NativeSelect
              label="Priorité"
              data={[
                {
                  value: 'Moyenne',
                  label: 'Moyenne',
                },
                {
                  value: 'Urgent',
                  label: 'Urgent',
                },
              ]}
              required
              {...form.getInputProps('priority')}
            />
          </Col>
          <Col md={12} style={{ marginTop: 15 }}>
            <Textarea
              withAsterisk
              required
              size="lg"
              label="Message"
              {...form.getInputProps('message')}
            />
          </Col>
          <Col md={12} style={{ marginTop: 15 }}>
            <Button type="submit">Envoyer</Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default NewRequest
