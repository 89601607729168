import React, { useEffect, useState } from 'react';
import Layout from '../Containers/Layout';
import {
  Text,
  Card,
  TextInput,
  Group,
  ActionIcon,
  Button,
} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useDebouncedValue } from '@mantine/hooks';
import {
  IconEdit,
  IconFileExport,
  IconPlus,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';
import CreateOrEdit from './CreateOrEdit';
import { openModal } from '../../../store/actions/modal.action';
import { useDispatch, useSelector } from 'react-redux';
import { modals } from '@mantine/modals';
import {
  deleteArticle,
  fetchArticles,
} from '../../../store/actions/article.action';
import axios from 'axios';
import moment from 'moment';

const PAGE_SIZES = [10, 15, 20];

const Articles = () => {
  const articles = useSelector((state) => state.articles.articles);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const [activePage, setPage] = useState(1);
  const [records, setRecords] = useState(articles.slice(0, pageSize));
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const from = (activePage - 1) * pageSize;
  const to = from + pageSize;

  useEffect(() => {
    dispatch(fetchArticles());
    setRecords(articles.slice(from, to));
  }, [activePage, pageSize]);

  useEffect(() => {
    setRecords(
      articles.slice(from, to).filter(({ name }) => {
        if (
          debouncedQuery !== '' &&
          !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        ) {
          return false;
        }
        return true;
      }),
    );
  }, [debouncedQuery]);

  const exportToExcel = async () => {
    window.location.href = 'https://santika.mg/api/v1/articles/export/excel';
  };

  const openDeleteModal = (id) =>
    modals.openConfirmModal({
      title: 'Supprimer cette article',
      centered: true,
      children: (
        <Text size="sm">
          Êtes-vous sûr de vouloir supprimer votre profil ? Cette action est
          destructive et vous devrez contacter le support pour restaurer vos
          données.
        </Text>
      ),
      transitionProps: { transition: 'pop', duration: 200 },
      overlayProps: {
        opacity: 0.55,
        blur: 6,
      },
      labels: {
        confirm: "Supprimer l'article",
        cancel: 'Non ne le supprimez pas',
      },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => dispatch(deleteArticle(id)),
    });

  return (
    <div>
      <Layout>
        <CreateOrEdit />
        <Card padding="lg" radius="md" withBorder>
          <div
            mb={15}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              sx={{ flexBasis: '60%' }}
              placeholder="Rechercher un article..."
              icon={<IconSearch size={16} />}
              value={query}
              onChange={(e) => setQuery(e.currentTarget.value)}
              variant="unstyled"
              mb={15}
            />
            <div>
              <Button
                style={{ float: 'right', width: 100 }}
                color="dark"
                onClick={() => dispatch(openModal({ type: 'article' }))}
              >
                <IconPlus /> Ajouter
              </Button>
              <Button style={{ marginRight: 5 }} onClick={exportToExcel}>
                <IconFileExport size={20} style={{ marginRight: 5 }} /> Exporter
                en excel
              </Button>
            </div>
          </div>
          <DataTable
            withColumnBorders
            records={records}
            columns={[
              {
                accessor: 'Image',
                width: 70,
                render: (image) => (
                  <img
                    width={image.filename ? 50 : 40}
                    src={
                      image.filename ??
                      'https://www.freeiconspng.com/uploads/no-image-icon-4.png'
                    }
                  />
                ),
              },
              { accessor: 'name', title: 'Nom', width: 200 },
              { accessor: 'price', title: 'Prix' },
              { accessor: 'priceCasse', title: 'Prix de remplacement' },
              { accessor: 'qtyDispo', title: 'Qté dispo' },
              { accessor: 'qtyLost', title: 'Qty perdu' },
              { accessor: 'qtyBroken', title: 'Qty cassée' },
              {
                accessor: 'actions',
                title: <Text mr="xs">Actions</Text>,
                textAlignment: 'right',
                render: (article) => (
                  <Group spacing={4} position="center" noWrap>
                    <ActionIcon
                      color="red"
                      onClick={() => openDeleteModal(article._id)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                    <ActionIcon
                      color="blue"
                      onClick={() =>
                        dispatch(openModal({ type: 'article', extra: article }))
                      }
                    >
                      <IconEdit size={16} />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            totalRecords={articles.length}
            paginationColor="dark"
            recordsPerPage={pageSize}
            page={activePage}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
          />
        </Card>
      </Layout>
    </div>
  );
};

export default Articles;
