import * as React from 'react'

const Bag = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="-0.5 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.25 7.885h1.06l1.06 13.54c.02.29-.21.54-.5.54l-13.74.04c-.29 0-.52-.25-.5-.54l.98-13.54h1.1M13.25 8.955h-2.53"
      stroke={props.strokeColor}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.25 10.605v-4.27c0-1.85-1.46-3.34-3.26-3.34-1.8 0-3.26 1.5-3.26 3.34v4.27"
      stroke={props.strokeColor}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Bag
