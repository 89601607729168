import { IconSend } from "@tabler/icons-react";
import axios from "axios";
import React, { useRef } from "react";
import { usePostQuery } from "../../hooks/usePostQuery";

const API = process.env.REACT_APP_BASE_URL;

const ChatFooter = ({ socket, conversation, userId, ...props }) => {
  const messageRef = useRef(null);

  const sendMessage = async (e) => {
    const { sender, receiver } = conversation;
    const receiverId = [sender, receiver].find((member) => member !== userId);

    const { data } = await axios.post(`${API}messenger/messages`, {
      conversationId: conversation._id,
      sender: userId,
      text: messageRef.current.value,
    });

    props.newMessage(data);

    socket.emit("newMessage", {
      ...data,
      receiverId 
    });
  };

  return (
    <div class="message-input">
      <div class="wrap">
        <input
          type="text"
          placeholder="Write your message..."
          ref={messageRef}
        />
        <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
        <button class="submit" onClick={sendMessage}>
          <IconSend />
        </button>
      </div>
    </div>
  );
};

export default ChatFooter;
