import React from 'react'
import Button from '../../components/common/Button'
import { useAuthContext } from '../../context/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { CLEAR_CART } from '../../store/types'

const ProcessPayment = (props) => {
  const dispatch = useDispatch()
  const cartProduct = useSelector((state) => state.cart.cart)
  const paymentMethodType = localStorage.getItem('paymentMethodType')
  const { user } = useAuthContext()

  const onSave = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const paymentMethodType = localStorage.getItem('paymentMethodType')
    const payload = {
      ...userInfo,
      userId: user._id,
      paymentMethod: paymentMethodType,
      paid: false,
      items:
        cartProduct &&
        cartProduct?.items.map((item) => {
          return item._id
        }),
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}orders/create`,
      payload,
    )
    props.nextStep(true)
    props.orderRef(data.data.order.ref)
    localStorage.setItem('orderRef', data.data.order.ref)
    localStorage.removeItem('userInfo')
    localStorage.removeItem('paymentMethodType')

    dispatch({ type: CLEAR_CART })
  }

  return (
    <div
      style={{ marginTop: 15, textAlign: 'center', fontFamily: 'JosefinSlab' }}
    >
      {['mastercard', 'visa'].includes(paymentMethodType) && (
        <div style={{ fontSize: 17 }}>
          Veuillez nous contacter en utilisant le formulaire de contact sur{' '}
          <br />
          <a href="" style={{ textDecoration: 'underline' }}>
            https://www.santika.mg/contact
          </a>{' '}
          pour obtenir les informations de paiement svp
        </div>
      )}
      {['mvola', 'orange money', 'airtel money'].includes(
        paymentMethodType,
      ) && (
        <div>
          Veuillez envoyer votre paiement au 033 64 414 44 / 032 97 396 97 / 034
          04 248 42 (Nomeniavo Suzy Myriam), puis veuillez envoyer la référence
          de la transaction et le numéro de votre facture en utilisant le
          formulaire sur{' '}
          <p>
            Mais avant le paiement, nous vous prions de nous contacter au +261
            34 04 248 42 / 033 64 414 44 / 032 97 396 97 ou nous alerter par SMS
            ou Whatsapp pour confirmer la commande
          </p>
          <a href="" style={{ textDecoration: 'underline' }}>
            https://www.santika.mg/contact
          </a>{' '}
        </div>
      )}
      <Button style={{ marginTop: 15 }} onClick={onSave}>
        continuez vos achats
      </Button>
    </div>
  )
}

export default ProcessPayment
