import { EncryptStorage } from 'encrypt-storage'

export const encryptStorage = new EncryptStorage('santika-redux-persist@012', {
  prefix: 'santika',
  stateManagementUse: true,
})

export const getEncryptKey = (key) => {
  return key && key === 'key'
    ? JSON.parse(encryptStorage.getItem(key)).key
    : JSON.parse(encryptStorage.getItem(key))
}

export const setEncryptKey = (key, value) => {
  return encryptStorage.setItem(key, value)
}

export const decrypt = (value) => {
  return encryptStorage.decryptValue(value)
}

export const getStorage = (key) => {
  if (key) {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key)
    } else {
      return null
    }
  }
}
