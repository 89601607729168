import React, { useState } from 'react'
import styled from 'styled-components'
import { Column } from '../../components/grid/Column'
import { Row } from '../../components/grid/Row'
import { Container } from '@mantine/core'
import Lightbox from 'react-image-lightbox'
import {
  IconBrandFacebook,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconBrandYoutube,
} from '@tabler/icons-react'
import 'react-image-lightbox/style.css'
import { down } from 'styled-breakpoints'

const galleries = [
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755222/portfolio/242393610_1248648472316641_7906886452745841135_n_o6ys42.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755221/portfolio/242395276_1248647855650036_7264276206112240118_n_w5kp40.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755221/portfolio/242393924_1248647878983367_6984911388950393594_n_lj8536.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755220/portfolio/242401868_1248648095650012_2305412663776311328_n_fe81bo.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755219/portfolio/242396275_1248648172316671_2868420947176834971_n_sluwds.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755217/portfolio/242395949_1248648435649978_40768931533533232_n_zp8hko.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755217/portfolio/242395949_1248648435649978_40768931533533232_n_zp8hko.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755206/portfolio/242395950_1248647948983360_3143699813606605862_n_dftfa6.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755204/portfolio/242411088_1248648202316668_3103556881423851664_n_wvpggq.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755201/portfolio/314500503_583565960239569_991366116539634753_n_qjmvxu.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755195/portfolio/254119361_2953913331498474_5088459350673368956_n_yw4jil.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755191/portfolio/245651801_2255015487972945_2759385250984327534_n_dasiy1.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755191/portfolio/261666657_1987171964793507_3909127005042805106_n_xjgm3k.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755190/portfolio/245866726_2252405104900650_2978979346482026197_n_qi8frb.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755186/portfolio/262719538_598623114694409_7362895582642423043_n_a6ewkc.jpg',
  'https://res.cloudinary.com/dkqcgiblz/image/upload/v1679755185/portfolio/254250767_428920302237598_2440006485879594945_n_bhwxrn.jpg',
]
const Gallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Title>Photos</Title> <br />
      <Container>
        {isOpen && (
          <Lightbox
            mainSrc={galleries[photoIndex]}
            nextSrc={galleries[(photoIndex + 1) % galleries.length]}
            prevSrc={
              galleries[(photoIndex + galleries.length - 1) % galleries.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + galleries.length - 1) % galleries.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleries.length)
            }
            reactModalStyle={{ width: 300, height: 300, objectFit: 'cover' }}
          />
        )}
        <GalleryContainer>
          {galleries.map((gallery, index) => (
            <GalleryItem col={12} key={index}>
              <GalleryImage
                src={gallery}
                alt=""
                onClick={() => setIsOpen(true)}
                style={{ objectFit: 'cover' }}
              />
            </GalleryItem>
          ))}
        </GalleryContainer>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 35,
          }}
        >
          <Column style={{ display: 'flex' }}>
            Partager:
            <Social>
              <SocialItem>
                <IconBrandFacebook />
              </SocialItem>
              <SocialItem>
                <IconBrandTwitter />
              </SocialItem>
              <SocialItem>
                <IconBrandYoutube />
              </SocialItem>
              <SocialItem>
                <IconBrandWhatsapp />
              </SocialItem>
            </Social>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

const Title = styled.h2`
  font-size: 36px;
  font-family: 'JosefinSlab';
  font-weight: normal;
  text-align: center;
`
const Social = styled.ul`
  display: inline;
  list-style: none;
`
const SocialItem = styled.li`
  display: inline;
  padding: 0 15px;
`
const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
const GalleryItem = styled.div`
  flex: 14.285714286;
  margin-bottom: 5px;
  ${down('md')} {
    flex: 50%;
    width: 100%;
  }
`
const GalleryImage = styled.img`
  width: 110px;
  height: 110px;
  ${down('md')} {
    flex: 50%;
    width: 100%;
  }
`
export default Gallery
