import React, { useState } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Modal, Input, TextInput, useMantineTheme } from '@mantine/core'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { TextEditor } from '../TextEditor'
import { useForm } from '@mantine/form'
import Button from '../common/Button'
import { closeModal, openModal } from '../../store/actions/modal.action'
import { addProductToCart } from '../../store/actions/cart.action'
import { useTranslation } from 'react-i18next'
import { getStorage } from '../../utils/encrypt'
import { IconX } from '@tabler/icons-react'

const CustomProduct = () => {
  const [specification, setSpecification] = useState(null)
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modalStatus = useSelector((state) =>
    state.modal.openModal?.type === 'customProduct'
      ? state.modal.openModal.value
      : false,
  )
  const productId = useSelector((state) => state.modal.extra?.product)
  const cart = getStorage('cart')
  const [opened, { close }] = useDisclosure(modalStatus)

  const form = useForm({
    initialValues: {
      quantity: '',
    },
  })

  const handleSubmit = (values) => {
    dispatch(
      addProductToCart({
        productId,
        cart,
        quantity: values.quantity,
        productType: 'custom',
        productCustomDescription: specification,
      }),
    )
    form.reset()
    dispatch(closeModal({ type: 'customProduct' }))
    dispatch(openModal({ type: 'addCart' }))
  }

  return (
    <Modal
      opened={modalStatus}
      onClose={close}
      centered
      withCloseButton={false}
      closeOnClickOutside={true}
      size="lg"
      className="modal"
      transitionProps={{ transition: 'pop', duration: 200 }}
      overlayProps={{
        color:
          theme.colorScheme === 'light'
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 8,
      }}
    >
      <ModalHeader
        onClick={() => dispatch(closeModal({ type: 'customProduct' }))}
      >
        <IconX size={18} />
      </ModalHeader>
      <ModalBody>
        <Inner>
          <ModalTitle>{t('shop.spec.title')}</ModalTitle>
          <form onSubmit={form.onSubmit(handleSubmit)} method="post">
            <Input.Wrapper
              label={t('shop.spec.expectations')}
              maw={'100%'}
              style={{ fontFamily: 'JosefinSlab' }}
              mx="auto"
              withAsterisk
              mt={10}
            >
              <TextEditor content={(value) => setSpecification(value)} />
            </Input.Wrapper>
            <Input.Wrapper
              label={t('shop.spec.quantity')}
              maw={'100%'}
              style={{ fontFamily: 'JosefinSlab' }}
              mx="auto"
              withAsterisk
              mt={5}
            >
              <TextInput
                withAsterisk
                style={{ fontFamily: 'JosefinSlab' }}
                mt={10}
                size="lg"
                name="content"
                type="number"
                radius={'xs'}
                {...form.getInputProps('quantity')}
                required
              />
            </Input.Wrapper>
            <Button type="submit" style={{ fontSize: 12, marginTop: 15 }}>
              {t('shop.spec.button')}
            </Button>
          </form>
        </Inner>
      </ModalBody>
    </Modal>
  )
}
const ModalHeader = styled.div`
  background: #ddd;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9999;
`
const ModalBody = styled.div`
  position: relative;
  padding: 10px;
  font-family: 'JosefinSlab';
`
const ModalTitle = styled.h3`
  font-family: 'JosefinSlab';
  font-weight: 100;
  margin-bottom: 5px;
  letter-spacing: 2px;
  text-transform: uppercase;
`
const Inner = styled.div`
  border: 1px solid #eee;
  padding: 15px 35px;
`
export default CustomProduct
